import { Button, Dropdown, message, Modal, Spin, Tag } from "antd";
import "./BulkDownload.scss";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Mixpanel } from "app/config/Mixpanel";

import {
  CarryOutOutlined,
  CheckOutlined,
  FormOutlined,
  HolderOutlined,
  CheckCircleFilled,
  LoadingOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { Select, Switch, Tree } from "antd";
import type { TreeDataNode } from "antd";
import { colorPicker } from "app/utils/color.helper";
import Typography from "../Typography";
import { Column } from "ag-grid-enterprise";
import { apiGet, apiPost, apiPostForm } from "app/services/apiServices";
import {
  API_ENDPOINT_AG_TABLE_RAISE_DOWNLOAD_INVOICE_REQUEST,
  API_ENDPOINT_AG_TABLE_RAISE_DOWNLOAD_INVOICE_REQUEST_HISTORY,
} from "../AgTable/AgTable.constants";
import { AgTableClient } from "../AgTable";
import moment from "moment";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { defaultFileTagInvoiceMap } from "global.constants";
import { getSubdomain } from "app/utils/generic.helper";
import Uploader from "../Uploader";
import { API_ENDPOINT_UPLOAD_FILE } from "./Bulk.constant";

export default function BulkDownloadWidget(props: any) {
  const [showModal, setShowModal] = useState(false);
  const [showModalFor, setShowModalFor] = useState("");
  const [showLine, setShowLine] = useState<boolean>(true);
  const [showIcon, setShowIcon] = useState<boolean>(false);
  const [showLeafIcon, setShowLeafIcon] = useState<React.ReactNode>(true);
  const [filenameTags, setFileNameTags] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [history, setHistory] = useState<any[]>([]);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);

  const onSelect = (selectedKeys: React.Key[], info: any) => {
    console.log("selected", selectedKeys, info);
  };

  const handleLeafIconChange = (value: "true" | "false" | "custom") => {
    if (value === "custom") {
      return setShowLeafIcon(<CheckOutlined />);
    }

    if (value === "true") {
      return setShowLeafIcon(true);
    }

    return setShowLeafIcon(false);
  };

  useEffect(() => {
    fetchDownloadHistory();
  }, [userDetails.currentWorkspace]);

  const handleAddFileTag = (tag: string) => {
    setFileNameTags((prevKeys) => {
      if (prevKeys.includes(tag)) {
        // If key exists, remove it
        return prevKeys.filter((k) => k !== tag);
      } else {
        // If key doesn't exist, add it
        return [...prevKeys, tag];
      }
    });
  };

  function getFileExtension(fileName: string) {
    // Split the file name by the dot character
    let parts = fileName.split(".");

    // Get the last part of the array which should be the file extension
    let extension = parts[parts.length - 1];

    return extension;
  }

  //new ----
  const handleUpload = async (file: any) => {
    try {
      setLoading(true);
      // Allowed file types
      const allowedTypes = ["text/csv"];
      const fileType = file.type;

      if (!allowedTypes.includes(fileType)) {
        messageApi.error("Only CSV (.csv) files are allowed.");
        setLoading(false);
        return;
      }
      let fileExtension = getFileExtension(file.name);
      let formData = new FormData();
      formData.append("file", file);
      formData.append(
        "filename",
        `${
          file.name || "invoice_download_file"
        }_${moment().unix()}.${fileExtension}`
      );

      console.log("FormData:", formData);

      const response = await apiPostForm(API_ENDPOINT_UPLOAD_FILE, formData);
      console.log(response.data);

      if (response.status) {
        const fileUrl = response.data.fileUrl;
        messageApi.success("File uploaded successfully.");
        handleRaiseDownloadRequest(fileUrl);
      }
    } catch (error) {
      messageApi.error("Error uploading file");
    } finally {
      setLoading(false);
    }
  };

  const fetchDownloadHistory = async () => {
    const response = await apiGet(
      API_ENDPOINT_AG_TABLE_RAISE_DOWNLOAD_INVOICE_REQUEST_HISTORY
    );
    if (response.status) {
      setHistory(response.data);
    }
  };

  const createTree = (items: any) => {
    const makeNode: any = (title: string, key: any, children = []) => ({
      title,
      key,
      icon: <CarryOutOutlined />,
      children,
    });

    // If items array is empty, only show the two PDF children
    if (items.length === 0) {
      return [
        {
          title: "Invoices.zip",
          key: "0",
          icon: <CarryOutOutlined />,
          children: [
            {
              title: "invoice_1.pdf",
              key: "0-1",
              icon: <CarryOutOutlined />,
            },
            {
              title: "invoice_2.pdf",
              key: "0-2",
              icon: <CarryOutOutlined />,
            },
          ],
        },
      ];
    }

    // Start with the base node
    let tree = makeNode(items[0], "0-0");

    // Reference to the current node
    let currentNode = tree;

    // Iterate over the remaining items to build the tree
    for (let i = 1; i < items.length; i++) {
      const newNode = makeNode(items[i], `${currentNode.key}-${i - 1}`);
      currentNode.children = [newNode];
      currentNode = newNode;
    }

    // Add final children to the last node
    currentNode.children = [
      makeNode("invoice_1.pdf", `${currentNode.key}-0`),
      makeNode("invoice_2.pdf", `${currentNode.key}-1`),
    ];

    console.log("tree+_node", tree);
    return [
      {
        title: "Invoices.zip",
        key: "0",
        icon: <CarryOutOutlined />,
        children: [tree],
      },
    ];
  };

  const handleRaiseDownloadRequest = async (fileUrl = null) => {
    const domain = getSubdomain();
    setLoading(true);
    const payload = {
      filenameTags: defaultFileTagInvoiceMap[domain] || [],
      groupingPayload: props.getPayload(),
      moduleId: props.moduleId,
      columnLinks: ["assetlink"],
      dbType: props.dbType || "postgre",
      database: props.database || "gstservice_db",
      tableName: props.tableName,
      requestlink: fileUrl,
      columnInvoiceNo: defaultFileTagInvoiceMap[domain] || [],
    };
    const response = await apiPost(
      API_ENDPOINT_AG_TABLE_RAISE_DOWNLOAD_INVOICE_REQUEST,
      payload
    );
    if (response.status) {
      messageApi.success({
        type: "success",
        content: "We have received your request will notify you via email",
      });
      props.success();
      fetchDownloadHistory();
      setShowModal(false);
      setShowModalFor("");
    }

    console.log("download request payload", payload);
    setLoading(false);
  };

  const tablePayload = props.getPayload();

  const tableColumns = props.getColumnDefs() || [];
  console.log("tableColumns", tableColumns);
  const groupedColumns = (tablePayload?.rowGroupCols || []).map(
    (item: any) => item.field
  );

  const onMenuClick = (e: any) => {
    setShowModalFor("HISTORY");
    setShowModal(true);
  };

  const getReportActionButton = (rowInfo: any) => {
    switch (rowInfo.status) {
      case "COMPLETED":
        return (
          <Button
            size="small"
            style={{ color: colorPicker("green.700") }}
            icon={<DownloadOutlined />}
            onClick={() => {
              rowInfo?.filehash &&
                window.open(
                  `https://files.finkraft.ai/invoice-${rowInfo?.filehash}`,
                  "_blank"
                );
            }}
          >
            {rowInfo?.filehash ? "Download" : "No Data"}
          </Button>
        );

      case "PENDING":
        return (
          <Button
            size="small"
            style={{ color: colorPicker("yellow.700") }}
            icon={
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 12,
                      marginRight: 6,
                      color: colorPicker("yellow.700"),
                    }}
                    spin
                  />
                }
              />
            }
          >
            Generating
          </Button>
        );

      case "IN PROGRESS":
        return (
          <Button
            size="small"
            style={{ color: colorPicker("yellow.700") }}
            icon={
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 12,
                      marginRight: 6,
                      color: colorPicker("yellow.700"),
                    }}
                    spin
                  />
                }
              />
            }
          >
            Generating
          </Button>
        );

      case "EXCEPTION IN GETDATA":
        return (
          <Button size="small" style={{ color: colorPicker("red.700") }}>
            Failed, Regenerate
          </Button>
        );

      case "FAILED":
        return (
          <Button size="small" style={{ color: colorPicker("red.700") }}>
            Failed, Regenerate
          </Button>
        );
      case "NO RECORDS":
        return (
          <Button size="small" style={{ color: colorPicker("yellow.700") }}>
            No Record Found
          </Button>
        );
    }
  };

  const downloadSampleCredentialCSV = async () => {
    Mixpanel.track("Download Sample CSV", {
      Screen: "Workspace",
    });

    // Define only Invoice Numbers in CSV
    let csvString = `Invoice Number
  I270000014319179
  I270000014319180
  I270000014319181
  I270000014319182`;

    try {
      // Convert the CSV string to a Blob
      const csvBlob = new Blob([csvString], { type: "text/csv" });

      // Convert the Blob object to a data URL
      const csvDataUrl = URL.createObjectURL(csvBlob);

      // Create a temporary anchor element
      const link = document.createElement("a");
      link.href = csvDataUrl;
      link.setAttribute("download", "invoice_numbers.csv");

      // Trigger the download by programmatically clicking the anchor element
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading CSV file:", error);
    }
  };

  return (
    <div className="BulkDownload">
      <Dropdown.Button
        size="small"
        type="primary"
        menu={{
          items: [
            {
              key: "1",
              label: "Download History",
            },
          ],
          onClick: onMenuClick,
        }}
        onClick={() => {
          setShowModalFor("DOWNLOAD");
          setShowModal(true);
          // if (userDetails?.hasInvoiceRequestRaised) {
          //   let workspaceInfo = (userDetails?.workspaces || []).find(
          //     (item: any) =>
          //       item.id ===
          //       userDetails?.hasInvoiceRequestRaised?.workspace_id?.[0]
          //   );
          //   message.info(
          //     `Invoice generation is already in progress ${
          //       workspaceInfo ? `for ${workspaceInfo?.name} ` : ""
          //     }, please wait for it to complete`
          //   );
          // } else {
          //   setShowModalFor("DOWNLOAD");
          //   setShowModal(true);
          // }
        }}
      >
        {props.type && props.type === "CSV"
          ? "Upload Invoices Using Invoice Number"
          : "Download Invoices"}
      </Dropdown.Button>

      <Modal
        footer={false}
        title={
          showModalFor === "HISTORY"
            ? "Bulk Download History"
            : "Bulk Downloader"
        }
        open={showModal}
        onCancel={() => {
          setShowModal(false);
          setShowModalFor("");
        }}
      >
        {showModalFor === "HISTORY" ? (
          <div style={{ height: 350 }}>
            <AgTableClient
              // @ts-ignore
              rowData={history}
              columnDefs={[
                {
                  field: "report_name",
                  headerName: "Report Name",
                  filter: "agTextColumnFilter",
                  cellRenderer: (params: any) => {
                    return `Invoice Report ${moment(
                      params.data?.createdAt
                    ).format("DD MMM")}`;
                  },
                },
                // {
                //   field: "created_by",
                //   headerName: "Created By",
                // },
                {
                  field: "createdAt",
                  headerName: "Created At",
                  cellRenderer: (params: any) =>
                    moment(params.data?.createdAt).fromNow(),
                },
                {
                  field: "status",
                  headerName: "Status",
                  cellRenderer: (params: any) => {
                    return getReportActionButton(params?.data);
                  },
                },
              ]}
              hideToolbar
            />
          </div>
        ) : props.type && props.type === "CSV" ? (
          <div>
            <div
              onClick={downloadSampleCredentialCSV}
              style={{
                fontSize: "14px",
                border: "1px solid #0a8394",
                borderRadius: "5px",
                width: "180px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                marginBottom: "8px",
                backgroundColor: "white",
                color: "#0a8394",
                marginLeft: "auto",
              }}
            >
              <DownloadOutlined
                style={{ marginRight: "4px", color: "#0a8394" }}
              />
              Download Sample CSV
            </div>
            <Uploader
              onSelect={(file: any, request_link: string) => {
                console.log(" selected file", file);
                handleUpload(file);
              }}
            />
          </div>
        ) : (
          <div>
            <div
              style={{
                background: "#0A839440",
                width: "100%",
                borderRadius: 4,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  color: colorPicker("primary.700"),
                  paddingLeft: 12,
                  paddingRight: 12,
                }}
                variant="caption"
              >
                The download process will take upto 30 minutes depending upon
                size of file. You will be notified via email with the invoice
                zip.
              </Typography>
            </div>
            {/* <div style={{ marginTop: 12, marginBottom: 24 }}>
              {(props.getColumnDefs() || []).map((column: any, key: any) => {
                return (
                  <Tag
                    key={key}
                    color={
                      filenameTags.includes(column.headerName)
                        ? colorPicker("primary.700")
                        : "cyan"
                    }
                    icon={
                      filenameTags.includes(column.headerName) ? (
                        <CheckCircleFilled />
                      ) : null
                    }
                    style={{ cursor: "pointer", marginBottom: 4 }}
                    onClick={() => handleAddFileTag(column.headerName)}
                  >
                    {column.headerName}
                  </Tag>
                );
              })}
            </div> */}
            {/* <Typography
              variant="caption"
              style={{ color: colorPicker("neutral.700") }}
            >
              File Hierarchy will be shown as below
            </Typography> */}
            {/* <Tree
              showLine={showLine ? { showLeafIcon } : false}
              showIcon={showIcon}
              defaultExpandedKeys={["0-0-0"]}
              onSelect={onSelect}
              treeData={createTree(groupedColumns) || []}
            /> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginTop: 24,
              }}
            >
              <Button
                size="small"
                style={{ marginRight: 12 }}
                onClick={() => setShowModal(false)}
                loading={isLoading}
              >
                Cancel
              </Button>

              <Button
                type="primary"
                size="small"
                // onClick={handleRaiseDownloadRequest}
                onClick={() => handleRaiseDownloadRequest(null)}
                loading={isLoading}
              >
                Initiate Download
              </Button>
            </div>
          </div>
        )}
      </Modal>
      {contextHolder}
    </div>
  );
}
