export const API_ENDPOINT_VENDOR_VALIDATE = `${process.env.REACT_APP_API_URL}/finalytics/tables/data/vendorValidate`
export const API_ENDPOINT_VENDOR_COUNT = `${process.env.REACT_APP_API_URL}/finalytics/tables/data/vendorCountVendorTally`
export const API_ENDPOINT_UNIQUE_VENDOR = `${process.env.REACT_APP_API_URL}/finalytics/tables/data/uniqueVendor`
export const API_ENDPOINT_VENDOR_SUMMARY = `${process.env.REACT_APP_API_URL}/finalytics/tables/data/vendorSummary`
// export const API_ENDPOINT_VENDOR_SUMMARY = `http://localhost:7000/api/finalytics/tables/data/vendorSummary`

export const API_ENDPOINT_FETCH_GSTINS = `${process.env.REACT_APP_API_URL_FUZZY}/fetch-gstins?workspace_name=`;
export const API_ENDPOINT_EXPENSE_DATA = `${process.env.REACT_APP_API_URL}/finalytics/tables/data/expense_data`;
export const API_ENDPOINT_TOP_TEN_VENDORS = `${process.env.REACT_APP_API_URL}/finalytics/tables/data/toptenvendors`;
export const API_ENDPOINT_FOLLOW_UP_STATUS = `${process.env.REACT_APP_API_URL}/finalytics/tables/data/followUpStatus`;
export const API_ENDPOINT_USER_LIST = `${process.env.REACT_APP_API_URL}/meta/user/list`;
export const API_ENDPOINT_GET_EMAIL = `${process.env.REACT_APP_API_URL_FUZZY}/api/get-email`;
export const API_ENDPOINT_GET_CCS = `${process.env.REACT_APP_API_URL_FUZZY}/api/get-ccs`;
export const API_ENDPOINT_SEND_EMAIL = `${process.env.REACT_APP_API_URL_FUZZY}/send-email`;
export const API_ENDPOINT_ADD_CREDS = `${process.env.REACT_APP_API_URL_VENDOR_SCORE}/api/addcreds`;
export const API_ENDPOINT_EXEMPTED_DATA = `${process.env.REACT_APP_API_URL}/exempted-data`;
export const API_ENDPOINT_GET_GSTINS = `${process.env.REACT_APP_API_URL_VENDOR_SCORE}/api/gstins`;
export const API_ENDPOINT_GET_GSTIN = `${process.env.REACT_APP_API_URL_VENDOR_SCORE}/get_gstin`;
export const API_ENDPOINT_GET_INVOICE_SUMMARY = `${process.env.REACT_APP_API_URL_FUZZY}/get_invoice_summary`;
export const API_ENDPOINT_GET_GSTIN_DATA = `${process.env.REACT_APP_API_URL_VENDOR_SCORE}/gstin-data`;
export const API_ENDPOINT_DELETE_EXEMPTED_DATA = `${process.env.REACT_APP_API_URL_FUZZY}/delete-exempted-data`;
export const API_ENDPOINT_FETCH_EXEMPTED_DATA = `${process.env.REACT_APP_API_URL}/finalytics/tables/fetchExemptedData`;
export const API_ENDPOINT_VENDORS = `${process.env.REACT_APP_API_URL_FUZZY}/api/vendors`;
export const API_ENDPOINT_REMOVE_CC = `${process.env.REACT_APP_API_URL_FUZZY}/remove-cc`;
export const API_ENDPOINT_UPLOAD_CSV = `${process.env.REACT_APP_API_URL_FUZZY}/upload-csv`;
export const API_ENDPOINT_PREVIEW_VENDOR_TALLY = `${process.env.REACT_APP_API_URL}/finalytics/tables/data/preview_vendor_tally`;
export const API_ENDPOINT_UNIQUE_VENDOR_TALLY = `${process.env.REACT_APP_API_URL}/finalytics/tables/data/uniqueVendorTally`;
export const API_ENDPOINT_ADD_CONTACT = `${process.env.REACT_APP_API_URL_FUZZY}/add-contact`;
export const API_ENDPOINT_TEMPLATE = `${process.env.REACT_APP_API_URL_FUZZY}/api/template/`
export const API_ENDPOINT_ADD_GSTIN = `${process.env.REACT_APP_API_URL_FUZZY}/api/gstins/add`;
export const API_ENDPOINT_EINVOICE_PIPELINE = `${process.env.REACT_APP_API_URL_FUZZY}/einvoice-pipeline`;
export const API_ENDPOINT_VENDOR_COUNT_NEW = `${process.env.REACT_APP_API_URL}/finalytics/tables/data/vendorCount`;
export const API_ENDPOINT_ADD_CC = `${process.env.REACT_APP_API_URL_FUZZY}/add-cc/`;
export const API_ENDPOINT_GET_CC_DATA =`${process.env.REACT_APP_API_URL_FUZZY}/get-cc-data`;
export const API_ENDPOINT_GET_GST_SCORE = `${process.env.REACT_APP_API_URL_VENDOR_SCORE}/api/gstscore`;
export const API_ENDPOINT_TOGGLE_DATA = `${process.env.REACT_APP_API_URL}/api/finalytics/tables/data/toggleData`;





