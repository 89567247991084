import React, { useState ,useRef} from "react";
import AgTableClient from "../../shared/AgTable/AgTableClient";
import HotelData from './sampleHotel.json'
import { getProcessedDefinition, getSubdomain } from "app/utils/generic.helper";
import { useRecoilState, useSetRecoilState } from "recoil";
import { tableSchema } from "app/config/States/agtable";
import { currentPDFData, userInfo } from "app/config/States/users";
import ToolParamInvoker from "app/shared/AgTable/HelperTools/ToolParamInvoker";

const HotelPage = () => {
    const [rowData, setRowData] = useState(HotelData);
    const setRecoilPdfData = useSetRecoilState(currentPDFData);
    const [userDetails, setUserDetails] = useRecoilState(userInfo);
    const gridRef = useRef();
    const openSidebarRenderer = (params) => {
      console.log(params?.data?.["Updated Asset Link"], "params in openSidebarRenderer")
      params.api.openToolPanel("pdfRenderer", { paramData: params?.data?.["Final Invoice Link"] });
  };


    const columnDefs = [
        { field: "Main Invoice Status", headerName: "Invoice Status", filter: "agSetColumnFilter", 
          filterParams: {
            values: ["Invoice Received","Invoice Not Received"], // Only allow "INV" as a filter option
          },
        },
        { field: "Workspace", headerName: "Workspace", filter: "agTextColumnFilter", },
        { field: "vendor_gstin", headerName: "Vendor GSTIN", filter: "agTextColumnFilter", },
        { field: "vendor_name_einvoice", headerName: "Vendor Name", filter: "agTextColumnFilter", },
        { field: "vendor_pan", headerName: "Vendor PAN", filter: "agTextColumnFilter", },
        { field: "customer_gstin", headerName: "Customer GSTIN",filter: "agTextColumnFilter", },
        { field: "customer_pan", headerName: "Customer PAN", filter: "agTextColumnFilter", },
        { field: "invoice_number", headerName: "Invoice Number", filter: "agTextColumnFilter", },
        { field: "invoice_date", 
          headerName: "Invoice Date", 
          filter: "agDateColumnFilter" ,
          formatType:"DATE",
            formatProps:{
                dateFormat:"DD-MM-YYYY"
            },
        },
        
        { field: "taxable_amount", 
          headerName: "Taxable Amount", 
          filter: "agNumberColumnFilter",
         },
        { field: "cgst_amount", 
          headerName: "CGST Amount", 
          filter: "agNumberColumnFilter", 
          },
        { field: "sgst_amount", 
          headerName: "SGST Amount", 
          filter: "agNumberColumnFilter", 
          },
        { field: "igst_amount", 
          headerName: "IGST Amount", 
          filter: "agNumberColumnFilter", 
         },
        { field: "Total GST", 
          headerName: "Total GST", 
          filter: "agNumberColumnFilter", 
          },
        { field: "total_amount", 
          headerName: "Total Amount", 
          filter: "agNumberColumnFilter", 
         },
        { field: "invoice_type", headerName: "Invoice Type",  filter: "agSetColumnFilter",
          filterParams: {
            values: ["INV","CRN"], // Only allow "INV" as a filter option
          },
        },
        { field: "Irn", headerName: "IRN", filter: "agTextColumnFilter", },
        { field: "Total GST (996311)", 
          headerName: "Total GST (996311)", 
          filter: "agNumberColumnFilter", 
          },
        { field: "Total GST (996322)", 
          headerName: "Total GST (996322)", 
          filter: "agNumberColumnFilter", 
         },
        { field: "Total GST (996331)", 
          headerName: "Total GST (996331)", 
          filter: "agNumberColumnFilter", 
           },
        { field: "Total GST (996332)", 
          headerName: "Total GST (996332)", 
          filter: "agNumberColumnFilter", 
           },
        { field: "Total GST (996334)", 
          headerName: "Total GST (996334)", 
          filter: "agNumberColumnFilter", 
           },
        { field: "Total GST (996339)", 
          headerName: "Total GST (996339)", 
          filter: "agNumberColumnFilter", 
          },
        { field: "Final Claimable Status", headerName: "Claimable Status", filter: true },
        { field: "State Code Status", headerName: "State Code Status", filter: true },
        // { 
        //   field: "Final Invoice Link", 
        //   headerName: "Invoice Link", 
        //   cellRenderer: params => `<a href="${params.value}" target="_blank">View Invoice</a>` 
        // }
        {
          headerName: "Invoice Link",
          field: "Final Invoice Link",
          filter: "agTextColumnFilter",
          //invoiceLink: "html.pdf",
          cellRenderer: ToolParamInvoker,
          formatType: "INVOICE",
          // cellRenderer: ToolParamInvoker,
          // formatProps: {},
          // ...handleCellRenderer(),
          cellRendererParams: (params) => {
              return {
                  label: "View Invoice",
                  handleClick: openSidebarRenderer,
                  //data: { pdfLink: params.data?.["Updated Asset Link"] }
                  data: params.data,
              };
          }
      },
      ];

      const getProcessedDefinitions = () => {
        

        return getProcessedDefinition(
            columnDefs,
            "CLIENT",
            setRecoilPdfData,
            userDetails || {}
        );
    };
      
console.log(columnDefs,"columnDefs")
console.log(rowData,"rowData")
  return (
    <div>
        <div style={{ height: "100vh" }}>
        <AgTableClient 
          rowData={rowData} 
          //columnDefs={columnDefs}
          columnDefs={getProcessedDefinitions()}
          groupPanel
          pdfV2
          pdfDataKey="Final Invoice Link"
          ref={gridRef}
          />
    </div>
    </div>
  );
};

export default HotelPage;





