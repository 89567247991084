import React, { useState, useRef, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-enterprise/styles/ag-theme-alpine.css";
import "ag-grid-enterprise/styles/ag-grid.css";
import axios from "axios";
import { AgTableClient } from "app/shared/AgTable";
import { Button, message, Tag } from "antd";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import CustomAgHeader from "app/scenes/FinkAnalytics/components/ColMapTablePreview/CustomHeader";
import AgCustomHeader from "./CustomColumn";

const AgTrial = () => {
  const [rowData, setRowData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [viewName, setViewName] = useState("");
  const [showSaveViewButtons, setShowSaveViewButtons] = useState(false);
  const [activeView, setActiveView] = useState(null);
  const [savedViews, setSavedViews] = useState([
    {
      viewName: "Athelete",
      payload: {
        startRow: 0,
        endRow: 100,
        rowGroupCols: ["athlete"],
        valueCols: ["total"],
        pivotCols: [],
        pivotMode: true,
        groupKeys: [],
        filterModel: {},
        sortModel: null,
      },
    },
    {
      viewName: "GraphUser",
      chartModels: [
        {
          modelType: "range",
          chartId: "id-50whhtixji",
          chartType: "groupedBar",
          chartThemeName: "ag-default",
          chartOptions: {},
          chartPalette: {
            fills: [
              "#5090dc",
              "#ffa03a",
              "#459d55",
              "#34bfe1",
              "#e1cc00",
              "#9669cb",
              "#b5b5b5",
              "#bd5aa7",
              "#8a6224",
              "#ef5452",
            ],
            strokes: [
              "#2b5c95",
              "#cc6f10",
              "#1e652e",
              "#18859e",
              "#a69400",
              "#603c88",
              "#575757",
              "#7d2f6d",
              "#4f3508",
              "#a82529",
            ],
          },
          cellRange: {
            rowStartIndex: 0,
            rowStartPinned: null,
            rowEndIndex: 8,
            rowEndPinned: null,
            columns: ["athlete", "age"],
          },
          switchCategorySeries: false,
          suppressChartRanges: false,
          unlinkChart: false,
          version: "31.3.4",
        },
      ],
      viewType: "CHART",
    },
  ]);

  const [chartCols, setChartCols] = useState([]);

  const gridApiRef = useRef(null);
  const gridRef = useRef();

  const handleOpenChart = (column) => {
    let chartModal = gridRef.current?.getChartModels() || [];
    if (
      chartModal?.length > 0 &&
      Array.isArray(chartModal[0]?.cellRange?.columns)
    ) {
      // Update only the first item, keeping the rest of the array intact
      chartModal[0].cellRange.columns = [
        column, // Update first column
        ...chartModal[0].cellRange.columns.slice(1), // Keep the rest of the columns
      ];
    }

    console.log("updated collumn", chartModal);
    gridRef.current?.setChartModels(chartModal[0]);
  };

  useEffect(() => {
    if (chartCols.length >= 2) {
    }
  }, [chartCols]);

  const handleApplyFilter = (field, params) => {
    gridRef.current?.setFilterModel({
      [field]: {
        filterType: "text",
        type: "contains",
        filter: params,
      },
    });
  };
  const columnDefs = [
    {
      headerName: "Athlete",
      field: "athlete",
      sortable: true,
      filter: "agTextColumnFilter",
      enableRowGroup: true,
      headerComponentParams: {
        icon: "fa-user",
        onClick: handleOpenChart,
      },
      headerComponent: AgCustomHeader,
    },
    {
      headerName: "Age",
      field: "age",
      sortable: true,
      filter: "agNumberColumnFilter",
      enableValue: true,
      headerComponentParams: {
        icon: "fa-user",
        onClick: handleOpenChart,
      },
      headerComponent: AgCustomHeader,
    },
    {
      headerName: "Country",
      field: "country",
      sortable: true,
      filter: "agTextColumnFilter",
      headerComponentParams: {
        icon: "fa-user",
        onClick: handleOpenChart,
      },
      headerComponent: AgCustomHeader,
      cellRenderer: (params) => (
        <Tag
          onClick={() => handleApplyFilter("country", params.data?.country)}
          style={{ cursor: "pointer" }}
        >
          {params?.data?.country || params?.value}
        </Tag>
      ),
    },
    {
      headerName: "Year",
      field: "year",
      sortable: true,
      filter: "agNumberColumnFilter",
    },
    {
      headerName: "Sport",
      field: "sport",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Gold Medals",
      field: "gold",
      sortable: true,
      filter: "agNumberColumnFilter",
      enableValue: true,
    },
    {
      headerName: "Silver Medals",
      field: "silver",
      sortable: true,
      filter: "agNumberColumnFilter",
      enableValue: true,
    },
    {
      headerName: "Bronze Medals",
      field: "bronze",
      sortable: true,
      filter: "agNumberColumnFilter",
      enableValue: true,
    },
    {
      headerName: "Total Medals",
      field: "total",
      sortable: true,
      filter: "agNumberColumnFilter",
      enableValue: true,
      headerComponentParams: {
        icon: "fa-user",
        onClick: handleOpenChart,
      },
      headerComponent: AgCustomHeader,
    },
  ];

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
  };

  React.useEffect(() => {
    axios
      .get("https://www.ag-grid.com/example-assets/olympic-winners.json")
      .then((response) => {
        setRowData(response.data || []);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleCreateView = () => {
    alert("Please start changing");
    setShowSaveViewButtons(true);
  };

  const handleSaveView = () => {
    // if (!viewName.trim()) {
    //   alert("View name cannot be empty!");
    //   return;
    // }
    const chartModels = gridRef.current?.getChartModels();
    console.log("chartModals", chartModels);
    const chartType = chartModels.length > 0 ? "CHART" : "AGTABLE";
    const columnState = gridRef.current?.getServerSidePayload();
    const viewData = {
      viewName: viewName || `${chartType} View`,
      payload: columnState,
      chartModels: chartModels,
      viewType: chartType,
    };

    localStorage.setItem(`agGridView_${viewName}`, JSON.stringify(viewData));
    message.success(`View "${viewName}" saved successfully!`);
    console.log("view payload:", viewData);

    setSavedViews((prev) => [...prev, viewData]);
    setOpenDialog(false);
    setViewName("");
    setShowSaveViewButtons(false);
  };

  const handleLoadView = (viewData) => {
    setActiveView(viewData);
    console.log("viewData:", viewData);
    if (viewData.viewType === "CHART") {
      gridRef.current &&
        gridRef.current?.setChartModels(viewData.chartModels?.[0]);
    } else {
      gridRef.current && gridRef.current?.loadGridView2(viewData.payload);
    }
    // setRowData(rowData);
    message.success(`View "${viewData?.viewName}" loaded!`);
  };

  return (
    <div
      className="ag-theme-alpine"
      style={{ height: "1000px", width: "100%" }}
    >
      <div style={{ display: "flex" }}>
        <Button
          type="primary"
          onClick={() =>
            gridRef.current && gridRef.current?.loadGridView2("DEFAULT")
          }
          style={{ margin: "10px 6px" }}
        >
          Main View
        </Button>
        {savedViews.map((viewData) => (
          <Button
            key={viewData?.viewName}
            type="primary"
            onClick={() => handleLoadView(viewData)}
            style={{ margin: "10px 6px" }}
          >
            {viewData?.viewName}
          </Button>
        ))}
        {!showSaveViewButtons ? (
          <Button
            type="primary"
            onClick={handleCreateView}
            style={{ margin: "10px 6px" }}
          >
            Create View
          </Button>
        ) : (
          <>
            <Button
              type="primary"
              onClick={() => setShowSaveViewButtons(false)}
              style={{ margin: "10px 6px" }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => setOpenDialog(true)}
              style={{ margin: "10px 6px" }}
            >
              Save
            </Button>
          </>
        )}
      </div>

      <div style={{ height: "900px", width: "100%" }}>
        <AgTableClient
          rowData={rowData}
          columnDefs={columnDefs}
          groupPanel
          onGridReady={onGridReady}
          ref={gridRef}
        />
      </div>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        sx={{ "& .MuiDialog-paper": { width: "400px" } }}
      >
        <DialogTitle>Save View</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="View Name"
            fullWidth
            value={viewName}
            onChange={(e) => setViewName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} type="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveView} type="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AgTrial;
