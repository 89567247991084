import React, { useEffect, useRef, useState } from "react";
import "./BhilosaDashboard.scss";
import { AgCharts } from "ag-charts-enterprise";
// import 'ag-grid-enterprise';
import BhilosaLogo from "../../../assets/images/BhilosaLogo.png";
import FinkraftLogo from "../../../assets/images/finkraftlogo.png";
import { Button, Modal, Tabs, Tag } from "antd";
import tickIcon from "../../../assets/images/tick.png";
import phoneIcon from "../../../assets/images/phone.png";
import GstinLogo from "../../../assets/images/GstinLogo.png";
import GSTPortal from "../../../assets/images/GSTPage.png";
import warninging from "../../../assets/images/warning.png";
import pivot from "../../../assets/images/pivot.png";
import logout from "../../../assets/images/logout.png";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { API_ENDPOINT_GET_USER_INFO } from "app/scenes/Auth/auth.constants";
import { API_ENDPOINT_EINVOICE_PIPELINE, API_ENDPOINT_EXPENSE_DATA, API_ENDPOINT_FETCH_GSTINS, API_ENDPOINT_FOLLOW_UP_STATUS, API_ENDPOINT_TOP_TEN_VENDORS, API_ENDPOINT_VENDOR_COUNT, API_ENDPOINT_VENDOR_COUNT_NEW, API_ENDPOINT_VENDOR_VALIDATE } from "../vendorfollowup.constants";
import { apiGet } from "app/services/apiServices";
import "./App.css";
import { useRecoilState } from "recoil";
import {
  gst2ADataState,
  gst2BDataState,
  irndata,
  roleInfo,
  userInfo,
  vendorDetailsDisplay,
  workspaceInfo,
} from "app/config/States/users";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { EyeOutlined } from "@ant-design/icons"; //new
import CircleIcon from "@mui/icons-material/Circle";
import { ReactComponent as ArrowIcon } from "../arrow.svg";
import PrimaryNavBhilosa from "app/shared/PrimaryNavBhilosa";
import ExpenseTypeFile from "./ExpenseType/ExpenseTypeFile";
import InvoiceIRN from "./InvoiceIRN/InvoiceIRN";
import Vendor from "./VendorCompliance/Vendor";
import Bar from "./Bar/Bar";
import Donut from "./Donut/Donut";
import FollowUpStatus from "./Line/Line";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/styles/ag-theme-alpine.css";
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-quartz.css";
import MainContentVendor from "./VendorScore/pages/demo/MainContent/MainContent";
import { BeatLoader } from "react-spinners";
import EmailModal from "./EmailModel/EmailModel";
import color from "theme/color";
import { Category } from "@mui/icons-material";
import { display, height } from "@mui/system";
import Typography from "app/shared/Typography"

const getMonthOptions = () => {
  const months = [];
  const startMonth = new Date(2024, 3); // April 2024 (months are 0-indexed, so 3 = April)
  const currentMonth = new Date(); // Current date

  // Loop from April 2024 to the current month
  let monthDate = startMonth;
  while (monthDate <= currentMonth) {
    const monthLabel =
      monthDate.toLocaleString("default", { month: "short" }) +
      "-" +
      monthDate.getFullYear();
    const monthValue = `${(monthDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}${monthDate.getFullYear()}`;
    months.push({ value: monthValue, label: monthLabel });
    monthDate.setMonth(monthDate.getMonth() + 1); // Move to the next month
  }

  return [{ value: "All", label: "All Months" }, ...months];
};

const GSTIntegration = () => {
  const [workspaces, setWorkspaces] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const { state } = useLocation();
  const [workspace, setWorkspace] = useRecoilState(workspaceInfo);
  const [expenseData, setExpenseData] = useState(null);

  const { GstinStatus, workspace: selectedWorkspaceFromLocation } = state || {};

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const [selectedWorkspace, setSelectedWorkspace] = useState(
    workspace || selectedWorkspaceFromLocation || null
  );
  const closeEmailModal = () => setIsEmailModalOpen(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [stateShortForm, setStateShortForm] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedMonthBar, setSelectedMonthBar] = useState(null);
  const [selectedMonthLine, setSelectedMonthLine]=useState(null)
  const [exemptedStatusState, setExemptedStatusState]=useState(null)

  const handleRequestClick = () => {
    setIsEmailModalOpen(true);
  };

  const hasGstin = true;

  const [counts, setCounts] = useState({
    exempted: 0,
    eligible: 0,
    notEligible: 0,
  });
  const [percentages, setPercentages] = useState({
    exempted: 0,
    eligible: 0,
    notEligible: 0,
  });

  const chartRef = useRef(null);

  const [totalVendors, setTotalVendors] = useState(0);
  const [missingContactCount, setMissingContactCount] = useState(0);
  const [compliantVendors, setCompliantVendors] = useState(0);
  const [nonCompliantVendors, setNonCompliantVendors] = useState(0);

  const [vendors, setVendors] = useState(null);
  const [vendorCount, setVendorCount]=useState(null)
  const [followupStatus, setFollowupStatus]=useState(null)

  const stateShortFormMapping = {
    "01": "JK", // Jammu & Kashmir
    "02": "HP", // Himachal Pradesh
    "03": "PB", // Punjab
    "04": "CH", // Chandigarh
    "05": "UK", // Uttarakhand
    "06": "HR", // Haryana
    "07": "DL", // Delhi
    "08": "RJ", // Rajasthan
    "09": "UP", // Uttar Pradesh
    10: "BR", // Bihar
    11: "SK", // Sikkim
    12: "AR", // Arunachal Pradesh
    13: "NL", // Nagaland
    14: "MN", // Manipur
    15: "MZ", // Mizoram
    16: "TR", // Tripura
    17: "ML", // Meghalaya
    18: "AS", // Assam
    19: "WB", // West Bengal
    20: "JH", // Jharkhand
    21: "OD", // Odisha
    22: "CG", // Chhattisgarh
    23: "MP", // Madhya Pradesh
    24: "GJ", // Gujarat
    25: "DD", // Daman & Diu
    26: "DN", // Dadra & Nagar Haveli
    27: "MH", // Maharashtra
    28: "AP", // Andhra Pradesh
    29: "KA", // Karnataka
    30: "GA", // Goa
    31: "LD", // Lakshadweep
    32: "KL", // Kerala
    33: "TN", // Tamil Nadu
    34: "PY", // Puducherry
    35: "AN", // Andaman & Nicobar Islands
    36: "TG", // Telangana
    37: "AP", // Andhra Pradesh (New)
  };

  useEffect(() => {
    let chartInstance;

    if (counts && percentages && counts.exempted !== undefined) {
      const { exempted, eligible, notEligible } = counts;
      const totalVendors = exempted + eligible + notEligible; // Calculate total vendors

      if (chartRef.current) {
        chartInstance = AgCharts.create({
          container: chartRef.current,
          data: [
            {
              category: "Eligible",
              value: eligible,
              label: `${percentages.eligible}%`,
            },
            {
              category: "Not Eligible",
              value: notEligible,
              label: `${percentages.notEligible}%`,
            },
            {
              category: "Exempted",
              value: exempted,
              label: `${percentages.exempted}%`,
            },
          ],
          series: [
            {
              type: "donut",
              angleKey: "value",
              labelKey: "category",
              fills: ["#006D77", "#83C5BE", "#EDF6F9"],
              strokeWidth: 0,
              innerRadiusOffset: -50,
              innerLabels: [
                {
                  text: totalVendors.toString(), // Dynamic total vendors count
                  fontSize: 20,
                  color: "black",
                },
                {
                  text: "Vendors", // Label
                  fontSize: 12,
                  color: "#718096",
                },
              ],
              tooltip: {
                renderer: (params) => {
                  return {
                    content: `${params.datum.category}: ${params.datum.label}`,
                  };
                },
              },
            },
          ],
          legend: {
            enabled: true, // Enable the legend
            position: "right", // Legend is placed on the right
            spacing: 20, // Adjust spacing between legend items
            item: {
              marker: {
                shape: "circle",
                size: 12, // Adjust size of the circle in the legend
                strokeWidth: 0,
              },
              paddingX: 10, // Space between marker and text
              paddingY: 12, // Space between legend items
              label: {
                fontSize: 14,
                color: "#4A5568",
                formatter: ({ datum }) => {
                  if (datum) {
                    // Format legends as: "Category: Count"
                    return `${datum.category}: ${datum.value}`;
                  }
                  return "";
                },
              },
            },
          },
          background: {
            fill: "transparent",
          },
          padding: {
            right: 50, // Adjust padding to accommodate legend
          },
          listeners: {
            seriesNodeClick: (event) => {
              console.log("Inside listeners");
              const clickedCategory = event.datum.category;
              vendorSummary(clickedCategory); // Use the existing function
            },
          },
        });
      }
    }

    // Clean up chart instance on unmount or re-render
    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [counts, percentages]); // Re-render when counts or percentages change

  const OnboardingView = () => (
    <div className="onboarding-dashboard">
      <div className="dashboard-header">
        <img src={FinkraftLogo} alt="logo" />
        {/* <div className="dashboard-right-header">
          <div
            className="vendorfollowup-dropdown-container"
            style={{ marginTop: "45px" }}
          >
            <div
              className={`vendorfollowup-dropdown-header ${
                isDropdownOpen ? "active" : ""
              }`}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              {selectedWorkspace || "Select a workspace"}
              <span className="vendorfollowup-dropdown-icon">
                {isDropdownOpen ? "▲" : "▼"}
              </span>
            </div>

            {isDropdownOpen && (
              <div className="vendorfollowup-dropdown-body">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleSearch}
                  placeholder="Search workspaces..."
                  className="vendorfollowup-dropdown-search"
                />
                {filteredOptions.length > 0 ? (
                  filteredOptions.map((workspace) => (
                    <div
                      key={workspace.id}
                      className="vendorfollowup-dropdown-item"
                      onClick={() => handleSelectOption(workspace)}
                    >
                      {workspace.name}
                    </div>
                  ))
                ) : (
                  <div className="vendorfollowup-dropdown-item no-options">
                    No workspaces found
                  </div>
                )}
              </div>
            )}
          </div>
          <Button className="header-btn">
            <img
              src={phoneIcon}
              alt=""
              style={{ width: "19px", padding: "0px" }}
            />
            Contact{" "}
          </Button>

          
        </div>
      </div>

      <div className="content-grid">
        {/* First Row */}
        <div className="card integration-section">
          <span className="integration-badge">Integration</span>

          <div className="title-container">
            <img
              src={GstinLogo}
              alt="logo"
              style={{ width: "35px", marginTop: "5px" }}
            />
            <h2>GST integration</h2>
          </div>

          <p className="description">
            We will match your invoices with GSTR 2B and generate a report for
            the claimable amount. To proceed, we require you to authenticate
            with GST Portal Credentials.
          </p>

          <div className="button-group">
            <button className="invite-button">
              <span>→</span>
              Invite user who has GST credentials
            </button>
            <button className="add-pan-button">Add PAN / GSTIN </button>
          </div>

          <div className="info-text">ⓘ Invite user who has credentials</div>
        </div>

        <div className="card portal-preview">
          <img src={GSTPortal} alt="GST Portal" />
        </div>

        {/* Second Row */}
        <div className="card info-card">
          <span className="demo-badge">Demo data</span>

          <h3>Summary</h3>

          <p className="content">
            Stay ahead of GST regulations with a secure platform that provides
            real-time insights into your vendor ecosystem with seamless GST
            integration, monitor e-invoice eligibility in single place.
          </p>

          <Button
            type="primary"
            disabled
            style={{
              width: "100%",
              borderRadius: "8px",
              background: "#9DCDD4",
              color: "#fff",
              border: "none",
            }}
          >
            Continue →
          </Button>
        </div>

        <div className="card info-card">
          <span className="demo-badge">Demo data</span>

          <h3>Vendor Management</h3>

          <ul className="vendor-list">
            <li>Monitor GST return filing status of all vendors.</li>
            <li>Tag and categorise vendors based on compliance.</li>
            <li>Automated notifications to vendors for compliance lapses.</li>
          </ul>

          <Button
            type="primary"
            disabled
            style={{
              width: "100%",
              borderRadius: "8px",
              background: "#9DCDD4",
              color: "#fff",
              border: "none",
            }}
          >
            Continue →
          </Button>
        </div>
      </div>
    </div>
  );

  const navigate = useNavigate();

  const handleNavigation = () => {
    // Navigate to the next page with the hardcoded workspace query parameter
    navigate(`/vendorfollowup/vendoreinvoice?workspace=${workspace}`, {
      state: { selectedMonths },
    });
  };

  const summarypage = () => {
    // Navigate to the next page with the hardcoded workspace query parameter

    if (userRole === "ADMIN") {
      navigate(`/credential/gst/list`);
    } else {
      setAccessDeniedModalVisible(true); // Show modal if not an admin
    }
  };

  const handleNavigation1 = () => {
    navigate(`/vendorfollowup/vendorlist`);
  };
  const vendorSummary = (category) => {
    console.log("&&&&&&&&&&&&&&&&&&&");
    navigate(
      `/vendorfollowup/vendorSummary?category=${encodeURIComponent(category)}`
    );
  };

  const handleGstIntegration = () => {
    // Navigate to the next page with the hardcoded workspace query parameter

    if (userRole === "ADMIN") {
      navigate("/credential/gst/invite/user");
    } else {
      setAccessDeniedModalVisible(true); // Show modal if not an admin
    }
  };

  const handleAddPan = () => {
    // Navigate to the next page and pass 'pan' as state

    if (userRole === "ADMIN") {
      navigate(`/credential/gst/add/pan`, { state: { pan: "" } });
    } else {
      setAccessDeniedModalVisible(true); // Show modal if not an admin
    }
  };

  const opensetting = () => {
    if (userRole === "ADMIN") {
      navigate(`/vendorfollowup/memebersetting`);
    } else {
      setAccessDeniedModalVisible(true); // Show modal if not an admin
    }
  };

  // useEffect(() => {
  //   // Fetch vendor data
  //   axios
  //     .get(
  //       `https://fuzzyapi.finkraftai.com/vendor-data?workspace=${workspace}`
  //     )
  //     .then((response) => {
  //       const vendorList = response.data.map((vendor) => ({
  //         vendorName: vendor.enablement?.legalNm || "",
  //         gstin: vendor.gstin,
  //         eInvoice: vendor.einvoiceStatus,
  //         invoiceStatus: vendor.invoiceStatus, // Assuming invoiceStatus exists
  //       }));

  //       setTotalVendors(vendorList.length); // Set total vendors count

  //       // Calculate compliant and non-compliant vendors
  //       const compliant = vendorList.filter(
  //         (vendor) =>
  //           vendor.invoiceStatus === "Invoice Received" &&
  //           vendor.eInvoice === "Yes"
  //       ).length;
  //       setCompliantVendors(compliant);

  //       const nonCompliant = vendorList.filter(
  //         (vendor) =>
  //           vendor.invoiceStatus === "Invoice Not Received" &&
  //           vendor.eInvoice === "Yes"
  //       ).length;
  //       setNonCompliantVendors(nonCompliant);

  //       // Fetch contact data
  //       axios
  //         .get(
  //           `https://fuzzyapi.finkraftai.com/get-contacts?workspace=${workspace}`
  //         )
  //         .then((contactResponse) => {
  //           const contactData = contactResponse.data;

  //           // Update missing contact count
  //           const missingContacts = vendorList.filter(
  //             (vendor) =>
  //               !contactData.find(
  //                 (contact) =>
  //                   contact.vendorName === vendor.vendorName &&
  //                   contact.gstin === vendor.gstin
  //               )
  //           );
  //           setMissingContactCount(missingContacts.length); // Set missing contacts count
  //         })
  //         .catch((contactError) => {
  //           console.error("Error fetching contact data:", contactError);
  //         });
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching vendor data:", error);
  //     });
  // }, [workspace]);
  const [entities, setEntities] = useState([]);

  // Fetch data in useEffectc
  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentWorkspace = localStorage.getItem("currentWorkspace");
        const response = await axios.get(
          // `https://fuzzyapi.finkraftai.com/fetch-gstins?workspace_name=${currentWorkspace}`
          `${API_ENDPOINT_FETCH_GSTINS}${currentWorkspace}`
        );
        if (response.status === 200) {
          // Map the response data into the format required by the UI
          const formattedData = response.data.map((item) => ({
            pan: item.pan,
            tradeName: item.trade_name,
            gstin: item.gstin_count,
            credStatus: "✔ Completed", // Default Cred Status as Active
          }));
          setEntities(formattedData);
        }
      } catch (error) {
        console.error("Error fetching entities:", error);
        setEntities([]);
      }
    };

    if (workspace) {
      fetchData();
    }
  }, [workspace]);


  
    // Define an async function to fetch the expense data.
    const fetchExpenseData = async (month) => {
      const currentWorkspace = localStorage.getItem('currentWorkspace');
      console.log(selectedMonth,"selected month")
      if (!currentWorkspace) {
        console.error("No currentWorkspace found in localStorage");
        return;
      }
      try {
        const response = await fetch(API_ENDPOINT_EXPENSE_DATA, { // adjust endpoint URL if needed
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          
          body: JSON.stringify({
            collectionName: "vendor_tally", 
            currentWorkspace,
            selectedMonth: selectedMonth ? selectedMonth.format("MMYYYY") : null,
          }),
        });
        const result = await response.json();
        // setExemptedStatusState()

        if (!response.ok) {
          throw new Error(result.message || "Error fetching expense data");
        }
        setExpenseData(result);
      } catch (err) {
        console.error("Error fetching expense data:", err);
      
      }
    };
    useEffect(() => {
      // Log to see when the API is called and with which month.
      console.log("Calling API with month:", selectedMonth ? selectedMonth.format("MMYYYY") : "none");
      fetchExpenseData(selectedMonth);
    }, [workspace, selectedMonth]);


    // Define the async function to fetch top ten vendors on page load.
    const fetchTopTenVendors = async (month) => {
      // Get currentWorkspace from localStorage, or adjust as necessary.
      const currentWorkspace = localStorage.getItem('currentWorkspace');
      try {
        const response = await fetch(API_ENDPOINT_TOP_TEN_VENDORS, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            collectionName: "vendor_tally", // or adjust to your actual collection name
            currentWorkspace,
            db: "gstservice", // optional if needed by the backend
            selectedMonth: month ? month.format("MMYYYY") : null,
          }),
        });
        const result = await response.json();
        if (!response.ok) {
          throw new Error(result.message || "Error fetching vendor data");
        }
        // Assuming the backend returns an object with a "data" property containing the results.
        setVendors(result.data);
      } catch (error) {
        console.error("Error fetching top ten vendors:", error);
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      // Log to see when the API is called and with which month.
      console.log("Calling API with month:", selectedMonth ? selectedMonth.format("MMYYYY") : "none");
      fetchTopTenVendors(selectedMonthBar);
    }, [workspace, selectedMonthBar]);
  useEffect(() => {
    // Define an async function to fetch the expense data.
    const fetchGSTRisk = async (selectedMonthLine) => {
     
      try {
        const response = await fetch(API_ENDPOINT_FOLLOW_UP_STATUS, { // adjust endpoint URL if needed
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          
          body: JSON.stringify({
            collectionName: "weekly_gst_risk", 
            currentWorkspace,
            selectedMonthLine: selectedMonthLine ? selectedMonthLine.format("MMYYYY") : null,
          
          }),
        });
        const result = await response.json();

        if (!response.ok) {
          throw new Error(result.message || "Error fetching expense data");
        }
        setFollowupStatus(result);
      } catch (err) {
        console.error("Error fetching expense data:", err);
      
      }
    };

    // Call the async function immediately after component mounts.
    fetchGSTRisk(selectedMonthLine);
  }, [workspace, selectedMonthLine]);
  useEffect(() => {
    // Define an async function to fetch the expense data.
    const fetchVendorCount = async () => {
      const currentWorkspace = localStorage.getItem('currentWorkspace');
      if (!currentWorkspace) {
        console.error("No currentWorkspace found in localStorage");
        return;
      }
      try {
        const response = await fetch(API_ENDPOINT_VENDOR_COUNT, { // adjust endpoint URL if needed
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          
          body: JSON.stringify({
            collectionName: "vendor_tally", 
            currentWorkspace,
          }),
        });
        const result = await response.json();

        if (!response.ok) {
          throw new Error(result.message || "Error fetching expense data");
        }
        setVendorCount(result);
      } catch (err) {
        console.error("Error fetching expense data:", err);
      
      }
    };

    // Call the async function immediately after component mounts.
    fetchVendorCount();
  }, [workspace]);

  const handleMonthChange = (month) => {
    setSelectedMonth(month);
    console.log("Selected Month in Parent:", month);
    // You can use this month value to filter data or trigger other actions
  };
  const handleMonthChangeBar = (month) => {
    setSelectedMonthBar(month);
    console.log("Selected Month in Parent:", month);
    // You can use this month value to filter data or trigger other actions
  };
  const handleMonthChangeLine = (month) => {
    setSelectedMonthLine(month);
    console.log("Selected Month in Parent:", month);
    // You can use this month value to filter data or trigger other actions
  };

  const [gstAmountData, setGstAmountData] = useState(null);

  const [gst2AData, setGst2AData] = useRecoilState(gst2ADataState); // State for 2A
  const [gst2BData, setGst2BData] = useRecoilState(gst2BDataState); // State for 2B
  const [loading, setLoading] = useState(true);
  const [is2A, setIs2A] = useState(true); // Default to 2A

  // useEffect(() => {
  //   const fetchGstData = async (tableType) => {
  //     console.log("2A",gst2AData)
  //     const apiUrl = "https://fuzzyapi.finkraftai.com/sum-gst-eligible";
  //     setLoading(true);

  //     try {
  //       const response = await fetch(apiUrl, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           workspaceName: workspace,
  //           tableType: tableType, // Send table type based on toggle (2A or 2B)
  //         }),
  //       });

  //       if (response.ok) {
  //         const data = await response.json();

  //         if (tableType === "2A") {
  //           setGst2AData(data); // Store data for 2A
  //         } else {
  //           setGst2BData(data); // Store data for 2B
  //         }
  //       } else {
  //         console.error("Failed to fetch GST data");
  //       }
  //     } catch (error) {
  //       console.error("Error:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   // If workspace is available and data is not already present for 2A, fetch 2A data

  //   if (workspace && !gst2AData) {
  //     fetchGstData("2A");
  //   }

  //   // If workspace is available and data is not already present for 2B, fetch 2B data
  //   if (workspace && !gst2BData) {
  //     fetchGstData("2B");
  //   }

  // }, [workspace, gst2AData, gst2BData, setGst2AData, setGst2BData]);

  const [isMonthsDropdownOpen, setIsMonthsDropdownOpen] = useState(false);
  // const [selectedMonths, setSelectedMonths] = useState([
  //   "All", // Initialize with "All" selected
  //   "042024",
  //   "052024",
  //   "062024",
  //   "072024",
  //   "082024",
  //   "092024",
  //   "102024",
  //   "112024",
  //   "122024",
  // ]);

  // const [monthOptions] = useState([
  //   { value: "All", label: "All Months" },
  //   { value: "042024", label: "Apr-2024" },
  //   { value: "052024", label: "May-2024" },
  //   { value: "062024", label: "Jun-2024" },
  //   { value: "072024", label: "Jul-2024" },
  //   { value: "082024", label: "Aug-2024" },
  //   { value: "092024", label: "Sep-2024" },
  //   { value: "102024", label: "Oct-2024" },
  //   { value: "112024", label: "Nov-2024" },
  //   { value: "122024", label: "Dec-2024" },
  // ]);

  const [selectedMonths, setSelectedMonths] = useState(["All"]);
  const [monthOptions, setMonthOptions] = useState([]);

  // Handle checkbox change
  const handleCheckboxChange = (month) => {
    setSelectedMonths((prev) => {
      if (month === "All") {
        // Toggle "All" checkbox
        if (prev.includes("All")) {
          return []; // Deselect all months if "All" is unchecked
        } else {
          return ["All", ...monthOptions.map((option) => option.value)];
        }
      } else {
        if (prev.includes(month)) {
          return prev.filter((item) => item !== month); // Deselect specific month
        } else {
          return [...prev, month]; // Select specific month
        }
      }
    });
  };

  // Prepare selected months for API payload
  const getPayloadMonths = () => {
    // If "All" is selected, use all months, otherwise return selected months excluding "All"
    return selectedMonths.includes("All")
      ? monthOptions
          .map((option) => option.value)
          .filter((value) => value !== "All")
      : selectedMonths;
  };



  const currentData = is2A ? gst2AData : gst2BData;

  const [userDetails, setUserDetails] = useRecoilState(
    userInfo || { workspaces: [] }
  );

  const [isFetchingData, setIsFetchingData] = useState(true);

  useEffect(() => {
    if (!workspace) return;

    const fetchCounts = async () => {
      const currentWorkspace = localStorage.getItem("currentWorkspace");
      try {
        setIsFetchingData(true); // Show loader
        const response = await fetch(
          `${API_ENDPOINT_VENDOR_COUNT_NEW}?workspace=${currentWorkspace}&tableType=${is2A ? "2A" : "2B"}`
        );
        

        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        console.log("API Data:", data);

        setCounts({
          exempted: data.counts?.exempted || 0,
          eligible: data.counts?.eligible || 0,
          notEligible: data.counts?.not_eligible || 0,
        });

        setPercentages({
          exempted: data.percentages?.exempted || 0,
          eligible: data.percentages?.eligible || 0,
          notEligible: data.percentages?.not_eligible || 0,
        });
      } catch (err) {
        console.error("Error fetching counts:", err);
        setCounts({});
        setPercentages({});
      } finally {
        setIsFetchingData(false);
      }
    };

    fetchCounts();
  }, [workspace, is2A]);

  const [isAccessDeniedModalVisible, setAccessDeniedModalVisible] =
    useState(false);

  useEffect(() => {
    // Fetch workspace data on mount
    const fetchUserInfo = async () => {
      try {
        const response = await apiGet(API_ENDPOINT_GET_USER_INFO, false);
        console.log("API Response:", response);
        if (response.status && response.data?.workspaces) {
          setWorkspaces(response.data.workspaces);
          setUserDetails({
            ...userDetails,
            workspaces: response.data.workspaces,
          });
          setWorkspace(response.data?.workspaces?.[0]?.name);
          setSelectedWorkspace(response.data?.workspaces?.[0]?.name);
          setFilteredOptions(response.data.workspaces); // Initialize filtered options
          const userWorkspace = response.data.workspaces[0]; // Assuming first workspace
          // setUserRole(userWorkspace?.role);
          
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchUserInfo();
  }, []);

  // Filter options based on the search query
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    setFilteredOptions(
      workspaces.filter((workspace) =>
        workspace.name.toLowerCase().includes(query)
      )
    );
  };

  // Handle selection of a workspace
  const handleSelectOption = (workspace) => {
    setSelectedWorkspace(workspace.name);
    setWorkspace(workspace.name);
    setIsDropdownOpen(false);
    setSearchQuery("");
    setFilteredOptions(workspaces); // Reset the filtered options
  };

  const toggleTable = () => {
    setIs2A((prev) => !prev); // Toggle between 2A and 2B
  };

  const [showPopup, setShowPopup] = useState(false);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/auth/signin");
  };

  const handleCancel = () => {
    setShowPopup(false);
  };
  const [rowData, setRowData] = useState([]);

  const transformData = (apiData) => {
    // Extract InvoiceBreakdown safely
    const breakdown = apiData[0]?.InvoiceBreakdown || {};

    // Safely extract Invoice Received and Invoice Not Received breakdowns
    const receivedBreakdown =
      breakdown["Invoice Received"]?.EligiblityBreakdown || {};
    const notReceivedBreakdown =
      breakdown["Invoice Not Received"]?.EligiblityBreakdown || {};

    // Define a function to safely access nested properties
    const getSafeValue = (obj, key, defaultValue = 0) =>
      obj?.[key] || defaultValue;

    return [
      {
        category: "Eligible",
        receivedInvoiceCount: getSafeValue(
          receivedBreakdown.Eligible,
          "InvoiceCount"
        ),
        receivedTotalAmount: getSafeValue(receivedBreakdown.Eligible, "Amount"),
        receivedTotalGST: getSafeValue(receivedBreakdown.Eligible, "GSTAmount"),
        notReceivedInvoiceCount: getSafeValue(
          notReceivedBreakdown.Eligible,
          "InvoiceCount"
        ),
        notReceivedTotalAmount: getSafeValue(
          notReceivedBreakdown.Eligible,
          "Amount"
        ),
        notReceivedTotalGST: getSafeValue(
          notReceivedBreakdown.Eligible,
          "GSTAmount"
        ),
      },
      {
        category: "Not Eligible",
        receivedInvoiceCount: getSafeValue(
          receivedBreakdown["Not Eligible"],
          "InvoiceCount"
        ),
        receivedTotalAmount: getSafeValue(
          receivedBreakdown["Not Eligible"],
          "Amount"
        ),
        receivedTotalGST: getSafeValue(
          receivedBreakdown["Not Eligible"],
          "GSTAmount"
        ),
        notReceivedInvoiceCount: getSafeValue(
          notReceivedBreakdown["Not Eligible"],
          "InvoiceCount"
        ),
        notReceivedTotalAmount: getSafeValue(
          notReceivedBreakdown["Not Eligible"],
          "Amount"
        ),
        notReceivedTotalGST: getSafeValue(
          notReceivedBreakdown["Not Eligible"],
          "GSTAmount"
        ),
      },
      {
        category: "Exempted",
        receivedInvoiceCount: getSafeValue(
          receivedBreakdown.Exempted,
          "InvoiceCount"
        ),
        receivedTotalAmount: getSafeValue(receivedBreakdown.Exempted, "Amount"),
        receivedTotalGST: getSafeValue(receivedBreakdown.Exempted, "GSTAmount"),
        notReceivedInvoiceCount: getSafeValue(
          notReceivedBreakdown.Exempted,
          "InvoiceCount"
        ),
        notReceivedTotalAmount: getSafeValue(
          notReceivedBreakdown.Exempted,
          "Amount"
        ),
        notReceivedTotalGST: getSafeValue(
          notReceivedBreakdown.Exempted,
          "GSTAmount"
        ),
      },
    ];
  };

  const columnDefs = [
    {
      headerName: "Category",
      field: "category",
    },
    {
      headerName: "Invoice Received",
      children: [
        {
          headerName: "Invoice Count",
          field: "receivedInvoiceCount",
          valueFormatter: (params) => {
            if (params.value !== undefined && params.value !== null) {
              const roundedValue = Math.round(params.value);
              return new Intl.NumberFormat("en-IN", {
                minimumFractionDigits: 0,
              }).format(roundedValue);
            }
            return "";
          },
          cellStyle: () => ({
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }),
        },
        {
          headerName: "Total Amount",
          field: "receivedTotalAmount",
          valueFormatter: (params) => {
            if (params.value !== undefined && params.value !== null) {
              const roundedValue = Math.round(params.value);
              return new Intl.NumberFormat("en-IN", {
                minimumFractionDigits: 0,
              }).format(roundedValue);
            }
            return "";
          },
          cellStyle: () => ({
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }),
        },
        {
          headerName: "Total GST Amount",
          field: "receivedTotalGST",
          valueFormatter: (params) => {
            if (params.value !== undefined && params.value !== null) {
              const roundedValue = Math.round(params.value);
              return new Intl.NumberFormat("en-IN", {
                minimumFractionDigits: 0,
              }).format(roundedValue);
            }
            return "";
          },
          cellStyle: () => ({
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }),
        },
      ],
    },
    {
      headerName: "Invoice Not Received",
      children: [
        {
          headerName: "Invoice Count",
          field: "notReceivedInvoiceCount",
          valueFormatter: (params) => {
            if (params.value !== undefined && params.value !== null) {
              const roundedValue = Math.round(params.value);
              return new Intl.NumberFormat("en-IN", {
                minimumFractionDigits: 0,
              }).format(roundedValue);
            }
            return "";
          },
          cellStyle: () => ({
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }),
        },
        {
          headerName: "Total Amount",
          field: "notReceivedTotalAmount",
          valueFormatter: (params) => {
            if (params.value !== undefined && params.value !== null) {
              const roundedValue = Math.round(params.value);
              return new Intl.NumberFormat("en-IN", {
                minimumFractionDigits: 0,
              }).format(roundedValue);
            }
            return "";
          },
          cellStyle: () => ({
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }),
        },
        {
          headerName: "Total GST Amount",
          field: "notReceivedTotalGST",
          valueFormatter: (params) => {
            if (params.value !== undefined && params.value !== null) {
              const roundedValue = Math.round(params.value);
              return new Intl.NumberFormat("en-IN", {
                minimumFractionDigits: 0,
              }).format(roundedValue);
            }
            return "";
          },
          cellStyle: () => ({
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }),
        },
      ],
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
  };

  useEffect(() => {
    const options = getMonthOptions();
    setMonthOptions(options);
    // Set selected months to include all available months (including "All")
    const allMonthsSelected = options.map((option) => option.value);
    setSelectedMonths(allMonthsSelected); // Select all by default
  }, []);

  useEffect(() => {
    const currentWorkspace = localStorage.getItem("currentWorkspace");
    const dataType = is2A ? "2A" : "2B"; // Determine the data type based on toggle
    setIsFetchingData(true);

    // API call
    fetch(API_ENDPOINT_EINVOICE_PIPELINE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        workspace_id: currentWorkspace,
        type: dataType, // Include the type (2A or 2B) in the payload
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const transformedData = transformData(data);
        setRowData(transformedData);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsFetchingData(false); // Ensure fetching state is reset
      });
  }, [is2A]); // Re-run effect when is2A changes

  const [isPivotTableModalOpen, setIsPivotTableModalOpen] = useState(false);

  // Handle modal toggle
  const handlePivotTableModalToggle = () => {
    setIsPivotTableModalOpen(!isPivotTableModalOpen);
  };
  // const [userRole, setUserRole] = useRecoilState(roleInfo);
  const userRole = localStorage.getItem("role");

  const [activeTab, setActiveTab] = useState("invoice");
  const [showVendorDetails, setShowVendorDetails] =
    useRecoilState(vendorDetailsDisplay);

  // const handleValidate = () => {
  //   setShowVendorDetails(true);
  // }
  const userRoleCheck=localStorage.getItem("roleInfo")
  console.log(userRoleCheck,"userRoleCheck")

  const [formData, setFormData] = useState({
    vendorGSTIN: "",
    financialYear: "",
    invoiceType: "",
    invoiceNumber: "",
    irnNumber: "",
  });

  const [isIrnMode, setIsIrnMode] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const [irnloading, setIrnLoading] = useState(false);

  const [invoiceStatus, setInvoiceStatus] = useState("Recieved");

  // const [invoiceData, setInvoiceData] = useState({
  //   regtime: "Not Available",
  //   irn: "Not Available",
  //   regirp: "Not Available",
  //   status: "Not Available"
  // });
  console.log(is2A, "Checking the toggle");
  const currentWorkspace = localStorage.getItem("currentWorkspace");
  console.log(currentWorkspace, "currentWorkspace");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [invoiceData, setInvoiceData] = useRecoilState(irndata);

  const mapInvoiceType = (type) => {
    switch (type) {
      case "INV":
        return "E-tax invoice";
      case "CRN":
        return "Credit note";
      case "DBN":
        return "Debit note";
      default:
        return type; // Return the original type if no mapping is found
    }
  };
  const mapInvoiceStatus = (status) => {
    return status === "Invoice Received" ? "Received" : "Not Received";
  };

  const handleValidate = async () => {
    if (
      (activeTab === "invoice" &&
        (!formData.vendorGSTIN ||
          !formData.financialYear ||
          !formData.invoiceType ||
          !formData.invoiceNumber)) ||
      (activeTab === "irn" && !formData.irnNumber)
    ) {
      alert("Please fill all the required fields.");
      return;
    }

    if (
      activeTab === "irn" &&
      (!formData.irnNumber || formData.irnNumber.length !== 64)
    ) {
      alert("IRN Number must be exactly 64 characters.");
      return;
    }

    setIrnLoading(true);
    try {
      const currentWorkspace = localStorage.getItem("currentWorkspace");
      // const payload = activeTab === "irn" ? { irnNumber: formData.irnNumber } : formData;
      const collection = 'vendor_tally'
      const payload =
        activeTab === "irn"
          ? {
              irnNumber: formData.irnNumber,
              collection: collection,
              db: "gstservice",
              currentWorkspace,
            }
          : {
              ...formData,
              collection: collection,
              db: "gstservice",
              currentWorkspace,
            };
      const response = await fetch(
        API_ENDPOINT_VENDOR_VALIDATE,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      console.log(response, "response");

      const data = await response.json();
      // const data=response.data
      // setIsModalVisible(true)
      // setShowVendorDetails(true);
      // setInvoiceStatus(data.web_data?.irn ? "✔ Received" : "Not Received");
      // setInvoiceData({
      //   regtime: data.web_data?.regtime || "Not Available",
      //   irn: data.web_data?.irn || "Not Available",
      //   regirp: data.web_data?.regirp || "Not Available",
      //   status: data.web_data?.status || "Not Available",
      // });
      console.log(data.data, "data");
      setInvoiceData({
        inum: data.data.inum,
        date: data.data.dt,
        eligibleStatus: data.data.einvoiceEligibility,
        invoiceStatus: mapInvoiceStatus(data.data.invoiceStatus),
        vendorName: data.data.enablement.legalNm,
        pan: data.data.pan,
        vendorGSTIN: data.data.ctin,
        totalGSTAmount: data.data.totalGstAmount,
        // invoiceType:data.data.invType
        invoiceType: mapInvoiceType(data.data.invType),
        assetLink: data.data.assetlink,
      });
      console.log(setInvoiceData.vendorData, "gstin value check");
      const gstinPrefix = data.data.ctin?.substring(0, 2);
      console.log(gstinPrefix, "gstinPrefix");
      const stateShortForm = stateShortFormMapping[gstinPrefix] || "Unknown";
      console.log(stateShortForm, "state form");
      setStateShortForm(stateShortForm);

      if (data) {
        setIsModalVisible(true);
        setShowVendorDetails(true);
      }
    } catch (error) {
      console.error("Error validating data:", error);
      alert("Validation failed. Please try again.");
    } finally {
      setIrnLoading(false);
    }
  };

  console.log(invoiceData, "invoiceData");

  const handleInputChange = (e) => {
    setGstin(e.target.value);
  };

  const handleSearchgstin = () => {
    if (gstin.trim()) {
      setIsSideMenuOpen(true);
    }
  };

  const closeSideMenu = () => {
    setIsSideMenuOpen(false);
  };

  const [gstin, setGstin] = useState("");
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  console.log(currentData, "currentData");

  const formatCurrency = (value) => {
    if (value >= 10000000) {
      // Convert to crores
      return (value / 10000000).toFixed(2) + "Cr";
    } else if (value >= 100000) {
      // Convert to lakhs
      return (value / 100000).toFixed(2) + "L";
    }
    return value.toLocaleString("en-IN"); // Default formatting
  };

  const items = [
    {
      key: "1",
      label: "Dashboard",
    },
    // {
    //   key: "2",
    //   label: "Master Data",
    // },
  ];

  const handleTabChange = (key) => {
    if (key === "2") {
      navigate("/vendorfollowup/vendoreinvoice/tabs");
    }
  };
  return hasGstin ? (
    <div style={{ display: "flex" }} className="dashboard">
      {/* Sidebar / Primary Navigation */}
      <PrimaryNavBhilosa workspace={workspace}/>
      <div className="MainContainer">
        <div className="TopHeader">
          <div className="ContainerFirst">
            <Typography variant="h5" weight={500}>
              Welcome
            </Typography>
          </div>
          <Tabs defaultActiveKey="1" items={items} onChange={handleTabChange}  />
        </div>
        <div className="DashboardContent">
          <div className="DashbaordRowItem">
            <div style={{ width: "55%" }}>
              <InvoiceIRN />
            </div>
            <div style={{ width: "45%" }}>
              <Vendor />
            </div>
          </div>
          <div className="DashbaordRowItem">
            <div style={{ width: "35%" }}>
              <ExpenseTypeFile expenseData={expenseData} onMonthChange={handleMonthChange} />
            </div>
            <div style={{ width: "65%" }}>
              <Bar vendors={vendors} onMonthChange={handleMonthChangeBar}/>
            </div>
          </div>

          <div className="DashbaordRowItem">
            <div style={{ width: "35%" }}>
              <Donut vendorCount={vendorCount}/>
            </div>
            <div style={{ width: "65%" }}>
              <FollowUpStatus followupStatus={followupStatus} onMonthChange={handleMonthChangeLine}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <OnboardingView />
  );
};

export default React.memo(GSTIntegration);
