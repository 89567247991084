import React from "react";
import "./InvoiceIRN.scss";
import { Button, Input, Modal, Tag } from "antd";
import { useState } from "react";
import { useRecoilState } from "recoil";
import {
  gst2ADataState,
  gst2BDataState,
  irndata,
  roleInfo,
  userInfo,
  vendorDetailsDisplay,
  workspaceInfo,
} from "app/config/States/users";

//Import the endpoints
import { API_ENDPOINT_VENDOR_VALIDATE } from "../../vendorfollowup.constants";
import { BeatLoader } from "react-spinners";
import CircleIcon from "@mui/icons-material/Circle";
import { EyeOutlined, CheckCircleFilled ,CloseCircleFilled} from "@ant-design/icons";
import { ReactComponent as ArrowIcon } from "../../arrow.svg";
import EmailModal from "../EmailModel/EmailModel";
import Typography from "app/shared/Typography";
import { Select } from "antd";
import { DownOutlined } from "@ant-design/icons";

const InvoiceIRN = () => {
  const [activeTab, setActiveTab] = useState("invoice");
  const { Option } = Select;//new
  const [showVendorDetails, setShowVendorDetails] =
    useRecoilState(vendorDetailsDisplay);
  const [irnloading, setIrnLoading] = useState(false);
  const [is2A, setIs2A] = useState(true);
  const [invoiceData, setInvoiceData] = useRecoilState(irndata);
  const [stateShortForm, setStateShortForm] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const closeEmailModal = () => setIsEmailModalOpen(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    vendorGSTIN: "",
    financialYear: "",
    invoiceType: "",
    invoiceNumber: "",
    irnNumber: "",
  });
  const handleRequestClick = () => {
    setIsEmailModalOpen(true);
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };


  const handleChange = (value, option) => {
    if (option && option.name) {
      // Handling Ant Design Select
      setFormData((prevData) => ({
        ...prevData,
        [option.name]: value, // Update based on the select field name
      }));
    } else if (value?.target) {
      // Handling regular input field
      const { name, value: inputValue } = value.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: inputValue,
      }));
    }
  };

  const handleSelectChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };




  const stateShortFormMapping = {
    "01": "JK", // Jammu & Kashmir
    "02": "HP", // Himachal Pradesh
    "03": "PB", // Punjab
    "04": "CH", // Chandigarh
    "05": "UK", // Uttarakhand
    "06": "HR", // Haryana
    "07": "DL", // Delhi
    "08": "RJ", // Rajasthan
    "09": "UP", // Uttar Pradesh
    10: "BR", // Bihar
    11: "SK", // Sikkim
    12: "AR", // Arunachal Pradesh
    13: "NL", // Nagaland
    14: "MN", // Manipur
    15: "MZ", // Mizoram
    16: "TR", // Tripura
    17: "ML", // Meghalaya
    18: "AS", // Assam
    19: "WB", // West Bengal
    20: "JH", // Jharkhand
    21: "OD", // Odisha
    22: "CG", // Chhattisgarh
    23: "MP", // Madhya Pradesh
    24: "GJ", // Gujarat
    25: "DD", // Daman & Diu
    26: "DN", // Dadra & Nagar Haveli
    27: "MH", // Maharashtra
    28: "AP", // Andhra Pradesh
    29: "KA", // Karnataka
    30: "GA", // Goa
    31: "LD", // Lakshadweep
    32: "KL", // Kerala
    33: "TN", // Tamil Nadu
    34: "PY", // Puducherry
    35: "AN", // Andaman & Nicobar Islands
    36: "TG", // Telangana
    37: "AP", // Andhra Pradesh (New)
  };
  const mapInvoiceStatus = (status) => {
    return status === "Invoice Received" ? "Received" : "Not Received";
  };

  const mapInvoiceType = (type) => {
    switch (type) {
      case "INV":
        return "E-tax invoice";
      case "CRN":
        return "Credit note";
      case "DBN":
        return "Debit note";
      default:
        return type; // Return the original type if no mapping is found
    }
  };
  // const handleValidate = async () => {
  //   if (
  //     (activeTab === "invoice" &&
  //       (!formData.vendorGSTIN ||
  //         !formData.financialYear ||
  //         !formData.invoiceType ||
  //         !formData.invoiceNumber)) ||
  //     (activeTab === "irn" && !formData.irnNumber)
  //   ) {
  //     alert("Please fill all the required fields.");
  //     return;
  //   }

  //   if (
  //     activeTab === "irn" &&
  //     (!formData.irnNumber || formData.irnNumber.length !== 64)
  //   ) {
  //     alert("IRN Number must be exactly 64 characters.");
  //     return;
  //   }

  //   setIrnLoading(true);
  //   try {
  //     const currentWorkspace = localStorage.getItem("currentWorkspace");
  //     // const payload = activeTab === "irn" ? { irnNumber: formData.irnNumber } : formData;
  //     const collection = 'vendor_tally'
  //     const payload =
  //       activeTab === "irn"
  //         ? {
  //           irnNumber: formData.irnNumber,
  //           collection: collection,
  //           db: "gstservice",
  //           currentWorkspace,
  //         }
  //         : {
  //           ...formData,
  //           collection: collection,
  //           db: "gstservice",
  //           currentWorkspace,
  //         };
  //     console.log('Testing IRN TAB with process env: ', process.env.REACT_APP_API_URL)
  //     const response = await fetch(
  //       API_ENDPOINT_VENDOR_VALIDATE,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(payload),
  //       }
  //     );
  //     console.log("API Endpoint:", API_ENDPOINT_VENDOR_VALIDATE);
  //     console.log(response, "response");

  //     const data = await response.json();
  //     // const data=response.data
  //     // setIsModalVisible(true)
  //     // setShowVendorDetails(true);
  //     // setInvoiceStatus(data.web_data?.irn ? "✔ Received" : "Not Received");
  //     // setInvoiceData({
  //     //   regtime: data.web_data?.regtime || "Not Available",
  //     //   irn: data.web_data?.irn || "Not Available",
  //     //   regirp: data.web_data?.regirp || "Not Available",
  //     //   status: data.web_data?.status || "Not Available",
  //     // });
  //     console.log(data.data, "data");
  //     setInvoiceData({
  //       inum: data.data?.Doc_data?.inum,
  //       date: data.data?.Doc_data?.dt,
  //       eligibleStatus: data.data?.vendor_data?.einvoiceEligiblity,
  //       invoiceStatus: mapInvoiceStatus(data.data?.invoiceStatus),
  //       vendorName: data.data?.vendor_data?.enablement_gov?.tradeName,
  //       // pan: data.data?.vendor_data?.pan,
  //       vendorGSTIN: data.data?.vendor_data?.vendor_gstin,
  //       totalGSTAmount: data.data?.Amount_data?.totalGstAmount,
  //       // invoiceType:data.data.invType
  //       invoiceType: mapInvoiceType(data.data?.Doc_data?.invType),
  //       assetLink: data.data?.Doc_data?.assetlink,
  //     });
  //     console.log(setInvoiceData.vendorData, "gstin value check");
  //     const gstinPrefix = data.data.ctin?.substring(0, 2);
  //     console.log(gstinPrefix, "gstinPrefix");
  //     const stateShortForm = stateShortFormMapping[gstinPrefix] || "Unknown";
  //     console.log(stateShortForm, "state form");
  //     setStateShortForm(stateShortForm);

  //     if (data) {
  //       setIsModalVisible(true);
  //       setShowVendorDetails(true);
  //     }
  //   } catch (error) {
  //     console.error("Error validating data:", error);
  //     alert("Validation failed. Please try again.");
  //   } finally {
  //     setIrnLoading(false);
  //   }
  // };



  const handleValidate = async () => {
    console.log(formData, "formData")
    if (
      (activeTab === "invoice" &&
        (!formData.vendorGSTIN ||
          !formData.financialYear ||
          !formData.invoiceType ||
          !formData.invoiceNumber)) ||
      (activeTab === "irn" && !formData.irnNumber)
    ) {
      alert("Please fill all the required fields.");
      return;
    }

    if (
      activeTab === "irn" &&
      (!formData.irnNumber || formData.irnNumber.length !== 64)
    ) {
      alert("IRN Number must be exactly 64 characters.");
      return;
    }

    setIrnLoading(true);
    try {
      const currentWorkspace = localStorage.getItem("currentWorkspace");
      // const payload = activeTab === "irn" ? { irnNumber: formData.irnNumber } : formData;
      const collection = 'vendor_tally'
      const payload =
        activeTab === "irn"
          ? {
            irnNumber: formData.irnNumber,
            collection: collection,
            db: "gstservice",
            currentWorkspace,
          }
          : {
            ...formData,
            collection: collection,
            db: "gstservice",
            currentWorkspace,
          };
      console.log('Testing IRN TAB with process env: ', process.env.REACT_APP_API_URL)
      const response = await fetch(
        API_ENDPOINT_VENDOR_VALIDATE,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      console.log(response, "response");

      const data = await response.json();

      console.log(data.data, "data");
      setInvoiceData({
        inum: data.data?.Doc_data?.inum,
        date: data.data?.Doc_data?.dt,
        eligibleStatus: data.data?.vendor_data?.einvoiceEligibility,
        invoiceStatus: mapInvoiceStatus(data.data?.invoiceStatus),
        vendorName: data.data?.vendor_data?.enablement_gov?.tradeName,
        // pan: data.data?.vendor_data?.pan,
        pan: data.data?.vendor_data?.vendor_gstin
          ? data.data.vendor_data.vendor_gstin.substring(2, 12)
          : "",
        vendorGSTIN: data.data?.vendor_data?.vendor_gstin,
        totalGSTAmount: data.data?.Amount_data?.totalGstAmount,
        // invoiceType:data.data.invType
        invoiceType: mapInvoiceType(data.data?.Doc_data?.invType),
        assetLink: data.data?.Doc_data?.assetlink,
      });
      console.log(setInvoiceData.vendorData, "gstin value check");
      const gstinPrefix = data.data.vendor_data.vendor_gstin?.substring(0, 2);
      console.log(gstinPrefix, "gstinPrefix");
      const stateShortForm = stateShortFormMapping[gstinPrefix] || "Unknown";
      console.log(stateShortForm, "state form");
      setStateShortForm(stateShortForm);

      if (data) {
        setIsModalVisible(true);
        setShowVendorDetails(true);
      }
    } catch (error) {
      console.error("Error validating data:", error);
      alert("Validation failed. Please try again.");
    } finally {
      setIrnLoading(false);
    }
  };























  return (
    <div>
      {/* <div className="invoiceHeading">
        <Button className="btn1">Invoice #</Button>
        <Button className="btn2">IRN #</Button>
       </div>
       <div className="headerFirst">E-invoice validate</div> */}
      <div className="invoiceContainer">
        <div className="einvoice-toggle-switch">
          <div
            className={`einvoice-toggle-option ${activeTab === "invoice" ? "active" : ""
              }`}
            onClick={() => setActiveTab("invoice")}
          >
            Invoice #
          </div>
          <div
            className={`einvoice-toggle-option ${activeTab === "irn" ? "active" : ""
              }`}
            onClick={() => setActiveTab("irn")}
          >
            IRN #
          </div>
          <div
            className={`einvoice-toggle-indicator ${activeTab === "irn" ? "right" : ""
              }`}
          />
        </div>

        {!showVendorDetails && (
          <>
            <h3 className="einvoicecard-title">
              {activeTab === "invoice" ? "E-invoice Validate" : "IRN Validate"}
            </h3>

            {activeTab === "invoice" ? (
              <>
                <div style={{ display: "flex", gap: "16px" }}>
                  <div className="einvoicecard-form-group">
                    {irnloading && (
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          backgroundColor: "transparent",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          zIndex: 10,
                        }}
                      >
                        <BeatLoader color="#36D7B7" size={20} />
                      </div>
                    )}
                    <div style={{ display: "flex", gap: "16px", width: "100%" }}>
                      <div className="einvoicecard-input-container"> {/* Vendor Gstin DIV */}
                        <label >
                          Vendor GSTIN<span>*</span>
                        </label>
                        <input
                          type="text"
                          name="vendorGSTIN"
                          value={formData.vendorGSTIN}
                          onChange={handleChange}
                          placeholder="Enter Vendor GSTIN "
                        />
                      </div>

                      <div className="einvoicecard-input-container">
                        <label>
                          Financial Year<span>*</span>
                        </label>
                        <Select
                          name="financialYear"
                          value={formData.financialYear || undefined}
                          onChange={(value) => handleSelectChange("financialYear", value)}
                          showArrow={true}
                          placeholder="Choose financial year"
                          style={{ width: "100%", color: "black" }}
                          suffixIcon={<DownOutlined style={{ color: "#0A8394" }} />}
                          dropdownRender={(menu) => <div style={{ color: "black" }}>{menu}</div>}
                          className="custom-select"
                        >
                          <Option value="2024-25">2024-25</Option>
                        </Select>
                      </div>






                    </div>
                  </div>

                  {/* Invoice Type Card */}
                  <div
                    className="einvoicecard-form-group"
                    style={{ display: "flex" }}
                  >
                    <div
                      style={{ display: "flex", gap: "16px", width: "100%" }}
                    >
                      <div className="einvoicecard-input-container">
                        <label>
                          Invoice Type<span>*</span>
                        </label>
                        <Select
                          name="invoiceType"
                          value={formData.invoiceType || undefined} // Controlled component
                          // onChange={handleChange}
                          onChange={(value) => handleSelectChange("invoiceType", value)}
                          showArrow={true}
                          placeholder="Choose invoice type" // Placeholder when no value is selected
                          style={{ width: "100%", color: 'black' }} // Adjust width as needed
                          suffixIcon={<DownOutlined style={{ color: "#0A8394" }} />}
                          dropdownRender={(menu) => <div style={{ color: "black" }}>{menu}</div>}
                          className="custom-select"
                        >
                          <Option value="tax-invoice" style={{ color: "black" }}>Tax Invoice</Option>
                          <Option value="debit-note" style={{ color: "black" }}>Debit Note</Option>
                          <Option value="credit-note" style={{ color: "black" }}>Credit Note</Option>
                        </Select>
                      </div>
                      <div className="einvoicecard-input-container">
                        <label>
                          Invoice Number<span>*</span>
                        </label>
                        <input
                          type="text"
                          name="invoiceNumber"
                          value={formData.invoiceNumber}
                          onChange={handleChange}
                          placeholder="Enter Invoice Number"
                        />
                      </div>
                    </div>
                  </div>


                </div>

                {/* <div className="einvoicecard-button-group">
                                    <button className="einvoicecard-reset" onClick={() => setFormData({ vendorGSTIN: "", financialYear: "", invoiceType: "", invoiceNumber: "" })}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M16.6673 9.16658C16.4635 7.7001 15.7832 6.3413 14.7312 5.2995C13.6792 4.25769 12.3138 3.59068 10.8454 3.40121C9.37697 3.21174 7.887 3.51032 6.60499 4.25096C5.32297 4.99159 4.32003 6.1332 3.75065 7.49992M3.33398 4.16658V7.49992H6.66732M3.33398 10.8333C3.53778 12.2998 4.21809 13.6586 5.27012 14.7004C6.32214 15.7422 7.68752 16.4092 9.15593 16.5987C10.6243 16.7881 12.1143 16.4896 13.3963 15.7489C14.6783 15.0083 15.6813 13.8667 16.2507 12.5M16.6673 15.8333V12.5H13.334" stroke="#0A8394" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></button>
                                    <button className="einvoicecard-validate" onClick={handleValidate} >Validate Now</button>
                                </div> */}
                <div
                  style={{ display: "flex", marginTop: "15px", height: "30px" }}
                >
                  <Button
                    className="restBtn"
                    onClick={() =>
                      setFormData({
                        vendorGSTIN: "",
                        financialYear: "",
                        invoiceType: "",
                        invoiceNumber: "",
                      })
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M16.6673 9.16658C16.4635 7.7001 15.7832 6.3413 14.7312 5.2995C13.6792 4.25769 12.3138 3.59068 10.8454 3.40121C9.37697 3.21174 7.887 3.51032 6.60499 4.25096C5.32297 4.99159 4.32003 6.1332 3.75065 7.49992M3.33398 4.16658V7.49992H6.66732M3.33398 10.8333C3.53778 12.2998 4.21809 13.6586 5.27012 14.7004C6.32214 15.7422 7.68752 16.4092 9.15593 16.5987C10.6243 16.7881 12.1143 16.4896 13.3963 15.7489C14.6783 15.0083 15.6813 13.8667 16.2507 12.5M16.6673 15.8333V12.5H13.334"
                        stroke="#0A8394"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Button>
                  <Button
                    className="validateBtn"
                    onClick={handleValidate}
                    style={{ width: "25%" }}
                  >
                    Validate Now
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className="einvoicecard-form-group">
                  <div className="einvoicecard-input-container">
                    <label>
                      IRN Number<span>*</span>
                    </label>
                    {/* <Input
                      type="text"
                      placeholder="Enter IRN here"
                      defaultValue={formData.irnNumber}
                      onChange={handleChange}
                    /> */}
                    <input
                      type="text"
                      name="irnNumber"
                      value={formData.irnNumber}
                      onChange={handleChange}
                      style={{ width: "190%" }}
                      placeholder="Enter IRN here"
                    />
                  </div>
                </div>
                <div className="einvoicecard-button-group" style={{display:'flex'}}>
                  <Button
                    className="einvoicecard-cancel"
                    style={{ color: "#0A8394", borderColor: "#0A8394", height: '40px' }}
                    onClick={() =>
                      setFormData({
                        irnNumber: ""
                      })
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M16.6673 9.16658C16.4635 7.7001 15.7832 6.3413 14.7312 5.2995C13.6792 4.25769 12.3138 3.59068 10.8454 3.40121C9.37697 3.21174 7.887 3.51032 6.60499 4.25096C5.32297 4.99159 4.32003 6.1332 3.75065 7.49992M3.33398 4.16658V7.49992H6.66732M3.33398 10.8333C3.53778 12.2998 4.21809 13.6586 5.27012 14.7004C6.32214 15.7422 7.68752 16.4092 9.15593 16.5987C10.6243 16.7881 12.1143 16.4896 13.3963 15.7489C14.6783 15.0083 15.6813 13.8667 16.2507 12.5M16.6673 15.8333V12.5H13.334"
                        stroke="#0A8394"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Button>
                  <Button className="validateBtn" onClick={handleValidate}>
                    Validate Now
                  </Button>
                </div>
              </>
            )}
          </>
        )}

        {showVendorDetails && (
          <div className="vendor-details">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                paddingTop: 10,
              }}
            >
              <div
                className="vendorName"
                // style={{ display: "flex", alignItems: "center" }}
                style={{
                  display: "flex", alignItems: "center", maxWidth: "385px", // adjust this value as needed
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis"
                }}              >
                <Typography variant="caption" wrapped>
                  {invoiceData.vendorName}{" "}
                </Typography>
                {/* <Tag
                  style={{ marginLeft: 6 }}
                  icon={<CheckCircleFilled />}
                  color="green"
                >
                  {invoiceData.eligibleStatus}
                </Tag> */}
                <Tag
                  style={{ marginLeft: 6 }}
                  icon={
                    invoiceData.eligibleStatus === "Eligible" ? (
                      <CheckCircleFilled />
                    ) : (
                      <CloseCircleFilled />
                    )
                  }
                  color={invoiceData.eligibleStatus === "Eligible" ? "green" : "red"}
                >
                  {invoiceData.eligibleStatus}
                </Tag>
              </div>
              {/* <p className="vendorName">Vendor_name</p> */}
              <div className="detailsWrapper" style={{ display: "flex", gap: "15px" }}>
                <div
                  className="panDetails"
                  style={{ display: "flex", textAlign: "center" }}
                >
                  <Typography variant="xs" color="#3F4047" style={{ marginTop: '1px' }}>
                    PAN :
                  </Typography>
                  <div
                    className="panValue"
                    style={{ color: "#0A8394", marginLeft: 4 }}
                  >
                    {" "}
                    {invoiceData.pan}
                  </div>
                </div>
                <div className="gstinDetails" style={{ display: "flex", textAlign: "center" }}>
                  <Typography variant="xs" color="#3F4047" style={{ marginTop: '1px' }}>
                    GSTIN :
                  </Typography>
                  <div className="gstinValue" style={{ display: "flex" }}>
                    <div
                      className="stateForm"
                      style={{
                        background: "#4169E1",
                        borderRadius: 3,
                        marginLeft: 4,
                        fontSize: 12,
                        width: 24,
                        height: 22,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                      }}
                    >
                      {stateShortForm}
                    </div>
                    <div
                      className="gstin"
                      style={{ color: "#0A8394", marginLeft: 4 }}
                    >
                      {" "}
                      {invoiceData.vendorGSTIN}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="vendor-table" style={{ display: "flex", gap: 10 }}>
              {/* <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
<span>Irn</span>
<span style={{
paddingLeft: '10px',
wordBreak: 'break-all',
maxWidth: '50%',
}}>
{invoiceData.irn}
</span>
</div> */}
              <div
                className="TableView"
                style={{
                  border: "1px solid #E8EAF1",
                  width: "50%",
                  gap: 10,
                  marginTop: 12,
                  borderRadius: 12,

                  marginBottom: 16,
                }}
              >
                <div
                  className="row"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 8,
                    boxSizing: "border-box",
                    borderBottom: "1px solid #E8EAF1",
                  }}
                >
                  <Typography variant="caption" color="#72747D" style={{ fontSize: '12px', marginTop: '2px' }}>
                    E-invoice number
                  </Typography>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <Typography color="#72747D" variant="caption" style={{ fontSize: '12px' }}>
                      {invoiceData.inum}
                    </Typography>
                    <Tag
                      className="invoiceTypeRow"
                      style={{
                        color: "#0A8394",
                        borderColor: "#03A5B733",
                        textAlign: "center",
                        marginLeft: 10,
                      }}
                    >
                      {invoiceData.invoiceType}
                    </Tag>
                  </span>
                </div>
                <div
                  className="row"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 8,
                    boxSizing: "border-box",
                    borderBottom: "1px solid #E8EAF1",
                  }}
                >
                  <Typography variant="caption" color="#72747D" style={{ fontSize: '12px' }}>
                    Date
                  </Typography>
                  <Typography variant="caption" color="#72747D" style={{ fontSize: '12px' }}>
                    {invoiceData.date}
                  </Typography>
                </div>
              </div>
              <div
                className="TableView"
                style={{
                  border: "1px solid #E8EAF1",
                  width: "50%",
                  gap: 10,
                  marginTop: 12,
                  marginBottom: 16,
                  borderRadius: 12,
                }}
              >
                <div className="TableRow">
                  <div
                    className="row"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: 8,
                      boxSizing: "border-box",
                      borderBottom: "1px solid #E8EAF1",
                    }}
                  >
                    <Typography variant="caption" color="#72747D" style={{ fontSize: '12px', marginTop: '2px' }}>
                      E-invoice status
                    </Typography>
                    {/* <span ><Tag className="eligibleStatusRow">{invoiceData.eligibleStatus}</Tag></span> */}
                    <Tag
                      color={invoiceData.invoiceStatus === "Received" ? "green" : "red"}
                      className={`invoiceStatus ${invoiceData.invoiceStatus === "Received"
                        ? "received"
                        : "notReceived"
                        }`} style={{ display: "flex" }}
                    >
                      {/* Conditional SVG Icon */}
                      {invoiceData.invoiceStatus === "Received" ? (
                        <svg
                          width="15"
                          height="14"
                          viewBox="0 0 15 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.75 7L6.91667 8.16667L9.25 5.83333M2.25 7C2.25 7.68944 2.3858 8.37213 2.64963 9.00909C2.91347 9.64605 3.30018 10.2248 3.78769 10.7123C4.2752 11.1998 4.85395 11.5865 5.49091 11.8504C6.12787 12.1142 6.81056 12.25 7.5 12.25C8.18944 12.25 8.87213 12.1142 9.50909 11.8504C10.146 11.5865 10.7248 11.1998 11.2123 10.7123C11.6998 10.2248 12.0865 9.64605 12.3504 9.00909C12.6142 8.37213 12.75 7.68944 12.75 7C12.75 6.31056 12.6142 5.62787 12.3504 4.99091C12.0865 4.35395 11.6998 3.7752 11.2123 3.28769C10.7248 2.80018 10.146 2.41347 9.50909 2.14963C8.87213 1.8858 8.18944 1.75 7.5 1.75C6.81056 1.75 6.12787 1.8858 5.49091 2.14963C4.85395 2.41347 4.2752 2.80018 3.78769 3.28769C3.30018 3.7752 2.91347 4.35395 2.64963 4.99091C2.3858 5.62787 2.25 6.31056 2.25 7Z"
                            stroke="#1E7734"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="15"
                          height="14"
                          viewBox="0 0 15 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11 3.5L4 10.5M4 3.5L11 10.5"
                            stroke="#C8170D"
                            stroke-width="1.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      )}
                      {invoiceData.invoiceStatus}
                    </Tag>
                  </div>
                  <div
                    className="row"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: 8,
                      boxSizing: "border-box",
                      borderBottom: "1px solid #E8EAF1",
                    }}
                  >
                    <Typography variant="caption" color="#72747D" style={{ fontSize: '12px' }}>
                      Amount
                    </Typography>

                    <Typography variant="caption" color="#72747D" style={{ fontSize: '12px' }}>
                      INR {invoiceData.totalGSTAmount}
                    </Typography>
                  </div>
                  {/* <div className="row">
                    <span>E-invoice status</span>
                    <span>
                      <Tag
                        className={`invoiceStatus ${
                          invoiceData.invoiceStatus === "Received"
                            ? "received"
                            : "notReceived"
                        }`}
                      >
                        {invoiceData.invoiceStatus === "Received" ? (
                          <svg
                            width="15"
                            height="14"
                            viewBox="0 0 15 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.75 7L6.91667 8.16667L9.25 5.83333M2.25 7C2.25 7.68944 2.3858 8.37213 2.64963 9.00909C2.91347 9.64605 3.30018 10.2248 3.78769 10.7123C4.2752 11.1998 4.85395 11.5865 5.49091 11.8504C6.12787 12.1142 6.81056 12.25 7.5 12.25C8.18944 12.25 8.87213 12.1142 9.50909 11.8504C10.146 11.5865 10.7248 11.1998 11.2123 10.7123C11.6998 10.2248 12.0865 9.64605 12.3504 9.00909C12.6142 8.37213 12.75 7.68944 12.75 7C12.75 6.31056 12.6142 5.62787 12.3504 4.99091C12.0865 4.35395 11.6998 3.7752 11.2123 3.28769C10.7248 2.80018 10.146 2.41347 9.50909 2.14963C8.87213 1.8858 8.18944 1.75 7.5 1.75C6.81056 1.75 6.12787 1.8858 5.49091 2.14963C4.85395 2.41347 4.2752 2.80018 3.78769 3.28769C3.30018 3.7752 2.91347 4.35395 2.64963 4.99091C2.3858 5.62787 2.25 6.31056 2.25 7Z"
                              stroke="#1E7734"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="15"
                            height="14"
                            viewBox="0 0 15 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11 3.5L4 10.5M4 3.5L11 10.5"
                              stroke="#C8170D"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        )}
                        {invoiceData.invoiceStatus}
                      </Tag>
                    </span>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="vendor-actions" style={{ display: "flex" }}>
              <Button
                className="einvoicecard-reset"
                onClick={() => {
                  setShowVendorDetails(false);
                  setFormData({
                    vendorGSTIN: "",
                    financialYear: "",
                    invoiceType: "",
                    invoiceNumber: "",
                  });
                }}
                icon={<ArrowIcon style={{ width: "24px", height: "24px" }} />}
              ></Button>

              {/* {invoiceData.invoiceStatus === "Received" ? (
        <button style={{ backgroundColor: "#0A8394", color: 'white', width: '180px' }} className="einvoicecard-view" onClick={() => window.open(invoiceData.assetLink, "_blank")}><EyeOutlined style={{ marginRight: '5px' }} />View</button>
      ) : invoiceData.invoiceStatus === "Not Received" ? (
        <Button className="einvoicecard-request" onClick={handleRequestClick} style={{ backgroundColor: "#0A8394", color: 'white', width: '180px' }}>Request</Button>
      ) : null} */}
              {(invoiceData.invoiceStatus === "Received" &&
                invoiceData.eligibleStatus === "Eligible" &&
                invoiceData.assetLink) ||
                invoiceData.assetLink ? (
                <Button
                  style={{
                    backgroundColor: "#0A8394",
                    color: "white",
                    width: "180px",
                    height: '33px',
                    marginLeft: 8,
                  }}
                  className="einvoicecard-view"
                  onClick={() => window.open(invoiceData.assetLink, "_blank")}
                >
                  <EyeOutlined />
                  View
                </Button>
              ) : invoiceData.invoiceStatus === "Not Received" &&
                invoiceData.eligibleStatus === "Eligible" ? (
                <Button
                  className="einvoicecard-request"
                  onClick={handleRequestClick}
                  style={{
                    backgroundColor: "#0A8394",
                    color: "white",
                    width: "180px",
                  }}
                >
                  Request
                </Button>
              ) : null}

              {isEmailModalOpen && (
                <EmailModal
                  vendorData={[
                    {
                      gstin: invoiceData.vendorGSTIN,
                      vendorName: invoiceData.vendorName,
                    },
                  ]}
                  isOpen={isEmailModalOpen}
                  onClose={closeEmailModal}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InvoiceIRN;
