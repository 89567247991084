import Credentials from "./Credentials";
import AirlineCredentials from "./components/AirlineCredentials";
import EmailCredentials from "./components/EmailCredentials";
import GSTCredentials from "./components/GSTCredentials";
import AgTrial from "./components/TrialAG";

//eslint-disable-next-line
export default {
  path: "/credentials/",
  element: <Credentials />,
  strict: true,
  children: [
    {
      path: "/credentials/gst",
      element: <GSTCredentials />,
      exact: true,
    },
    {
      path: "/credentials/airline",
      element: <AirlineCredentials />,
      exact: true,
    },
    {
      path: "/credentials/mail",
      element: <EmailCredentials />,
      exact: true,
    },
  ],
};
