import PageHeader from "app/shared/PageHeader";
import "./Dashboard.scss";
import CustomReconTabs from "../Reconcilation/components/CustomReconTabs";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { tabViewStates, userInfo } from "app/config/States/users";
import Loader from "app/shared/Loader";
import { Empty, Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import { getSubdomain } from "app/utils/generic.helper";
import {
  DASHBOARD_LINK_ZOHO_CRITERIA_FILTER,
  dashboardTabConfig,
  dashboardURLConfig,
} from "global.constants";
import { Mixpanel } from "app/config/Mixpanel";
export default function Dashboard(props: any) {
  const [activeKey, setActiveKey] = useState("1");
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const [tabViews, setTabView] = useRecoilState<any>(tabViewStates);
  const [isLoading, setLoading] = useState(false);
  const [dashboardURL, setDashboardURL] = useState("");
  const navigate = useNavigate();
  Mixpanel.track("User Landed On Dashboard", { Screen: "Dashboard" });
  const domain: any = getSubdomain();

  useEffect(() => {
    setLoading(true);

    // if (userDetails.account_type === "CLIENT") navigate("/flights");
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [userDetails]);

  const getFormattedURL = (url: any) => {
    let link = url;
    console.log("coming here:", userDetails.currentWorkspace, link);

    if (userDetails.currentWorkspace?.meta?.full_access) {
      return link;
    } else {
      // Encode the workspace name to make it URL-safe
      const encodedWorkspaceName = encodeURIComponent(
        userDetails.currentWorkspace?.name
      );

      // Construct the new URL by adding the `ZOHO_CRITERIA` parameter
      const actualUrl = `${link}?ZOHO_CRITERIA=%22${DASHBOARD_LINK_ZOHO_CRITERIA_FILTER[domain]?.[activeKey]}%22%3D%27${encodedWorkspaceName}%27`;
      console.log("actual url :", actualUrl);
      return actualUrl;
    }
  };

  const getDashboardOptionVisible = (options: any) => {
    const menus = Array.isArray(options)
      ? options.filter(
          (menu: any) =>
            !menu.adminOnly || userDetails.currentWorkspace?.meta?.full_access
        )
      : [];
    return menus;
  };
  return isLoading ? (
    <Loader />
  ) : (
    <div className="Dashboard">
      <div className="ScreenContainer">
        <PageHeader
          leftActions={
            <Tabs
              items={getDashboardOptionVisible(dashboardTabConfig[domain])}
              tabBarStyle={{
                borderBottom: "none", // Remove the underline border
                margin: 0,
              }}
              activeKey={activeKey}
              onChange={(tabItem: string) => setActiveKey(tabItem)}
            />
          }
          // leftActions={
          //   <CustomReconTabs
          //     activeKey={activeKey}
          //     title="Report"
          //     onTabChange={(tabKey: any) => setActiveKey(tabKey)}
          //     onTabModify={(tabs: any) =>
          //       setTabView({ ...tabViews, dashboard: tabs })
          //     }
          //     hideAddTab
          //     hideRemove
          //     defaultTabs={getTabView()}
          //     width={200}
          //   />
          // }
        />

        <div className="TableContainer">
          <iframe
            height="100%"
            style={{ width: "100%" }}
            scrolling="no"
            title="Iframe"
            id="dashboardFrame"
            src={getFormattedURL(dashboardURLConfig[domain]?.[activeKey]) || ""}
          ></iframe>
        </div>
      </div>
    </div>
  );
}
