import airlinecredentialflowRoutes from "app/scenes/AirlineCredentialFlow/airlinecredentialflow.routes";
import AirlineConfirm from "app/scenes/AirlineCredentialFlow/components/AirlineConfirmPage";
import apptourRoutes from "app/scenes/AppTour/apptour.routes";
import OnboardAirlineReconView from "app/scenes/AppTour/components/OnboardAirlineReconView";
import MultiPart from "app/scenes/AppTour/components/OnboardAirlineReconView/MultiPart";
import SampleReconViewAGTable from "app/scenes/AppTour/components/SampleReconView/sampleGSTTableView";
import authRoute from "app/scenes/Auth/auth.routes";
import AcknowledgeApproval from "app/scenes/Auth/components/ApprovalAcknowledge";
import BhilosaDashboard from "app/scenes/BhilosaDashboard";
import PageNotFound from "app/scenes/Common/PageNotFound/PageNotFound";
import AgTrial from "app/scenes/Credentials/components/TrialAG";
import FinkTest from "app/scenes/FinkTest/FinkTest";
import FintTest from "app/scenes/FinkTest/FinkTest3";
import gstcredflowRoutes from "app/scenes/GSTCredentialFlow/gstcredflow.routes";
import HotelBookingView from "app/scenes/HotelBookingView";
import SingleInvoiceUpload from "app/scenes/HotelBookingView/components/SingleInvoiceUpload";
import HotelBookingViewClient from "app/scenes/HotelBookingViewClient/HotelBookingViewClient";
import HotelEmail from "app/scenes/HotelEmailFollowUp/HotelEmail";
import Onboarding from "app/scenes/Onboarding";
import onboadingRoutes from "app/scenes/Onboarding/onboading.routes";
import onboardingRoutes from "app/scenes/OnboardingV2/onboarding.routes";
import OpenReportList from "app/scenes/ReportManager/components/OpenReportList";
import OpenReportView from "app/scenes/ReportManager/components/OpenReportView";
import vendorfollowupRoutes from "app/scenes/VendorFollowUp/vendorfollowup.routes";
import masterRoutes from "app/shared/MasterLayout/master.routes";

const childRoutes = [
  authRoute,
  masterRoutes,
  onboadingRoutes,
  apptourRoutes,
  vendorfollowupRoutes,
];
// const childRoutes = [authRoute];

const routes = [
  ...childRoutes,
  {
    path: "*",
    element: <PageNotFound />,
    strict: true,
  },
  {
    path: "/user/acknowledgement/:token",
    element: <AcknowledgeApproval />,
    exact: true,
  },
  {
    path: "/hotel/email-followup",
    element:<HotelEmail/>,
    exact: true,
  },
  {
    path: "/hotel_booking2",
    element: <HotelBookingView />,
    exact: true,
  },
  {
    path: "/hotel_booking/upload",
    element: <SingleInvoiceUpload />,
    exact: true,
  },
  {
    path: "/test3",
    element: <OnboardAirlineReconView />,
    exact: true,
  },
  {
    path: "/test2",
    element: <MultiPart />,
    exact: true,
  },
  {
    path: "/hotel_booking",
    element: <HotelBookingViewClient />,
    exact: true,
  },

  {
    path: "/open_view/report/:reportId",
    element: <OpenReportView />,
    exact: true,
  },

  {
    path: "/open_view/report/list/:tokenId",
    element: <OpenReportList />,
    exact: true,
  },
  {
    path: "/credential/airline/question/finkraft/consent/Confirm",
    element: <AirlineConfirm />,
  },
  {
    path: "/auth/bhilosadashboard",
    element: <BhilosaDashboard />,
    exact: true,
  },
  onboardingRoutes,
  airlinecredentialflowRoutes,
  ...gstcredflowRoutes,
  {
    path: "/aggtrial",
    element: <AgTrial />,
    exact: true,
  },
];

export default routes;
