import {
  Avatar,
  Badge,
  Breadcrumb,
  Button,
  Dropdown,
  Layout,
  MenuProps,
  Select,
} from "antd";

import "./PageHeader.scss";
import SearchInput from "../SearchInput";

import MenuList from "../PrimaryNav/MenuItems.json";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Typography from "../Typography";
import { colorPicker } from "app/utils/color.helper";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { apiGet } from "app/services/apiServices";
import { API_ENDPOINT_GET_USER_INFO } from "app/scenes/Auth/auth.constants";
import SearchDropdown from "../WorkspaceSelector";
import { LeftOutlined } from "@ant-design/icons";
import { Mixpanel } from "app/config/Mixpanel";
import { getSubdomain } from "app/utils/generic.helper";
export default function PageHeader(props: any) {
  const [breadcrumItem, setBreadcrumbItem] = useState<any[]>([]);
  const location = useLocation();
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  useEffect(() => {
    let menuItem = MenuList.find(
      (item: any) => item.route === location.pathname
    );
    if (menuItem) {
      setBreadcrumbItem([menuItem]);
    }
  }, [location.pathname]);

  const handleSelectWorkspce = (workspaceInfo: any) => {
    let newWorksapceData = { ...userDetails, currentWorkspace: workspaceInfo };
    setUserDetails(JSON.parse(JSON.stringify(newWorksapceData)));
    localStorage.setItem("currentWorkspace", workspaceInfo.id);
    fetchUserInfo(workspaceInfo);
    Mixpanel.track("User Changed Workspace", {
      Workspace: workspaceInfo?.name,
    });
  };

  const fetchUserInfo = async (workspaceInfo: any) => {
    const response = await apiGet(API_ENDPOINT_GET_USER_INFO);
    if (response.status) {
      setUserDetails({
        ...response.data,
        currentWorkspace: workspaceInfo,
        // clusterId: response.data.cluster_id,
      });
      // if (!response.data.hasOnboared) {
      //   navigate("/onboarding/welcome");
      // }
    }
    // setTimeout(() => {
    //   setLoading(false);
    // }, 1000);
  };

  const workspaceColorMap = [
    "#4555eb",
    "#209f85",
    "#fa694a",
    "#2c3e50",
    "#e74c3c",
  ];

  const PickWorkspaceColor = (word: string) => {
    const firstChar = word.charAt(0).toLowerCase();
    const alphabetIndex = firstChar.charCodeAt(0) - "a".charCodeAt(0);
    const colorIndex = Math.floor(
      alphabetIndex / (26 / workspaceColorMap.length)
    );
    return workspaceColorMap[colorIndex];
  };

  const { Option } = Select;
  const navigation = useNavigate();
  const domain = getSubdomain();
  return (
    <div className="PageHeader">
      <div className="HeaderLeft">
        {props.goBack ? (
          <div
            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
            onClick={() => navigation(-1)}
          >
            <LeftOutlined />
            <Typography variant="caption" style={{ marginRight: 24 }}>
              Back
            </Typography>
          </div>
        ) : null}
        <Typography
          weight={500}
          style={{ color: "var(--text-color-black)", fontSize: 14 }}
        >
          {props.title}
        </Typography>

        <div className="ItemContainer " style={{ marginRight: 24 }}>
          {props.leftActions}
        </div>
      </div>
      <div className="HeaderRight">
        <div className="ItemContainer " style={{ marginRight: 24 }}>
          {props.rightActions}
        </div>
        {props.noAuth ? null : (
          // <div className="ItemContainer" style={{ width: "fit-content" }}>
          //   <SearchDropdown
          //     workspaces={userDetails?.workspaces}
          //     handleSelectWorkspce={handleSelectWorkspce}
          //   />
          // </div>
          <div className="ItemContainer" style={{ width: "fit-content" }}>
            {domain !== "newdemo" && ( 
              <SearchDropdown
                workspaces={userDetails?.workspaces}
                handleSelectWorkspce={handleSelectWorkspce}
              />
            )}
          </div>

        )}
      </div>
    </div>
  );
}
