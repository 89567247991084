import React from "react";
import { AppstoreAddOutlined } from "@ant-design/icons";
const handleIconClick = (e) => {
  alert("Alert is made");
};

function AgCustomHeader(props) {
  console.log("customjheader:", props);
  return (
    <div className="customInnerHeader">
      {props.icon && (
        <i
          className={`${props.icon}`}
          onClick={() => handleIconClick(props.column?.colDef?.field)}
        ></i>
      )}
      <AppstoreAddOutlined
        style={{ fontSize: 18, marginRight: 4, cursor: "pointer" }}
        onClick={() => props.onClick(props.column?.colDef?.field)}
      />
      <span>{props.displayName}</span>
    </div>
  );
}

export default AgCustomHeader;
