import PageHeader from "app/shared/PageHeader";
import "./ReportView.scss";
import { Button, Radio, Tag } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import TableViewManager from "app/shared/TableViewManager";
import { AgTableServer } from "app/shared/AgTable";
import { useLocation, useParams } from "react-router-dom";
import { apiGet, apiPost } from "app/services/apiServices";
import {
  API_ENDPOINT_FETCH_DB_TABLE_PREVIEW,
  API_ENDPOINT_FETCH_REPORT_TABLE_SCHEMA,
} from "app/scenes/FinkAnalytics/finkanalytics.constants";
import {
  getColumnDefinationFromTable,
  processZARowForColumnDefs,
} from "app/scenes/FinkAnalytics/fa.helper";
import Loader from "app/shared/Loader";
import TableViewManagerV2 from "app/shared/TableViewManagerV2";
import { useRecoilState } from "recoil";
import { currentPDFData, userInfo } from "app/config/States/users";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { indexOf } from "lodash";
import {
  API_ENDPOINT_AG_TABLE_LIST_ALL_VIEW,
  API_ENDPOINT_AG_TABLE_LIST_ALL_VIEW_OPEN,
} from "app/shared/AgTable/AgTable.constants";
import { getProcessedDefinition } from "app/utils/generic.helper";

export default function ReportView(props: any) {
  const [selectedSheet, setSelectedSheet] = useState("DATA");
  const [isLoading, setLoading] = useState(true);
  const [colDefs, setColDefs] = useState<any[]>([]);
  const [selectedView, setSelectedView] = useState<any[]>([]);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [currentSheetIndex, setCurrentSheetIndex] = useState();
  const location = useLocation();
  const gridRef: any = useRef();
  const gridRefArray: any = useRef([]);
  const [recoilPDFData, setRecoilPdfData] =
    useRecoilState<string>(currentPDFData);
  const { dbType, database, table, selectedWorkspaceId } = location.state;
  const { reportId } = useParams();

  useEffect(() => {
    fetchTotalReportViews();
  }, []);

  const fetchTotalReportViews = async () => {
    setLoading(true);

    const response = await apiPost(API_ENDPOINT_AG_TABLE_LIST_ALL_VIEW_OPEN, {
      moduleId: "REPORT_MANAGER",
      reportId: reportId,
    });
    if (response.data) {
      console.log("response data:", response.data);
      setSelectedView(response.data);
      fetchReportViewSchema();
    }
  };

  const fetchReportViewSchema = async () => {
    const response = await apiPost(
      API_ENDPOINT_FETCH_REPORT_TABLE_SCHEMA + reportId,
      {
        workspace_id: selectedWorkspaceId,
      }
    );

    setColDefs(response.data.state?.columnDefs);
    setLoading(false);
  };

  const handleExportAllToExcel = async () => {
    const workbook = new ExcelJS.Workbook();

    let spreadsheets = [];

    let mainDataSheet =
      gridRef.current && gridRef.current.exportExcelDataRaw("Main Data");
    spreadsheets.push(mainDataSheet);

    for (let index = 0; index < gridRefArray.current.length; index++) {
      const singleGridRef = gridRefArray.current[index];

      if (singleGridRef && singleGridRef.exportExcelDataRaw) {
        let rawSheetData = singleGridRef.exportExcelDataRaw(
          selectedView[index]?.name || "Sheet"
        );
        console.log(`Processing grid ${index + 1}`, rawSheetData);

        spreadsheets.push(rawSheetData);
      } else {
        console.warn(`Grid ref ${index + 1} not found or invalid.`);
      }
    }
    console.log("speardsheets", spreadsheets);
    gridRef.current &&
      gridRef.current.exportMultipleSheetAsNewExcel(
        spreadsheets,
        "Finkraft_Report"
      );
  };

  const handleSelectView = (viewId: any) => {
    setSelectedView(viewId);
    setSelectedSheet(viewId[viewId.length - 1]);
  };

  const handleSaveView = (
    viewId: string,
    moduleId: string,
    viewName: string
  ) => {
    gridRef.current && gridRef.current.updateGridView(viewId, "REPORT_MANAGER");
    setSelectedView([...selectedView, { name: viewName, _id: viewId }]);
  };

  const getProcessedDefinitions = (columnDefination: any) => {
    let finalDefs = getProcessedDefinition(
      columnDefination,
      "SERVER",
      setRecoilPdfData
    );
    console.log("column", finalDefs);
    return finalDefs;
  };

  return (
    <div className="ReportView">
      <div className="HeaderContainer">
        <PageHeader
          goBack
          title="Report Manager"
          rightActions={
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                size="small"
                type="primary"
                onClick={handleExportAllToExcel}
                style={{ marginRight: 24 }}
              >
                Download Report
              </Button>
            </div>
          }
        />
      </div>
      {isLoading ? (
        <div style={{ height: "100%" }}>
          <Loader />
        </div>
      ) : (
        <>
          <div className="MainContent">
            <div
              className={
                "ViewContainer " +
                (selectedSheet === "DATA" ? " " : " hiddenTable")
              }
            >
              <AgTableServer
                //@ts-ignore
                columnDefs={getProcessedDefinitions(colDefs)}
                endpoint={API_ENDPOINT_FETCH_DB_TABLE_PREVIEW}
                // endpoint={"http://192.168.1.59:7001/api/recon/hotel"}
                pdfDataKey="asset_link"
                ref={gridRef}
                serverPayload={{
                  database: database,
                  dbType: dbType,
                  table: table,
                }}
                enablePDFViewer
                pdfV2
                currentWorkspaceId={selectedWorkspaceId}
                TableViewPayload={{
                  workspace_id: selectedWorkspaceId,
                  workspace_name: userDetails?.currentWorkspace?.name,
                  viewType: "REPORT_MANAGER",
                  reportId: reportId,
                }}
              />
            </div>
            {(selectedView || []).map((item: any, index: any) => {
              return (
                <div
                  className={
                    "ViewContainer " +
                    (selectedSheet === item?._id ? " " : " hiddenTable")
                  }
                  key={index}
                >
                  <AgTableServer
                    key={index}
                    //@ts-ignore
                    columnDefs={colDefs}
                    endpoint={API_ENDPOINT_FETCH_DB_TABLE_PREVIEW}
                    // endpoint={"http://192.168.1.59:7001/api/recon/hotel"}
                    pdfDataKey="Invoice_link"
                    ref={(el) => (gridRefArray.current[index] = el)}
                    serverPayload={{
                      database: database,
                      dbType: dbType,
                      table: table,
                      defaultView: item?._id || "test",
                    }}
                    moduleId="REPORT_MANAGER"
                    defaultView={item?._id}
                    currentWorkspaceId={selectedWorkspaceId}
                    TableViewPayload={{
                      workspace_id: selectedWorkspaceId,
                      workspace_name: userDetails?.currentWorkspace?.name,
                      viewType: "REPORT_MANAGER",
                      reportId: reportId,
                    }}
                  />
                </div>
              );
            })}
          </div>

          <div className="SheetsContainer">
            <Button
              size="small"
              type={selectedSheet === "DATA" ? "primary" : "default"}
              icon={<MenuOutlined />}
              onClick={() => setSelectedSheet("DATA")}
            >
              Data Sheet
            </Button>

            {selectedView.map((item: any, index: any) => {
              return (
                <Button
                  size="small"
                  style={{ marginLeft: 12 }}
                  icon={<MenuOutlined />}
                  onClick={() => {
                    setSelectedSheet(item?._id);
                    setCurrentSheetIndex(index);
                  }}
                  type={selectedSheet === item._id ? "primary" : "default"}
                >
                  {item?.name}
                </Button>
              );
            })}
            <div style={{ marginLeft: 12 }}>
              <TableViewManagerV2
                selectedSheet={selectedSheet}
                moduleId={"REPORT_MANAGER"}
                reportId={reportId}
                selectView={(viewId: string) => handleSelectView(viewId)}
                updateView={handleSaveView}
                updateViewNew={(viewId: string) =>
                  gridRefArray.current &&
                  gridRefArray.current?.[currentSheetIndex || 0].updateGridView(
                    selectedSheet,
                    "REPORT_MANAGER"
                  )
                }
                viewMode={props.viewMode}
                workspace_id={selectedWorkspaceId}
                workspace_name={userDetails?.currentWorkspace?.name}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
