

// import React from "react";
// import { AgCharts } from "ag-charts-react";
// import "./Donut.scss"; // Import SCSS file
// import { useLocation, useNavigate } from "react-router-dom";
// import Loader from "app/shared/Loader";
// import { toast } from "react-toastify";

// const Donut = ({ vendorCount }) => {
//   const navigate = useNavigate();
//   console.log(vendorCount);

//   const totalVendors =
//     vendorCount?.counts.government_exempted +
//     vendorCount?.counts.manual_exempted +
//     vendorCount?.counts.eligible +
//     vendorCount?.counts.not_eligible;

// console.log(totalVendors,"total vendors check")
//   const chartData = [
//     { category: "Eligible", value: vendorCount?.counts.eligible },
//     { category: "Not Eligible", value: vendorCount?.counts.not_eligible },
//     { category: "Government-Exempted", value: vendorCount?.counts.government_exempted },
//     { category: "Manual-Exempted", value: vendorCount?.counts.manual_exempted },
//   ];

//   const vendorSummary = (category) => {
//     console.log("&&&&&&&&&&&&&&&&&&&");
//     navigate(
//       `/vendorfollowup/vendorSummary?category=${encodeURIComponent(category)}`
//     );
//   };

//   return !vendorCount ? (
//     <div
//       className="donutContainer"
//       style={{
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//       }}
//     >
//       <Loader />
//     </div>
//   ) : (
//     <div className="donutContainer">
//       <div className="heading">Split by Enablement</div>
//       <div className="donut">
//         <AgCharts
//           options={{
//             //  width: 80%,
//             series: [
//               {
//                 type: "donut",
//                 angleKey: "value",
//                 calloutLabelKey: "category",
//                 data: chartData,
//                 innerRadiusRatio: 0.7, // Adjust thickness of the donut
//                 innerLabels: [
//                   {
//                     text: "Total vendors",
//                     fontSize: 12,
//                     color: "#A3A6B7",
//                   },
//                   {
//                     text: totalVendors.toString(),
//                     fontWeight: "bold",
//                     fontSize: 32,
//                   },
//                 ],
//                 label: {
//                   formatter: (params) =>
//                     `${params.datum.category}: ${params.datum.value}`,
//                 },
//                 tooltip: {
//                   enabled: true,
//                   renderer: (params) => ({
//                     content: `${params.datum.category}: ${params.datum.value}`,
//                   }),
//                 },
//                 fills: ["#0A8394", "#03A5B7", "#FACC15"],
//                 // listeners: {
//                 //     seriesNodeClick: (event) => {
//                 //       console.log("Inside listeners");
//                 //       const clickedCategory = event.datum.category;
//                 //       vendorSummary(clickedCategory); // Use the existing function
//                 //     },
//                 //   },
//                 listeners: {
//                   nodeClick: (event) => {
//                     // Use `nodeClick` instead of `itemClick`
//                     console.log("Inside listeners");
//                     if (event.datum && event.datum.category) {
//                       const clickedCategory = event.datum.category;
//                       console.log("Clicked Category:", clickedCategory);
//                       vendorSummary(clickedCategory);
//                     }
//                   },
//                 },
//               },
//             ],
//             legend: {
//               position: "right",
//               item: {
//                 marker: {
//                   shape: "circle", // Make the legend marker circular
//                   size: 12, // Adjust the circle size
//                 },

//                 label: {
//                   color: "var(--Neutral-70, #404040)", // Set legend text color to #404040
//                   fontSize: 14, // Adjust font size for better readability
//                 },
//                 spacing: 50,
//               },
//               spacing: 20,
//             },
//           }}
//         />
//       </div>
//     </div>
//   );
// };

// export default Donut;




import React from "react";
import { AgCharts } from "ag-charts-react";
import "./Donut.scss"; // Import SCSS file
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "app/shared/Loader";
import { toast } from "react-toastify";

const Donut = ({ vendorCount }) => {
  const navigate = useNavigate();
  console.log(vendorCount);

  const totalVendors =
    vendorCount?.counts.government_exempted +
    vendorCount?.counts.manual_exempted +
    vendorCount?.counts.eligible +
    vendorCount?.counts.not_eligible;

console.log(totalVendors,"total vendors check")
  const chartData = [
    { category: "Eligible", value: vendorCount?.counts.eligible },
    { category: "Not Eligible", value: vendorCount?.counts.not_eligible },
    { category: "Government-Exempted", value: vendorCount?.counts.government_exempted },
    { category: "Manual-Exempted", value: vendorCount?.counts.manual_exempted },
  ];

  const vendorSummary = (category) => {
    console.log("&&&&&&&&&&&&&&&&&&&");
    navigate(
      `/vendorfollowup/vendorSummary?category=${encodeURIComponent(category)}`
    );
  };

  return !vendorCount ? (
    <div
      className="donutContainer"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loader />
    </div>
  ) : (
    <div className="donutContainer">
      <div className="heading">Split by Enablement</div>
      <div className="donut">
        <AgCharts
          options={{
            series: [
              {
                type: "donut",
                angleKey: "value",
                calloutLabelKey: "category",
                calloutLabel: { enabled: false }, 
                data: chartData,
                innerRadiusRatio: 0.7, // Adjust thickness of the donut
                innerLabels: [
                  {
                    text: "Total vendors",
                    fontSize: 12,
                    color: "#A3A6B7",
                    textAlign: "center",
                    //fontWeight: "bold",
                  },
                  {
                    text: totalVendors.toString(),
                    fontWeight: "bold",
                    fontSize: 32,
                  },
                ],
                //label: null,
                label: {
                  formatter: (params) =>
                    `${params.datum.category}: ${params.datum.value}`,
                },
                tooltip: {
                  enabled: true,
                  renderer: (params) => ({
                    content: `${params.datum.category}: ${params.datum.value}`,
                  }),
                },
                fills: ["#0A8394", "#03A5B7", "#FACC15","#35BFD0"],
                // listeners: {
                //     seriesNodeClick: (event) => {
                //       console.log("Inside listeners");
                //       const clickedCategory = event.datum.category;
                //       vendorSummary(clickedCategory); // Use the existing function
                //     },
                //   },
                listeners: {
                  nodeClick: (event) => {
                    // Use `nodeClick` instead of `itemClick`
                    console.log("Inside listeners");
                    if (event.datum && event.datum.category) {
                      const clickedCategory = event.datum.category;
                      console.log("Clicked Category:", clickedCategory);
                      vendorSummary(clickedCategory);
                    }
                  },
                },
              },
            ],
            legend: {
              enabled: true,
              position: "bottom",
              spacing: 50,
              item: {
                marker: {
                  shape: "circle", // Make the legend marker circular
                  size: 12, // Adjust the circle size
                },

                label: {
                  color: "var(--Neutral-70, #404040)", // Set legend text color to #404040
                  fontSize: 14, // Adjust font size for better readability
                },
                spacing: 50,
              },
              spacing: 20,
            },
          }}
        />
      </div>
    </div>
  );
};

export default Donut;
