import GSTCredentials from 'app/scenes/Credentials/components/GSTCredentials';
import PrimaryNavBhilosa from 'app/shared/PrimaryNavBhilosa';
import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import './index.css'
import { useNavigate } from 'react-router-dom';

const GSTCredsBhilosa = () => {
    const navigate = useNavigate();

    const handleAddCredential = () => {
        navigate('/vendorfollowup/gst/creds');
    };

    return (
        <div style={{ display: 'flex' }}>
            <div>
                <PrimaryNavBhilosa />
            </div>
            <div style={{ width: '100%' }}>
                {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginRight: '20px' }}>
                    <Button
                        className='addBtnCred'
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={handleAddCredential}
                    >
                        Add Credential
                    </Button>
                </div> */}
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <h2 style={{marginTop:'20px',marginLeft:'10px',fontWeight:500}}>GSTIN Credentials</h2>
                    <Button
                        className='addBtnCred'
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={handleAddCredential}
                    >
                        Add Credential
                    </Button>
                </div>

                <div className='tableAll'>
                    <GSTCredentials componentType />
                </div>
            </div>
        </div>
    )
};

export default GSTCredsBhilosa
