import AppIcon from "static/images/FinLogo.png";
import "./PrimaryNavBhilosa.scss";
import {
  SettingOutlined,
  ReconciliationOutlined,
  LockFilled,
  ScanOutlined,
  MailOutlined,
  PieChartOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import MMTLogo from "static/images/logos/mmt_logo.svg";
import ClientIcon from "static/images/mmtLogo.png";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import HotelRoundedIcon from "@mui/icons-material/HotelRounded";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import HotelIcon from "@mui/icons-material/Hotel";
import KeyIcon from '@mui/icons-material/Key';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRightFromBracket,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import phoneIcon from "../../assets/images/phone.png"
import customerIcon from "../../assets/images/UsersThree.png"
import { Modal, Button } from "antd";

import {
  gst2ADataState,
  gst2BDataState,
  irndata,
  roleInfo,

  vendorDetailsDisplay,
  workspaceInfo,
} from "app/config/States/users";
// {
//   "title": "Flight",
//   "icon": "FlightTakeoffRoundedIcon",
//   "subMenus": [
//     {
//       "title": "Booking vs Invoice",
//       "route": "/flights/bookingvsinvoice"
//     },
//     {
//       "title": "2B vs IRN",
//       "route": "/flights/invoicevs2b"
//     }
//   ],
//   "route": "/flights"
// },
// {
//   "title": "Credentials",
//   "icon": "KeyRoundedIcon",
//   "subMenus": [
//     {
//       "title": "GST Credentials",
//       "icon": "PercentRoundedIcon",
//       "subMenus": [],
//       "route": "/credentials/gst"
//     },
//     {
//       "title": "Airline Credentials",
//       "icon": "FlightTakeoffRoundedIcon",
//       "subMenus": [],
//       "route": "/credentials/airline"
//     },
//     {
//       "title": "Email Credentials",
//       "icon": "MailOutlined",
//       "subMenus": [],
//       "route": "/credentials/mail"
//     }
//   ],
//   "route": "/credentials"
// },

import { Avatar, Divider, Drawer, Dropdown, MenuProps } from "antd";
import Typography from "../Typography";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import SubMenu from "./SubMenu";
import MenuList from "./MenuItems.json";
import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
import KeyRoundedIcon from "@mui/icons-material/KeyRounded";
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import FlightTakeoffRoundedIcon from "@mui/icons-material/FlightTakeoffRounded";
import PercentRoundedIcon from "@mui/icons-material/PercentRounded";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import { useRecoilState } from "recoil";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { userInfo } from "app/config/States/users";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import RocketLaneIcon from "static/icons/rocketlane.png";
import Lottie from "react-lottie";
import { colorPicker } from "app/utils/color.helper";
import { apiGet } from "app/services/apiServices";
import { API_ENDPOINT_ONBOARDING_GENERATE_MAGIC_LINK_ROCKETLANE } from "app/scenes/Onboarding/onboarding.constants";
import TourIcon from "@mui/icons-material/Tour";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import GroupIcon from "@mui/icons-material/Group";
import LuggageIcon from "@mui/icons-material/Luggage";
import { appMetaConfig } from "global.constants";
import { getSubdomain } from "app/utils/generic.helper";



const menuIconMap: any = {
  TimelineRoundedIcon: <TimelineRoundedIcon style={{ fontSize: 28 }} />,
  KeyRoundedIcon: <KeyRoundedIcon style={{ fontSize: 28 }} />,
  QueryStatsRoundedIcon: <QueryStatsRoundedIcon style={{ fontSize: 28 }} />,
  NotificationsRoundedIcon: (
    <NotificationsRoundedIcon style={{ fontSize: 28 }} />
  ),
  ReconciliationOutlined: <ReconciliationOutlined style={{ fontSize: 28 }} />,
  FlightTakeoffRoundedIcon: (
    <FlightTakeoffRoundedIcon style={{ fontSize: 24 }} />
  ),
  PercentRoundedIcon: <PercentRoundedIcon style={{ fontSize: 24 }} />,
  MailOutlined: <MailOutlined style={{ fontSize: 24 }} />,
  DescriptionOutlinedIcon: <DescriptionOutlinedIcon style={{ fontSize: 24 }} />,
  SignalCellularAltOutlinedIcon: (
    <SignalCellularAltOutlinedIcon style={{ fontSize: 24 }} />
  ),
  TourIcon: <TourIcon style={{ fontSize: 24 }} />,
  LuggageIcon: <LuggageIcon style={{ fontSize: 22 }} />,
  WorkspacesIcon: <WorkspacesIcon style={{ fontSize: 22 }} />,
  GroupIcon: <GroupIcon style={{ fontSize: 22 }} />,
  AddToPhotosIcon: <AddToPhotosIcon style={{ fontSize: 22 }} />,
  HotelRoundedIcon: <HotelRoundedIcon style={{ fontSize: 22 }} />,
  HomeOutlinedIcon: <HomeOutlinedIcon style={{ fontSize: 22 }} />,
  CloudUploadOutlinedIcon: <CloudUploadOutlinedIcon style={{ fontSize: 22 }} />,
  HotelIcon: <HotelIcon style={{ fontSize: 22 }} />,
  KeyIcon: <KeyIcon style={{ fontSize: 22 }} />,
  FilePdfOutlined: <FilePdfOutlined style={{ fontSize: 22 }} />,
};

const settingSubMenu = [
  {
    title: "Members",
    icon: "GroupIcon",
    subMenus: [],
    route: "/settings/members",
  },
  {
    title: "Workspaces",
    icon: "WorkspacesIcon",
    subMenus: [],
    route: "/settings/workspace",
  },
];

export default function PrimaryNavBhilosa(props: any) {
  console.log(props,"props in primary nav");
  const [activeMenu, setActiveMenu] = useState("");
  const [openNestedMenu, setOpenNestedMenu] = useState(false);
  const [subMenuItem, setSubMenuItem] = useState(null);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const location = useLocation();
  const domain = getSubdomain();
  // const [userRole, setUserRole] = useRecoilState(roleInfo);
  const userRole = localStorage.getItem("role");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAccessDeniedModalVisible, setAccessDeniedModalVisible] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const [activeModalItem, setActiveModalItem] = useState("");

  

  const handleRedirect = () => {
    window.open(
      "https://onboarding.finkraft.ai/projects/450451/plan",
      "_blank"
    );
    closeModal();
  };

  // const domain = "mmt";

  const handleOpenNestedMenu = (menuItem: any) => {
    setOpenNestedMenu(!openNestedMenu);
    props.openMenu && props.openMenu();
    setSubMenuItem(menuItem);
    setActiveMenu(menuItem.route);
  };
  const handleCloseNestedMenu = () => {
    setOpenNestedMenu(false);
    props.openMenu && props.openMenu();
  };
  const navigate = useNavigate();
  const handleOpenSubMenu = async (menu: any) => {
    setActiveMenu(menu.route);
    if (menu.length > 0) {
      setOpenNestedMenu(!openNestedMenu);
      props.openMenu && props.openMenu();
    } else {
      navigate(menu.route);
    }
  };
  const [isShowPopup, setShowPopup] = useState(false);
  const showPopup = () => setShowPopup(true);

  const handleLogout = () => {
    // showPopup
    localStorage.clear();
    navigate("/auth/signin");
  };
  const handleCancel = () => {
    setShowPopup(false);
  };
  const opensetting = () => {
    console.log("Inside settings")
    if (userRole === "ADMIN") {
      navigate(`/vendorfollowup/memebersetting`);
    } else {
      setAccessDeniedModalVisible(true); // Show modal if not an admin
    }
  };

  const bottomMenuItems: MenuProps = {
    items: [
      {
        key: "1",
        label: (
          <>
            <div className="UserAvatarInfoPrimaryNav">
              <Avatar style={{ backgroundColor: colorPicker("primary.700") }}>
                {userDetails?.email?.[0]?.toUpperCase()}
              </Avatar>
              <div className="UserInfo">
                <Typography variant="body2" weight={600}>
                  {userDetails?.name}
                </Typography>
                <Typography variant="xs" weight={400} style={{ color: "gray" }}>
                  {userDetails?.email}
                </Typography>
              </div>
            </div>
            <Divider style={{ margin: 0, marginTop: 8 }} />
          </>
        ),
        disabled: true,
      },
      {
        key: "2",
        danger: true,
        label: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              cursor: "pointer",
            }}
            onClick={openModal}
          >
            <img src={phoneIcon} style={{ width: "19px", padding: "0px" }} />
            <Typography variant="body2">Help Desk</Typography>
          </div>
        ),
      },

      {
        key: "3",
        danger: true,
        label: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              cursor: "pointer",
            }}
            // onClick={handleLogout}
            onClick={showPopup}
          >
            <FontAwesomeIcon
              icon={faRightFromBracket}
              style={{ color: colorPicker("neutral.700") }}
            />
            <Typography variant="body2">Logout</Typography>
          </div>
        ),
      },

      {
        key: "4",
        label: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              cursor: "pointer",
            }}
          // onClick={() => navigate("/vendorfollowup/memebersetting")}
          // onClick={opensetting}
          >
            <SettingsOutlinedIcon
              style={{ color: colorPicker("neutral.700") }}
            />
            <Typography variant="body2">Settings</Typography>
          </div>
        ),
      },
    ],
  };

  const checkIFRoutehasActive = (route: string) => {
    if (location.pathname.includes(route)) {
      return true;
    } else {
      return false;
    }
  };

  const appFinkraftMenu: any = [
    {
      title: "Invoices",
      icon: "AddToPhotosIcon",
      subMenus: [],
      route: "/invoices",
    },
  ];

  const subdoman = getSubdomain();
  const staticCDNURL = `https://static-assets.finkraftai.com/logo/${subdoman}.png`;
  return (
    <>
      <div className="PrimaryNav">
        <div className="NavigationControllerBhilosa">
          <div className="TopNavigation">
            <div className="AppLogo">
              <img
                alt="appIcon"
                style={{ width: domain === "samsung" ? 54 : 72 }}
                // src={appMetaConfig[getSubdomain() || "app"]?.logo}
                src="https://static-assets.finkraftai.com/logo/finkraft.png"
              />
            </div>
            <div className="MenuContainer">
              {userDetails.create_type === "OPEN_SIGNUP" ? (
                // Render the specific menu item for OPEN_SIGNUP
                <div
                  className={
                    "MenuItem " +
                    (activeMenu === "/integrations" ||
                      checkIFRoutehasActive("/integrations")
                      ? " active"
                      : "")
                  }
                  onClick={() => handleOpenSubMenu({ route: "/integrations" })}
                >
                  {menuIconMap["HotelRoundedIcon"]}
                  <Typography
                    variant="xs"
                    style={{
                      color:
                        activeMenu === "/integrations" ||
                          checkIFRoutehasActive("/integrations")
                          ? colorPicker("primary.700")
                          : "",
                    }}
                  >
                    Integration
                  </Typography>
                </div>
              ) : (
                // Render the existing logic for other cases
                [
                  {
                    title: "Dashboard",
                    icon: "HomeOutlinedIcon",
                    subMenus: [],
                    route: "/vendorfollowup/gstintegration",
                  },
                  {
                    title: "Master View",
                    icon: "HotelIcon",
                    subMenus: [],
                    route: '/vendorfollowup/vendoreinvoice/tabs',
                  },
                  // {
                  //   title: "GST Integration",
                  //   icon: "KeyIcon",
                  //   subMenus: [],
                  //    route: "/vendorfollowup/gst/creds",
                  // },
                  {
                    title: "GST Integration",
                    icon: "KeyIcon",
                    subMenus: [],
                    route: "/vendorfollowup/gst/integration/credential",
                  },
                ].map((menuItem: any, key: any) => {
                  return (!userDetails.gstinStatus &&
                    userDetails.create_type === "OPEN_SIGNUP" &&
                    menuItem.flagCheck) ||
                    (domain === "app" && menuItem.title === "Flight") ? null : (
                    <div
                      key={key}
                      className={
                        "MenuItem " +
                        (activeMenu === menuItem.route ||
                          checkIFRoutehasActive(menuItem.route)
                          ? " active"
                          : "")
                      }
                      onClick={() =>
                        menuItem.subMenus.length > 0
                          ? handleOpenNestedMenu(menuItem)
                          : handleOpenSubMenu(menuItem)
                      }
                    >
                      {menuIconMap[menuItem.icon]}
                      <Typography
                        variant="xs"
                        style={{
                          textAlign: "center",
                          color:
                            activeMenu === menuItem.route ||
                              checkIFRoutehasActive(menuItem.route)
                              ? colorPicker("primary.700")
                              : "",
                        }}
                      >
                        {menuItem.title}
                      </Typography>
                    </div>
                  );
                })
              )}
            </div>
          </div>

          {/* <div className="BottomNavigation">

            <div className="MenuItem" onClick={() => navigate("/vendorfollowup/vendorlist")}>
              <img src={customerIcon} style={{ width: "19px", padding: "0px" }} />
              <Typography variant="body2"><span style={{ marginLeft: '4px' }}>Customer</span><br></br>Management</Typography>
            </div>

            <div className="MenuItem" onClick={openModal}>
              <img src={phoneIcon} style={{ width: "19px", padding: "0px" }} />
              <Typography variant="body2">Help Desk</Typography>
            </div>

            <div
              className="MenuItem"
              // onClick={() => {
              //   // setActiveMenu("settings_schema");
              //   navigate("/vendorfollowup/memebersetting");
              // }}
              onClick={opensetting}
            >
              <SettingsOutlinedIcon
                style={{ color: colorPicker("neutral.700") }}
                // onClick={() => {
                //   navigate("/vendorfollowup/memebersetting");
                // }}
                onClick={opensetting}
              />
              <Typography
                variant="xs"
                style={{ color: colorPicker("neutral.700") }}
              >
                Settings
              </Typography>
            </div>

            <div
              className="MenuItem"
              // onClick={handleLogout}
              onClick={showPopup}
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon
                icon={faRightFromBracket}
                style={{ color: colorPicker("neutral.700") }}
              />
              <Typography
                variant="xs"
                style={{ color: colorPicker("neutral.700") }}
              >
                Logout
              </Typography>
            </div>

            <div
              className="MenuItem"
              style={{ zIndex: 99999, marginBottom: 0 }}
            >
              <Dropdown
                menu={bottomMenuItems}
                placement="topRight"
                arrow
                overlayStyle={{ zIndex: 99999 }}
              >
              </Dropdown>
            </div>
          </div> */}
          <div className="BottomNavigation">

            <div
              className={
                "MenuItem " +
                ((activeMenu === "/vendorfollowup/vendorlist" ||
                  checkIFRoutehasActive("/vendorfollowup/vendorlist"))
                  ? " active"
                  : "")
              }
              onClick={() => navigate("/vendorfollowup/vendorlist")}
            >
              <img src={customerIcon} style={{ width: "19px", padding: "0px" }} />
              <Typography
                variant="body2"
                style={{
                  color:
                    activeMenu === "/vendorfollowup/vendorlist" ||
                      checkIFRoutehasActive("/vendorfollowup/vendorlist")
                      ? colorPicker("primary.700")
                      : colorPicker("neutral.700"),
                }}
              >
                <span style={{ marginLeft: "4px" }}>Customer</span>
                <br />
                Management
              </Typography>
            </div>

            <div
            className={
              "MenuItem " +
              (activeModalItem === "helpdesk" ? " active" : "")
            }
              // onClick={openModal}
              onClick={() => {
                setActiveModalItem("helpdesk");
                openModal();
              }}
            >
              <img src={phoneIcon} style={{ width: "19px", padding: "0px" }} />
              <Typography
                variant="body2"
                style={{
                  color:
                    activeModalItem === "helpdesk"
                      ? colorPicker("primary.700")
                      : colorPicker("neutral.700"),
                }}
              >
                Help Desk
              </Typography>
            </div>

            <div
              className={
                "MenuItem " +
                ((activeMenu === "/vendorfollowup/memebersetting" ||
                  checkIFRoutehasActive("/vendorfollowup/memebersetting"))
                  ? " active"
                  : "")
              }
              onClick={opensetting}
            >
              <SettingsOutlinedIcon
                style={{
                  color:
                    activeMenu === "/vendorfollowup/memebersetting" ||
                      checkIFRoutehasActive("/vendorfollowup/memebersetting")
                      ? colorPicker("primary.700")
                      : colorPicker("neutral.700"),
                }}
              />
              <Typography
                variant="xs"
                style={{
                  color:
                    activeMenu === "/vendorfollowup/memebersetting" ||
                      checkIFRoutehasActive("/vendorfollowup/memebersetting")
                      ? colorPicker("primary.700")
                      : colorPicker("neutral.700"),
                }}
              >
                Settings
              </Typography>
            </div>

            <div
              className={
                "MenuItem " +
                (activeModalItem === "logout" ? " active" : "")
              }
              onClick={() => {
                setActiveModalItem("logout");
                showPopup();
              }}
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon
                icon={faRightFromBracket}
                style={{
                  color:
                    activeModalItem === "logout"
                      ? colorPicker("primary.700")
                      : colorPicker("neutral.700"),
                }}
              />
              <Typography
                variant="xs"
                style={{
                  color:
                    activeModalItem === "logout"
                      ? colorPicker("primary.700")
                      : colorPicker("neutral.700"),
                }}
              >
                Logout
              </Typography>
            </div>

            <div className="MenuItem" style={{ zIndex: 99999, marginBottom: 0 }}>
              <Dropdown
                menu={bottomMenuItems}
                placement="topRight"
                arrow
                overlayStyle={{ zIndex: 99999 }}
              ></Dropdown>
            </div>
          </div>

        </div>
        <Drawer
          placement={"left"}
          closable={false}
          onClose={handleCloseNestedMenu}
          open={openNestedMenu}
          width={200}
          mask={false}
          maskClosable
          style={{ marginLeft: 80, padding: 0 }}
          zIndex={1}
        >
          <SubMenu
            onClose={handleCloseNestedMenu}
            menuItems={subMenuItem}
            iconMap={menuIconMap}
          />
        </Drawer>
      </div>
      {isShowPopup && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1000,
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            padding: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "400px",
            height: "10%",
          }}
        >
          <div
            style={{
              borderRadius: "10px",
              padding: "20px",
              textAlign: "center",
              maxWidth: "400px",
              width: "100%",
            }}
          >
            <h1
              style={{
                margin: "0 0 20px 0",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                style={{ marginRight: "8px", color: "#ff4d4f" }}
              />
              Logout Confirmation
            </h1>
            <p style={{ margin: "0 0 20px 0" }}>
              Are you sure you want to log out?
            </p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button
                onClick={handleCancel}
                style={{
                  backgroundColor: "#ccc",
                  color: "#333",
                  border: "none",
                  padding: "10px 35px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                No
              </button>
              <button
                onClick={handleLogout}
                style={{
                  backgroundColor: "#ff4d4f",
                  color: "white",
                  border: "none",
                  padding: "10px 20px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      )}


      <Modal
        title="Redirect to Support Portal"
        visible={isModalOpen}
        onCancel={closeModal}
        centered
        footer={[
          <Button key="cancel" onClick={closeModal}>
            Cancel 🚫
          </Button>,
          <Button key="redirect" type="primary" onClick={handleRedirect}>
            Redirect ↗️
          </Button>,
        ]}
      >
        <p>
          You will be Re-Directed to Onboarding.Finkratf, our support portal.
          Please login with your organization credentials to raise a ticket.
        </p>
      </Modal>

      <Modal
        title="Access Denied"
        open={isAccessDeniedModalVisible}
        onOk={() => setAccessDeniedModalVisible(false)}
        onCancel={() => setAccessDeniedModalVisible(false)}
        centered
      >
        <p>You need admin access to view this page.</p>
      </Modal>
    </>
  );
}
