import { AgTableServer } from "app/shared/AgTable";
import PageHeader from "app/shared/PageHeader";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Select, Tag, Badge, Tooltip, Button, Modal, Input } from "antd"; // Import Ant Design components
import moment from "moment";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import AdsClickOutlinedIcon from "@mui/icons-material/AdsClickOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import MarkunreadMailboxOutlinedIcon from "@mui/icons-material/MarkunreadMailboxOutlined";
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";
import ScheduleSendOutlinedIcon from "@mui/icons-material/ScheduleSendOutlined";
import { apiGet, apiPost } from "app/services/apiServices";
import { API_ENDPOINT_FETCH_TEMPLATE_INFO } from "../HotelBookingView/hotel.booking.constants";
import { MailOutlined, PhoneOutlined, EditOutlined } from "@ant-design/icons";
import Handlebars from "handlebars";
import "./HotelEmailFollowUp.css";
import Typography from "app/shared/Typography";
import { filter, values } from "lodash";
import HotelDataUpdate from "./HotelDataUpdateEmai";
import { EditFilled } from "@ant-design/icons";
const { Option } = Select;

const HotelEmail = () => {
  const [selectedClient, setSelectedClient] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [selectedBooking, setSelectedBooking] = useState(null); // Stores row data
  const [email, setEmail] = useState("");
  const [contactDetails, setContactDetails] = useState(null);

  const [modalType, setModalType] = useState(null);
  const [showModalEmail, setShowModalEmail] = useState(false);
  const [modalConfig, setModalConfig] = useState({ visible: false, type: "" });

  // const [templateDataBulk, setTemplateDataBulk] = useState(null);
  const [templateData, setTemplateData] = useState(null);
  // Handle Client Selection Change
  const handleChange = (value) => {
    setSelectedClient(value);
    console.log("Selected Client:", value);
  };

  console.log(selectedClient, "selected Client");

  // Format timestamp into readable date
  const formatDate = (timestamp) => {
    if (!timestamp) return "N/A";
    return moment.unix(timestamp).format("DD-MM-YYYY, hh:mm A"); // Fix: Corrected minute formatting
  };

  const colorOfEmailEvent = {
    processed: "#007BFF",
    delivered: "#007BFF",
    open: "#28A745",
    click: "#FFC107",
    bounce: "#E74C3C",
    dropped: "#E74C3C",
    spammed: "#E74C3C",
    deffered: "#E74C3C",
  };

  const EVENT_ICON_MAP = {
    processed: (color) => <CheckOutlinedIcon style={{ fontSize: 18, color }} />,
    delivered: (color) => (
      <DoneAllOutlinedIcon style={{ fontSize: 18, color }} />
    ),
    open: (color) => <DraftsOutlinedIcon style={{ fontSize: 18, color }} />,
    click: (color) => <AdsClickOutlinedIcon style={{ fontSize: 18, color }} />,
    bounce: (color) => <CancelOutlinedIcon style={{ fontSize: 18, color }} />,
    dropped: (color) => (
      <MarkunreadMailboxOutlinedIcon style={{ fontSize: 18, color }} />
    ),
    spammed: (color) => (
      <PrivacyTipOutlinedIcon style={{ fontSize: 18, color }} />
    ),
    deffered: (color) => (
      <ScheduleSendOutlinedIcon style={{ fontSize: 18, color }} />
    ),
  };

  // Function to Render Email Logs
  const handleRenderEmailEvents = (params) => {
    let emailLogs = params.value;
    if (params.node.group) {
      return null;
    }
    if (!emailLogs || emailLogs.length === 0) return "No Logs Available";

    return (
      <div style={{ display: "flex", gap: 8 }}>
        {emailLogs.map((log, index) => (
          <Tooltip
            key={index}
            placement="bottom"
            title={
              <div>
                <Typography style={{ color: "white", marginBottom: 8 }}>
                  <strong></strong> {log.log_type || "No Log Type"}
                </Typography>
                {log.events && log.events.length > 0 ? (
                  log.events.map((event, i) => {
                    const eventType = event.event || "unknown";
                    const eventColor =
                      colorOfEmailEvent[eventType] || "#A0A0A0"; // Default gray
                    const EventIcon = EVENT_ICON_MAP[eventType];

                    return (
                      <Tag
                        key={i}
                        style={{
                          fontSize: 10,
                          display: "flex",
                          alignItems: "center",
                          gap: 4,
                        }}
                      >
                        {EventIcon ? EventIcon(eventColor) : null}
                        {event.event
                          ? `${event.event} at ${formatDate(event.timestamp)}`
                          : "No Event Info"}
                      </Tag>
                    );
                  })
                ) : (
                  <div>
                    {["open", "click", "bounce"].map((eventType, i) => (
                      <Tag
                        key={i}
                        style={{
                          fontSize: 10,
                          display: "flex",
                          alignItems: "center",
                          gap: 5,
                          marginTop: "10px",
                        }}
                      >
                        {EVENT_ICON_MAP[eventType]
                          ? EVENT_ICON_MAP[eventType](
                              colorOfEmailEvent[eventType]
                            )
                          : null}
                        {eventType.charAt(0).toUpperCase() + eventType.slice(1)}{" "}
                        at {formatDate(1742466843 + i * 1000)}
                      </Tag>
                    ))}
                  </div>
                )}
              </div>
            }
          >
            <Badge
              count={index + 1} // Show number badges
              style={{
                backgroundColor: "#0a8394",
                color: "white",
                cursor: "pointer",
                padding: "6px",
                borderRadius: "50%",
                minWidth: "22px",
                height: "22px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            />
          </Tooltip>
        ))}
      </div>
    );
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const openEmailModal = useCallback((data) => {
    setContactDetails(data);
    setModalConfig({ visible: true, type: "email" });
  }, []);

  const openPhoneModal = useCallback((data) => {
    setContactDetails(data);
    setModalConfig({ visible: true, type: "phone" });
  }, []);
  const handleEmailSubmit = () => {
    console.log("Email added:", email, "for row:", selectedBooking);
    setIsModalVisible(false);
  };

  useEffect(() => {
    fetchTemplateInfo();
    // fetchTemplateInfoBulk();
  }, []);
  const fetchTemplateInfo = async () => {
    const response = await apiGet(
      //   API_ENDPOINT_FETCH_TEMPLATE_INFO + "d-e2a6aa5cdc5240b9ac3f51e5782f9608"
      API_ENDPOINT_FETCH_TEMPLATE_INFO + "d-e2a6aa5cdc5240b9ac3f51e5782f9608"
    );
    console.log(response, "response sendgrid");
    if (response) {
      setTemplateData(response.html_content);
    }
  };

  const renderTemplate = (htmlContent, data) => {
    console.log("htmlContent", data);
    const templateCompiler = Handlebars.compile(htmlContent);
    const renderedContent = templateCompiler(data);

    return renderedContent;
  };

  // Column Definitions for Ag-Grid
  const columnDefs = useMemo(
    () => [
      {
        headerName: "Mail Config Status",
        field: "mailConfigStauts",
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["RUNNING", "EMAIL_REQUIRED"], // Correct way to specify filter values
        },
        enableRowGroup: true,

        cellRenderer: (params) => {
          return (
            <Tag
              color={
                params?.data?.mailConfigStauts === "RUNNING" ? "green" : "red"
              }
            >
              {params?.data?.mailConfigStauts}
            </Tag>
          );
        },
      },
      {
        headerName: "Email Booking ID",
        field: "email_bookingId",
        filter: "agTextColumnFilter",
        enableRowGroup: true,
      },
      {
        headerName: "Booking Client ID",
        field: "booking_client_id",
        filter: "agTextColumnFilter",
        enableRowGroup: true,
      },

      {
        headerName: "Entity Name",
        field: "company_name",
        filter: "agTextColumnFilter",
        enableRowGroup: true,
      },
      {
        headerName: "Template",
        width: 200,
        cellRenderer: (params) => {
          // Hide the button when row is grouped
          if (params.node.group) {
            return null;
          }

          return (
            <Button
              type="primary"
              onClick={() => {
                setModalType("TEMPLATE");
                setModalData(params?.data);
                setIsModalVisible(true);
              }}
              size="small"
            >
              Show Template
            </Button>
          );
        },
      },
      {
        headerName: "Hotel Name",
        field: "email_hotel_name",
        filter: "agTextColumnFilter",
        enableRowGroup: true,
      },

      {
        headerName: "Hotel Contact Email",
        field: "email_hotel_contact_email",
        enableRowGroup: true,
        width: 300,
        filter: "agTextColumnFilter",
        cellRenderer: (params) => {
          if (params.node.group) {
            return null;
          }
          const emails = params.value; // Assuming params.value is an array of emails
          const visibleEmail = emails?.[0]; // Show only the first email
          const remainingCount = emails?.length > 1 ? emails.length - 1 : 0; // Count remaining emails

          return (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "5px",
                flexWrap: "wrap",
              }}
            >
              {emails && Array.isArray(emails) && emails.length > 0 ? (
                <>
                  <Tag icon={<MailOutlined />}>{visibleEmail}</Tag>

                  {remainingCount > 0 && (
                    <Tooltip
                      title={emails.slice(1).map((email, idx) => (
                        <div key={idx}>{email}</div>
                      ))}
                    >
                      <span style={{ color: "#1890ff", cursor: "pointer" }}>
                        +{remainingCount}
                      </span>
                    </Tooltip>
                  )}

                  <EditFilled
                    style={{ cursor: "pointer", marginLeft: "5px" }}
                    onClick={() => openEmailModal(params.data)}
                  />
                </>
              ) : (
                <Tag
                  style={{ cursor: "pointer" }}
                  onClick={() => openEmailModal(params.data)}
                >
                  Add Email
                </Tag>
              )}
            </div>
          );
        },
      },

      {
        headerName: "Hotel Contact Number",
        field: "email_hotel_contact_phone",
        width: 300,
        enableRowGroup: true,
        filter: "agTextColumnFilter",
        cellRenderer: (params) => {
          if (params.node.group) {
            return null;
          }
          const phoneNumbers = params.value; // Assuming it's an array of phone numbers
          const visiblePhone = phoneNumbers?.[0]; // Show only the first phone number
          const remainingCount =
            phoneNumbers?.length > 1 ? phoneNumbers.length - 1 : 0; // Count remaining numbers

          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                flexWrap: "wrap",
                overflow: "hidden",
                maxWidth: "100%",
              }}
            >
              {phoneNumbers &&
              Array.isArray(phoneNumbers) &&
              phoneNumbers.length > 0 ? (
                <>
                  <Tag icon={<PhoneOutlined />}>{visiblePhone}</Tag>

                  {remainingCount > 0 && (
                    <Tooltip
                      title={phoneNumbers.slice(1).map((phone, idx) => (
                        <div key={idx}>{phone}</div>
                      ))}
                    >
                      <span style={{ color: "#1890ff", cursor: "pointer" }}>
                        +{remainingCount}
                      </span>
                    </Tooltip>
                  )}

                  <EditFilled
                    style={{ cursor: "pointer", marginLeft: "5px" }}
                    onClick={() => openPhoneModal(params.data)}
                  />
                </>
              ) : (
                <Tag
                  style={{ cursor: "pointer" }}
                  onClick={() => openPhoneModal(params.data)}
                >
                  Add Phone
                </Tag>
              )}
            </div>
          );
        },
      },

      {
        headerName: "Traveller Info",

        field: "email_travellers_info",
        filter: "agTextColumnFilter",
        cellRenderer: (params) => {
          return (
            <div style={{ height: "100%", width: "100%" }}>
              {(params.data?.email_travellers_info || []).map((item, key) => {
                return <Tag key={key}>{item.name}</Tag>;
              })}
            </div>
          );
        },
      },
      {
        headerName: "Hotel Address",
        field: "email_hotel_address",
        filter: "agTextColumnFilter",
        enableRowGroup: true,
      },
      {
        headerName: "City",
        field: "email_hotel_city",
        filter: "agTextColumnFilter",
        enableRowGroup: true,
      },
      {
        headerName: "State",
        field: "email_hotel_state",
        enableRowGroup: true,
        filter: "agTextColumnFilter",
      },
      // {
      //   headerName: "Check-in Date",
      //   field: "email_checkindate",
      //   filter: "agDateColumnFilter",
      //   enableRowGroup: true,
      //   valueFormatter: (params) => {
      //     if (!params.value) return "";
      //     const date = new Date(params.value);
      //     return date.toLocaleDateString("en-GB"); // Formats as "dd/mm/yyyy"
      //   },
      // },
      {
        headerName: "Check-in Date",
        field: "email_checkindate",
        filter: "agDateColumnFilter",
        enableRowGroup: true,
        valueFormatter: (params) => {
          if (!params.value) return "";

          const date = new Date(params.value);
          return date
            .toISOString()
            .split("T")[0]
            .split("-")
            .reverse()
            .join("/");
          // Converts "2025-03-31T15:00:00+05:30" → "31/03/2025"
        },
      },
      {
        headerName: "Booking Date",
        field: "email_bookingdate",
        filter: "agDateColumnFilter",
        enableRowGroup: true,
        valueFormatter: (params) => {
          if (!params.value) return "";
          const date = new Date(params.value);
          return date.toLocaleDateString("en-GB"); // Formats as "dd/mm/yyyy"
        },
      },
      {
        headerName: "Check-out Date",
        field: "email_checkoutdate",
        filter: "agDateColumnFilter",
        enableRowGroup: true,
        valueFormatter: (params) => {
          if (!params.value) return "";
          const date = new Date(params.value);
          return date.toLocaleDateString("en-GB"); // Formats as "dd/mm/yyyy"
        },
      },

      {
        headerName: "Traveller Info",

        field: "email_travellers_info",
        filter: "agTextColumnFilter",
        cellRenderer: (params) => {
          return (
            <div style={{ height: "100%", width: "100%" }}>
              {(params.data?.email_travellers_info || []).map((item, key) => {
                return <Tag key={key}>{item.name}</Tag>;
              })}
            </div>
          );
        },
        enableRowGroup: true,
      },
      {
        headerName: "Current Followup Stage",
        field: "last_mail_type",
        enableRowGroup: true,
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Last Email Sent",
        field: "last_mail_sent_at",
        enableRowGroup: true,
        filter: "agDateColumnFilter",
        valueFormatter: (params) => {
          if (!params.value) return "";
          const date = new Date(params.value);
          return date.toLocaleDateString("en-GB"); // Formats as "dd/mm/yyyy"
        },
      },

      {
        headerName: "Email Logs",
        field: "email_logs",
        cellRenderer: (params) => handleRenderEmailEvents(params),
      },
      {
        headerName: "Action",
        width: 400, // Set custom width for the column
        cellRenderer: (params) => {
          if (params.node.group) {
            return null;
          }
          return (
            <div style={{ display: "flex", gap: "8px" }}>
              <Button icon={<MailOutlined />} size="small">
                Send Next Followup Mail
              </Button>
              <Button icon={<MailOutlined />} size="small">
                Send Custom Mail
              </Button>
            </div>
          );
        },
      },
    ],
    []
  );

  const serverPayload = useMemo(
    () => ({
      database: "unified-hotel-followup",
      table: "booking-data",
      selectedClient,
    }),
    [selectedClient]
  );

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <PageHeader title="Email Followup" />
      <div style={{ padding: "8px 12px" }}>
        <Typography variant="xs" style={{ marginBottom: 4 }}>
          Select Client
        </Typography>
        <Select
          value={selectedClient}
          onChange={handleChange}
          style={{ width: 160, marginLeft: "auto", marginBottom: 16 }}
          placeholder="Select Client"
          allowClear
          size="small"
        >
          <Option value="">All</Option>
          <Option value="BCD_NOVARTIS_HOTEL">BCD Novartis</Option>
          <Option value="BCD_PHILIPS_HOTEL">BCD Philips</Option>
          <Option value="RENEW_POWER_BOOKING">Renew Power</Option>
        </Select>
      </div>

      <div
        className="ag-theme-alpine"
        style={{ height: "900px", width: "100%" }}
      >
        <AgTableServer
          columnDefs={columnDefs}
          endpoint={
            "https://stage-apiplatform.finkraftai.com/api/hotelFollowUp/booking"
            // "http://localhost:7000/api/hotelFollowUp/booking"
          }
          serverPayload={serverPayload}
        />
      </div>
      <Modal
        title="Template Details"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800} // This sets the width to 800px
        footer={false}
        style={{ width: 800 }} // This is redundant if width is already set
      >
        {templateData && (
          <div
            style={{
              height: 500,
              width: "100%",
              marginTop: 24,
              overflowY: "auto",
            }}
            dangerouslySetInnerHTML={{
              __html: renderTemplate(templateData, modalData),
            }}
          />
        )}
      </Modal>
      {modalConfig.visible && (
        <Modal
          title={
            modalConfig.type === "email"
              ? "Update Hotel Contact Email"
              : "Update Hotel Contact Number"
          }
          open={modalConfig.visible}
          onCancel={() => setModalConfig({ visible: false })}
          footer={false}
        >
          <HotelDataUpdate
            contactDetails={contactDetails}
            onSuccess={() => setModalConfig({ visible: false })}
            type={modalConfig.type}
          />
        </Modal>
      )}
    </div>
  );
};

export default HotelEmail;
