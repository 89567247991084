// import React, { useState } from "react";
// import { AgCharts } from "ag-charts-react";
// import { DatePicker } from "antd";
// import "./Bar.scss";
// import Loader from "app/shared/Loader";

// const Bar = ({ vendors, onMonthChange }) => {
//   console.log(vendors, "vendors"); 
//   const [selectedMonth, setSelectedMonth] = useState(null);
//   const [open, setOpen] = useState(false);

//   if (!vendors) {
//     return <Loader/>;
//   }
//   // Map vendor data to chart format using individual GST amounts.
//   // Here we assume each vendor has at least one breakdown.
//   const chartData = vendors.map(vendor => {
//     // Use the first breakdown (adjust if needed)
//     const breakdown = vendor.gstin_wise_breakdown[0];

//     // Retrieve the individual GST amount for each invoice status.
//     const receivedInvoice = breakdown.invoice_wise.find(
//       invoice => invoice.invoiceStatus === "Invoice Received"
//     );
//     const notReceivedInvoice = breakdown.invoice_wise.find(
//       invoice => invoice.invoiceStatus === "Invoice Not Received"
//     );   

//     return {
//       vendor: vendor.vendor_name,
//       "GST at Risk": notReceivedInvoice ? notReceivedInvoice.gstamt : 0,
//   "GST not at Risk": receivedInvoice ? receivedInvoice.gstamt : 0,
//       label: "" // Customize if needed.
//     };
//   });

//   // Update the chart options to include a custom tooltip and axis label formatter.
//   const options = {
//     tooltip: {
//       renderer: (params) => {
//         // Get the vendor name from the data.
//         const vendor = params.datum.vendor;
//         // Get the series name (either "GST at Risk" or "GST not at Risk")
//         const seriesName = params.series.name;
//         // Get the value from the hovered segment.
//         const value = params.yValue;
//         // Format the value in lakhs (1 Lakh = 100,000) with two decimal places.
//         const valueInLakhs = (value / 100000).toFixed(2) + " L";


//         return {
//           content: `<div style="text-align: left;">
//                       <strong>${vendor}</strong><br/>
//                       ${seriesName}: ${valueInLakhs}
//                     </div>`
//         };
//       }
//     },
//     theme: "ag-default",
//     data: chartData,
//     series: [
    
//       {
//         type: "bar",
//         direction: "horizontal",
//         xKey: "vendor",
//         yKey: "GST not at Risk",
//         stacked: true,
//         fill: "#0A8394",
//         label: { enabled: false },
//         name: "GST not at Risk",
//       },
//       {
//         type: "bar",
//         direction: "horizontal",
//         xKey: "vendor",
//         yKey: "GST at Risk",
//         stacked: true,
//         fill: "#FFB91B",
//         label: {
//           enabled: true,
//           color: "#fff",
//           formatter: (params) => params.datum.label,
//           fontWeight: "bold",
//         },
//         name: "GST at Risk",
//       },
//     ],
//     legend: {
//       enabled: true,
//       position: "right",
//       item: {
//         marker: { size: 10, shape: "circle" },
//         label: { key: "name", color: "#404040", fontSize: 12 },
//       },
//     },
//     axes: [
//       {
//         type: "category",
//         position: "left",
//         label: { fontSize: 12, color: "#C4C4C4", marginLeft: "30px" },
//       },
//       {
//         type: "number",
//         position: "bottom",
//         tick: { interval: 100000000 },
//         label: {
//           // Format the axis labels to show values in lakhs.
//           formatter: (params) => Math.round(params.value / 100000) + " L",

//           fontSize: 12,
//           fontWeight: 700,
//           color: "#CBD5E0",
//           marginTop: 5,
//           fontFamily: "Helvetica",
//         },
//       },
//     ],
//   };
//   return (
//     <div className="barContainer">
//       <div className="headingPortion">
//         <div className="leftPortion">Top 10 Vendor at Risk</div>
//         <DatePicker
//           picker="month"
//           placeholder=""
//           value={selectedMonth}
//           open={open}
//           inputReadOnly
//           onClick={() => setOpen(true)}
//           onOpenChange={(status) => setOpen(status)}
//           onChange={(date) => {
//             setSelectedMonth(date);
//             console.log(
//               "Bar component selected month:",
//               date ? date.format("MMYYYY") : "none"
//             );
//             if (onMonthChange) {
//               onMonthChange(date);
//             }
//           }}
//           suffixIcon={
//              <div className="custom-suffix"> 
//               <svg
//                 width="17"
//                 height="16"
//                 viewBox="0 0 17 16"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M3.16797 2.66666H13.8346V4.11466C13.8346 4.46825 13.694 4.80733 13.444 5.05732L10.5013 7.99999V12.6667L6.5013 14V8.33332L3.51464 5.04799C3.2916 4.80261 3.168 4.48292 3.16797 4.15132V2.66666Z"
//                   stroke="#0A8394"
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                 />
//               </svg>
//               {!selectedMonth && (
//         <span style={{ color: "#0A8394", fontSize: "12px", fontWeight: "normal" }}>
//           Apply filter
//         </span>
//       )}
//             </div>
//           }
//           className="apply-filter-datepicker1"
//           dropdownClassName="apply-filter-datepicker-dropdown"
//           style={{
//             marginLeft: "auto",
//             display: "flex",
//             alignItems: "center",
//           }}
//         />
//       </div>
//       <div className="chartContainer">
//         <AgCharts options={options} />
//       </div>
//     </div>

//   );
// };
// export default Bar;



import React, { useState } from "react";
import { AgCharts } from "ag-charts-react";
import { DatePicker } from "antd";
import "./Bar.scss";
import Loader from "app/shared/Loader";

const Bar = ({ vendors, onMonthChange }) => {
  console.log(vendors, "vendors"); 
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [open, setOpen] = useState(false);

  if (!vendors) {
    return <Loader/>;
  }
  
  const chartData = vendors.map(vendor => {
    const breakdown = vendor.gstin_wise_breakdown[0];

    const receivedInvoice = breakdown.invoice_wise.find(
      invoice => invoice.invoiceStatus === "Invoice Received"
    );
    const notReceivedInvoice = breakdown.invoice_wise.find(
      invoice => invoice.invoiceStatus === "Invoice Not Received"
    );  
 

    return {
      vendor: vendor.vendor_name,
      gstin: breakdown.gstin,
      "GST at Risk": notReceivedInvoice ? notReceivedInvoice.gstamt : 0,
      "GST not at Risk": receivedInvoice ? receivedInvoice.gstamt : 0,
      label: "" 
    };
  });

  const options = {
    // tooltip: {
    //   enabled: true,
    //   renderer: (params) => {
    //     console.log("tooltip",params.datum);
    //     const vendor = params.datum.vendor;
    //     const gstin = params.datum.gstin;
    //     const seriesName = params.series.name;
    //     const value = params.yValue;
    //     const valueInLakhs = (value / 100000).toFixed(2) + " L";

    //     return {
    //       content: `<div style="text-align: left;">
    //                   GSTIN: ${gstin}<br/>
    //                   ${seriesName}: ${valueInLakhs}
    //                 </div>`
    //     };
    //   }
    // },
    theme: "ag-default",
    data: chartData,
    series: [
      {
        type: "bar",
        direction: "horizontal",
        xKey: "vendor",
        yKey: "GST not at Risk",
        stacked: true,
        fill: "#0A8394",
        label: { enabled: false },
        name: "GST not at Risk",
        // tooltip: {
        //   renderer: (params) => {
        //     return {
        //       content: `GSTIN: ${params.datum.gstin}`
        //     };
        //   }
        // }
        tooltip: {
          enabled: true,
          renderer: (params) => {
            console.log("Tooltip Params:", params); // Debugging
            const gstin = params.datum.gstin || "N/A";  // Ensure GSTIN is available
            const yKey = params.yKey;  // Get the key used for Y-axis
            const value = params.datum[yKey];  // Extract the actual value
            const valueInLakhs = isNaN(value) ? "N/A" : (value / 100000).toFixed(2) + " L"; 
        
            return {
              content: `<div style="text-align: left;">
                         <strong>GSTIN:</strong> ${gstin}&nbsp;&nbsp;&nbsp;${valueInLakhs}
                        </div>`
            };
          }
        }
        
      },
      {
        type: "bar",
        direction: "horizontal",
        xKey: "vendor",
        yKey: "GST at Risk",
        stacked: true,
        fill: "#FFB91B",
        label: {
          enabled: true,
          color: "#fff",
          formatter: (params) => params.datum.label,
          fontWeight: "bold",
        },
        name: "GST at Risk",
        tooltip: {
          enabled: true,
          renderer: (params) => {
            console.log("Tooltip Params:", params); // Debugging
            const gstin = params.datum.gstin || "N/A";  // Ensure GSTIN is available
            const yKey = params.yKey;  // Get the key used for Y-axis
            const value = params.datum[yKey];  // Extract the actual value
            const valueInLakhs = isNaN(value) ? "N/A" : (value / 100000).toFixed(2) + " L"; 
        
            return {
              content: `<div style="text-align: left;">
                         <strong>GSTIN:</strong> ${gstin}&nbsp;&nbsp;&nbsp;${valueInLakhs}
                        </div>`
            };
          }
        }
      },
    ],
    legend: {
      enabled: true,
      position: "right",
      item: {
        marker: { size: 10, shape: "circle" },
        label: { key: "name", color: "#404040", fontSize: 12 },
      },
    },
    axes: [
      {
        type: "category",
        position: "left",
        label: { fontSize: 12, color: "#C4C4C4", marginLeft: "30px" },
      },
      {
        type: "number",
        position: "bottom",
        tick: { interval: 100000000 },
        label: {
          formatter: (params) => Math.round(params.value / 100000) + " L",
          fontSize: 12,
          fontWeight: 700,
          color: "#CBD5E0",
          marginTop: 5,
          fontFamily: "Helvetica",
        },
      },
    ],
  };

  return (
    <div className="barContainer">
      <div className="headingPortion">
        <div className="leftPortion">Top 10 Vendor at Risk</div>
        <DatePicker
          picker="month"
          placeholder=""
          value={selectedMonth}
          open={open}
          inputReadOnly
          onClick={() => setOpen(true)}
          onOpenChange={(status) => setOpen(status)}
          onChange={(date) => {
            setSelectedMonth(date);
            console.log(
              "Bar component selected month:",
              date ? date.format("MMYYYY") : "none"
            );
            if (onMonthChange) {
              onMonthChange(date);
            }
          }}
          suffixIcon={
            <div className="custom-suffix"> 
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.16797 2.66666H13.8346V4.11466C13.8346 4.46825 13.694 4.80733 13.444 5.05732L10.5013 7.99999V12.6667L6.5013 14V8.33332L3.51464 5.04799C3.2916 4.80261 3.168 4.48292 3.16797 4.15132V2.66666Z"
                  stroke="#0A8394"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {!selectedMonth && (
                <span style={{ color: "#0A8394", fontSize: "12px", fontWeight: "normal" }}>
                  Apply filter
                </span>
              )}
            </div>
          }
          className="apply-filter-datepicker1"
          dropdownClassName="apply-filter-datepicker-dropdown"
          style={{
            marginLeft: "auto",
            display: "flex",
            alignItems: "center",
          }}
        />
      </div>
      <div className="chartContainer">
        <AgCharts options={options} />
      </div>
    </div>
  );
};
export default Bar;
