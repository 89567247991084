import React, { useState, useEffect, useRef } from "react";
import AgTableClient from "../../shared/AgTable/AgTableClient";
import BookingData from "./sampleBookking.json";
import sample2BData from "./sample2B.json";
import { useRecoilState, useSetRecoilState } from "recoil";
import ToolParamInvoker from "app/shared/AgTable/HelperTools/ToolParamInvoker";
//import { tableSchema } from "app/config/States/agtable";
import { tableSchema } from "app/config/States/agtable";
import { currentPDFData, userInfo } from "app/config/States/users";
import { getProcessedDefinition, getSubdomain } from "app/utils/generic.helper";

const DemoPage = ({ activeTab }) => {
    const [rowData, setRowData] = useState(BookingData);
    const [columnDefs, setColumnDefs] = useState([]);
    const gridRef = useRef();
    const [tableSchemaInfo, setTableSchema] = useRecoilState(tableSchema);
    const setRecoilPdfData = useSetRecoilState(currentPDFData);
    const [userDetails, setUserDetails] = useRecoilState(userInfo);


    const openSidebarRenderer = (params) => {
        console.log(params?.data?.["Updated Asset Link"], "params in openSidebarRenderer")
        params.api.openToolPanel("pdfRenderer", { paramData: params?.data?.["Updated Asset Link"] });
    };

    console.log(activeTab, "activeTab")

    useEffect(() => {
        if (activeTab === "2") {
            setRowData(sample2BData);
            setColumnDefs(columnDefs2);
        } else {
            setRowData(BookingData);
            setColumnDefs(columnDefs1);
        }
    }, [activeTab]);


    const columnDefs1 = [
        { headerName: "Vendor", field: "Vendor", sortable: true, filter: "agTextColumnFilter", enableRowGroup: true },
        { headerName: "Type", field: "Type", sortable: true, filter: true, enableRowGroup: true },
        {
            headerName: "Priority",
            field: "Priority",
            sortable: true,
            filter: true,
            enableRowGroup: true,
            valueFormatter: (params) => params.value || ""
        },
        { headerName: "Name as per GST Portal", field: "Name as per GST Portal", sortable: true, filter: true, enableRowGroup: true },
        {
            headerName: "Transaction Date",
            field: "Transaction_Date",
            sortable: true,
            filter: "agDateColumnFilter",
            enableRowGroup: true,
            formatType: "DATE",
            formatProps: {
                dateFormat: "DD-MM-YYYY"
            },
        },
        {
            headerName: "Invoice Date",
            field: "Invoice_Date",
            sortable: true,
            filter: "agDateColumnFilter",
            enableRowGroup: true,
            formatType: "DATE",
            formatProps: {
                dateFormat: "DD-MM-YYYY"
            },
        },
        

        { headerName: "Ticket Number", field: "Ticket_Number", sortable: true, filter: "agTextColumnFilter", enableRowGroup: true },
        { headerName: "PNR", field: "PNR", sortable: true, filter: "agTextColumnFilter", enableRowGroup: true },
        { headerName: "Ticket/PNR", field: "Ticket/PNR", sortable: true, filter: "agTextColumnFilter", enableRowGroup: true },
        { headerName: "Customer GSTIN", field: "Customer_GSTIN", sortable: true, filter: "agTextColumnFilter", enableRowGroup: true },
        { headerName: "Customer Name", field: "Customer_Name", sortable: true, filter: "agTextColumnFilter", enableRowGroup: true },
        { headerName: "Transaction Type", field: "Transaction_Type", sortable: true, filter: true, enableRowGroup: true },
        { headerName: "Transaction Amount", field: "Transaction_Amount", sortable: true, filter: "agNumberColumnFilter", enableRowGroup: true },
        { headerName: "Booking GST", field: "Booking_GST", sortable: true, filter: "agNumberColumnFilter", enableRowGroup: true },
        { headerName: "K3", field: "K3", sortable: true, filter: "agNumberColumnFilter", enableRowGroup: true },
        { headerName: "Calculated GST", field: "Calculated GST", sortable: true, filter: "agNumberColumnFilter", enableRowGroup: true },
        { headerName: "Location", field: "Location", sortable: true, filter: "agTextColumnFilter", enableRowGroup: true },
        { headerName: "Origin", field: "Origin", sortable: true, filter: true, enableRowGroup: true },
        { headerName: "Agency Invoice Number", field: "Agency_Invoice_Number", sortable: true, filter: "agNumberColumnFilter", enableRowGroup: true },
        { headerName: "Agency Name", field: "Agency_Name", sortable: true, filter: "agTextColumnFilter", enableRowGroup: true },
        {
            headerName: "Invoice Number for Reference",
            field: "Invoice Number for Reference",
            sortable: true,
            filter: "agTextColumnFilter",
            enableRowGroup: true,
            valueFormatter: (params) => {
                if (!params.value) return ""; // Handle empty values
                return params.value.toString().trim(); // Ensure it's treated as a string
            },
        },

        {
            headerName: "Credit/Debit Note Number",
            field: "Credit/Debit Note Number", // Ensure exact match
            sortable: true,
            filter: "agTextColumnFilter",
            enableRowGroup: true,
            valueFormatter: (params) => {
                if (!params.value) return ""; // Handle empty values
                return params.value.toString().trim(); // Ensure proper formatting
            },
        },
        {
            headerName: "Credit/Debit Note Date",
            field: "Credit/Debit Note Date", // Ensure exact match
            sortable: true,
            filter: "agDateColumnFilter",
            enableRowGroup: true,
            formatProps: {
                dateFormat: "dd-mm-yy"
            },

        },
        { headerName: "Invoice Taxable", field: "Invoice Taxable", sortable: true, filter: "agNumberColumnFilter", enableRowGroup: true },
        { headerName: "Invoice CGST", field: "Invoice CGST", sortable: true, filter: "agNumberColumnFilter", enableRowGroup: true },
        { headerName: "Invoice SGST", field: "Invoice SGST", sortable: true, filter: "agNumberColumnFilter", enableRowGroup: true },
        { headerName: "Invoice IGST", field: "Invoice IGST", sortable: true, filter: "agNumberColumnFilter", enableRowGroup: true },
        { headerName: "Total GST", field: "Total_GST", sortable: true, filter: "agNumberColumnFilter", enableRowGroup: true },
        { headerName: "Total Amount", field: "Total_Amount", sortable: true, filter: "agNumberColumnFilter", enableRowGroup: true },
        {
            headerName: "Customer GSTIN in Invoice",
            field: "Customer_GSTIN in Invoice", // Keep exact match
            sortable: true,
            filter: "agTextColumnFilter",
            enableRowGroup: true,
            valueFormatter: (params) => params.value || "" // Ensure no "undefined"
        },
        {
            headerName: "Supplier GSTIN in Invoice",
            field: "Supplier_GSTIN in Invoice",
            sortable: true,
            filter: "agTextColumnFilter",
            enableRowGroup: true,
            valueFormatter: (params) => params.value || ""
        },

        {
            headerName: "Customer Name in Invoice",
            field: "Customer Name in Invoice",
            sortable: true,
            filter: "agTextColumnFilter",
            enableRowGroup: true,
            valueFormatter: (params) => params.value || ""

        },
        {
            headerName: "Document Type",
            field: "Document_Type",
            sortable: true,
            filter: true,
            enableRowGroup: true,
           formatType:"TEXT"
        },
        
        { headerName: "Invoice Status", field: "Invoice Status", sortable: true, filter: true, enableRowGroup: true },
        { headerName: "Workspace", field: "Workspace", sortable: true, filter: "agTextColumnFilter", enableRowGroup: true },
        { headerName: "Soto Status", field: "Soto Status", sortable: true, filter: true, enableRowGroup: true },
        { headerName: "From", field: "From", sortable: true, filter: "agTextColumnFilter", enableRowGroup: true },
        // {
        //     headerName: "Invoice Link",
        //     field: "Updated Asset Link",
        //     sortable: true,
        //     filter: true,
        //     enableRowGroup: true,
        // },
        {
            headerName: "Invoice",
            field: "Updated Asset Link",
            filter: "agTextColumnFilter",
            //invoiceLink: "html.pdf",
            cellRenderer: ToolParamInvoker,
            formatType: "INVOICE",
            // cellRenderer: ToolParamInvoker,
            // formatProps: {},
            // ...handleCellRenderer(),
            cellRendererParams: (params) => {
                return {
                    label: "View Invoice",
                    handleClick: openSidebarRenderer,
                    //data: { pdfLink: params.data?.["Updated Asset Link"] }
                    data: params.data,
                };
            }
        },

        //{ headerName: "Link Status", field: "Link Status", sortable: true, filter: true, enableRowGroup: true },

        { headerName: "GST Difference", field: "GST Difference", sortable: true, filter: "agNumberColumnFilter", enableRowGroup: true },
    ];
    console.log(columnDefs1, "columnDefs1")
    console.log(rowData, "rowData")

    const columnDefs2 = [
        { headerName: "Login Status", field: "Login Status", sortable: true, filter: true, enableRowGroup: true },
        {
            headerName: "SSR Status",
            field: "SSR Status",
            sortable: true,
            filter: true,
            enableRowGroup: true,
            valueFormatter: (params) => {
                console.log("SSR Status:", params.value); // Debugging
                return params.value || ""; // Handle missing values
            },
        },

        { headerName: "Unique Column", field: "Unique_Column", sortable: true, filter: "agTextColumnFilter", enableRowGroup: true },
        { headerName: "Scraper Type", field: "Scraper type", sortable: true, filter: true, enableRowGroup: true },
        { headerName: "Email Access (y/n)", field: "Email Access y/n", sortable: true, filter: true, enableRowGroup: true },
        { headerName: "Workspace", field: "Workspace", sortable: true, filter: "agTextColumnFilter", enableRowGroup: true },
        { headerName: "Vendor", field: "Vendor", sortable: true, filter: "agTextColumnFilter", enableRowGroup: true },
        { headerName: "Transaction Type", field: "Transaction_Type", sortable: true, filter: true, enableRowGroup: true },
        {
            headerName: "Note Type",
            field: "Note Type",
            sortable: true,
            filter: true,
            enableRowGroup: true,
            valueFormatter: (params) => {
                console.log("Note Type:", params.value); // Debugging
                return params.value || ""; // Handle missing values
            },
        },

        { headerName: "Type", field: "Type", sortable: true, filter: true, enableRowGroup: true },
        { headerName: "Financial Year", field: "Financial_Year", sortable: true, filter: true, enableRowGroup: true },
        { headerName: "Invoice/Note Number (Master)", field: "Invoice/Note_Number-Master", sortable: true, filter: true, enableRowGroup: true },
        { headerName: "2A/2B Invoice/Note Number", field: "2A/2B-Invoice/Note_Number", sortable: true, filter: true, enableRowGroup: true },
        {
            headerName: "Invoice Invoice/Note Number",
            field: "Invoice-Invoice/Note_Number",
            sortable: true,
            filter: true,
            enableRowGroup: true,
            valueFormatter: (params) => {
                return params.value || ""; // Handle missing values
            },
        },

        { headerName: "Status 2A/2B Invoice/Note Number", field: "Status-2A/2B-Invoice/Note_Number", sortable: true, filter: true, enableRowGroup: true },
        {
            headerName: "2A/2B Original Invoice Number",
            field: "2A/2B-Original Invoice Number",
            sortable: true,
            filter: true,
            enableRowGroup: true,
            valueFormatter: (params) => {
                return params.value || ""; // Handle missing values
            },
        },

        {
            headerName: "2A/2B Original Invoice Date",
            field: "2A/2B-Original Invoice Date",
            sortable: true,
            filter: true,
            enableRowGroup: true,
            valueFormatter: (params) => {
                return params.value || ""; // Handle missing values
            },
        },
        {
            headerName: "Invoice Original Invoice Number",
            field: "Invoice-Original Invoice Number",
            sortable: true,
            filter: true,
            enableRowGroup: true,
            valueFormatter: (params) => {
                return params.value || ""; // Handle missing values
            },
        },
        {
            headerName: "Invoice Original Invoice Date",
            field: "Invoice-Original Invoice Date",
            sortable: true,
            filter: true,
            enableRowGroup: true,
            valueFormatter: (params) => {
                return params.value || ""; // Handle missing values
            },
        },
        {
            headerName: "Invoice/Note Date (Master)",
            field: "Invoice/Note_Date-Master",
            sortable: true,
            filter: "agDateColumnFilter",
            enableRowGroup: true,
            formatType: "DATE",
            formatProps: {
                dateFormat: "DD-MM-YYYY"
            },
        },

        {
            headerName: "2A/2B Invoice/Note Date",
            field: "2A/2B-Invoice/Note_Date",
            sortable: true,
            filter: true,
            enableRowGroup: true,
            formatType:"DATE",
            formatProps:{
                dateFormat:"DD-MM-YYYY"
            },
        },
        {
            headerName: "Invoice Invoice/Note Date",
            field: "Invoice-Invoice/Note_Date",
            sortable: true,
            filter: "agDateColumnFilter",
            enableRowGroup: true,
            valueFormatter: (params) => {
                if (!params.value) return ""; // Handle empty values
                const dateParts = params.value.split("/"); // Assuming the format is MM/DD/YYYY
                if (dateParts.length === 3) {
                    const [month, day, year] = dateParts;
                    return `${day}-${month}-${year.slice(-2)}`; // Convert to DD-MM-YY format
                }
                return params.value; // Fallback for unexpected formats
            },
        },

        { headerName: "Status 2A/2B Invoice/Note Date", field: "Status-2A/2B-Invoice/Note_Date", sortable: true, filter: true, enableRowGroup: true },
        { headerName: "Supplier GSTIN (Master)", field: "Supplier_GSTIN-Master", sortable: true, filter: "agTextColumnFilter", enableRowGroup: true },
        { headerName: "2A/2B Supplier GSTIN", field: "2A/2B-Supplier_GSTIN", sortable: true, filter: "agTextColumnFilter", enableRowGroup: true },
        {
            headerName: "Invoice Supplier GSTIN",
            field: "Invoice-Supplier_GSTIN",
            sortable: true,
            filter: "agTextColumnFilter",
            enableRowGroup: true,
            valueFormatter: (params) => {
                return params.value || ""; // Handle missing values
            },
        },

        { headerName: "Status 2A/2B Supplier GSTIN", field: "Status-2A/2B-Supplier_GSTIN", sortable: true, filter: true, enableRowGroup: true },
        { headerName: "Customer GSTIN (Master)", field: "Customer_GSTIN-Master", sortable: true, filter: "agTextColumnFilter", enableRowGroup: true },
        { headerName: "2A/2B Customer GSTIN", field: "2A/2B-Customer_GSTIN", sortable: true, filter: "agTextColumnFilter", enableRowGroup: true },
        {
            headerName: "Invoice Customer GSTIN",
            field: "Invoice-Customer_GSTIN",
            sortable: true,
            filter: "agTextColumnFilter",
            enableRowGroup: true,
            valueFormatter: (params) => {
                return params.value || ""; // Handle missing values
            },
        },
        { headerName: "Status 2A/2B Customer GSTIN", field: "Status-2A/2B-Customer_GSTIN", sortable: true, filter: true, enableRowGroup: true },
        {
            headerName: "HSN SAC Code",
            field: "HSN_SAC_Code",
            sortable: true,
            filter: "agNumberColumnFilter",
            enableRowGroup: true,
            valueFormatter: (params) => {
                return params.value || ""; // Handle missing values
            },
        },
        { headerName: "2A/2B Taxable", field: "2A/2B-Taxable", sortable: true, filter: "agNumberColumnFilter", enableRowGroup: true },
        {
            headerName: "Invoice Taxable",
            field: "Invoice-Taxable",
            sortable: true,
            filter: "agNumberColumnFilter",
            enableRowGroup: true,
            valueFormatter: (params) => {
                return params.value || ""; // Handle missing values
            },
        },
        {
            headerName: "Taxable Difference",
            field: "Taxable Difference",
            sortable: true,
            filter: "agNumberColumnFilter",
            enableRowGroup: true,
        },
        { headerName: "2A/2B CGST", field: "2A/2B-CGST", sortable: true, filter: "agNumberColumnFilter", enableRowGroup: true },
        { headerName: "Invoice CGST", field: "Invoice-CGST", sortable: true, filter: "agNumberColumnFilter", enableRowGroup: true },
        { headerName: "2A/2B SGST", field: "2A/2B-SGST", sortable: true, filter: "agNumberColumnFilter", enableRowGroup: true },
        { headerName: "Invoice SGST", field: "Invoice-SGST", sortable: true, filter: "agNumberColumnFilter", enableRowGroup: true },
        { headerName: "2A/2B IGST", field: "2A/2B-IGST", sortable: true, filter: "agNumberColumnFilter", enableRowGroup: true },
        { headerName: "Invoice IGST", field: "Invoice-IGST", sortable: true, filter: "agNumberColumnFilter", enableRowGroup: true },
        { headerName: "Total GST (Master)", field: "Total_GST-Master", sortable: true, filter: "agNumberColumnFilter", enableRowGroup: true },
        { headerName: "2A/2B Total GST", field: "2A/2B-Total_GST", sortable: true, filter: "agNumberColumnFilter", enableRowGroup: true },
        { headerName: "Invoice Total GST", field: "Invoice-Total_GST", sortable: true, filter: "agNumberColumnFilter", enableRowGroup: true },
        { headerName: "GST Difference", field: "GST Difference", sortable: true, filter: "agNumberColumnFilter", enableRowGroup: true },
        { headerName: "2A/2B Total Amount", field: "2A/2B-Total_Amount", sortable: true, filter: "agNumberColumnFilter", enableRowGroup: true },
        { headerName: "Invoice Total Amount", field: "Invoice-Total_Amount", sortable: true, filter: "agNumberColumnFilter", enableRowGroup: true },
        { headerName: "Amount Difference", field: "Amount Difference", sortable: true, filter: "agNumberColumnFilter", enableRowGroup: true },
        {
            headerName: "Booking Status",
            field: "Booking Status",
            sortable: true,
            filter: true,
            enableRowGroup: true,
            valueFormatter: (params) => {
                return params.value || ""; // Handle missing values
            },
        },
        { headerName: "2A / 2B Status", field: "2A / 2B Status", sortable: true, filter: true, enableRowGroup: true },
        { headerName: "GSTR2 Status", field: "GSTR2- Status", sortable: true, filter: true, enableRowGroup: true },
        { headerName: "Invoice Status", field: "Invoice Status", sortable: true, filter: true, enableRowGroup: true },
        { headerName: "Claimed Status", field: "Claimed Status", sortable: true, filter: true, enableRowGroup: true },
        { headerName: "Original Invoice Status", field: "Orginal Invoice Status", sortable: true, filter: true, enableRowGroup: true },
        {
            headerName: "Ticket/PNR",
            field: "Ticket/PNR",
            sortable: true,
            filter: "agTextColumnFilter",
            enableRowGroup: true,
            valueFormatter: (params) => {
                return params.value || ""; // Handle missing values
            },
        },
        {
            headerName: "Agency Invoice Number",
            field: "Agency_Invoice_Number",
            sortable: true,
            filter: "agTextColumnFilter",
            enableRowGroup: true,
            valueFormatter: (params) => {
                return params.value || ""; // Handle missing values
            },
        },
        {
            headerName: "Match Type",
            field: "Match Type",
            sortable: true,
            filter: true,
            enableRowGroup: true,
            valueFormatter: (params) => {
                return params.value || ""; // Handle missing values
            },
        },
        { headerName: "Workspace Active", field: "workspace_active", sortable: true, filter: true, enableRowGroup: true },
        // { headerName: "Invoice Link", 
        //     field: "Invoice_Link", 
        //     sortable: true, 
        //     filter: true, 
        //     enableRowGroup: true 
        // },
        {
            headerName: "Invoice",
            field: "Invoice_Link",
            filter: "agTextColumnFilter",
            //invoiceLink: "html.pdf",
            cellRenderer: ToolParamInvoker,
            formatType: "INVOICE",
            // cellRenderer: ToolParamInvoker,
            // formatProps: {},
            // ...handleCellRenderer(),
            cellRendererParams: (params) => {
                return {
                    label: "View Invoice",
                    handleClick: openSidebarRenderer,
                    //data: { pdfLink: params.data?.["Updated Asset Link"] }
                    data: params.data,
                };
            }
        },

        { headerName: "Rate", field: "Rate", sortable: true, filter: "agNumberColumnFilter", enableRowGroup: true },
        { headerName: "18% Total GST", field: "18%Total_GST", sortable: true, filter: "agNumberColumnFilter", enableRowGroup: true },
        { headerName: "Claimable", field: "Claimable", sortable: true, filter: "agNumberColumnFilter", enableRowGroup: true },
    ];



    const getProcessedDefinitions = () => {
        const columnDefs = activeTab === "2" ? columnDefs2 : columnDefs1;

        return getProcessedDefinition(
            columnDefs,
            "CLIENT",
            setRecoilPdfData,
            userDetails || {}
        );
    };






    return (
        <div>
            <div style={{ height: "100vh" }}>
                <AgTableClient
                    rowData={rowData}
                    //columnDefs={columnDefs}
                    columnDefs={getProcessedDefinitions()}
                    groupPanel
                    //enablePDFViewer
                    pdfV2
                    pdfDataKey="Updated Asset Link"
                    ref={gridRef}
                />
            </div>
        </div>
    );
   
  };
export default DemoPage;
