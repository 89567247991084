// import { colorPicker } from "app/utils/helpers/color.helper";
import styled from "styled-components";
const variants = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  caption: "p",
  button: "p",
  body: "p",
  title: "p",
  xs: "small",
};

const fontSizes = {
  h1: "96px",
  h2: "60px",
  h3: "48px",
  h4: "34px",
  h5: "24px",
  h6: "20px",
  caption: "14px",
  button: "16px",
  body: "16px",
  title: "26px",
  xs: "12px",
  medium: "18px",
};

const Title = styled[variants["body"]]`
  font-size: ${(props) => fontSizes[props.variant || "body"]};
  text-align: left;
  color: ${(props) => props.color || "black"};
  font-weight: ${(props) => props.weight || 400};
  overflow: ${(props) => (props.wrapped ? "hidden" : "")};
  text-overflow: ${(props) => (props.wrapped ? "ellipsis" : "")};
  white-space: ${(props) => (props.wrapped ? "nowrap" : "")};
`;
export default function Typography(props) {
  return (
    <Title onClick={props.onClick} style={{ ...props.style }} {...props}>
      {props.children}
    </Title>
  );
}
