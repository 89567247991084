import { apiGet, apiPost, apiPut } from "app/services/apiServices";
import { AgTableClient } from "app/shared/AgTable";
import {
  forwardRef,
  useEffect,
  useId,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import Loader from "app/shared/Loader";
import { PlusOutlined, ExportOutlined, CopyOutlined } from "@ant-design/icons";
import CircleIcon from "@mui/icons-material/Circle";
import {
  Avatar,
  Button,
  Empty,
  Modal,
  Popconfirm,
  Popover,
  Spin,
  Tabs,
  Tag,
  message,
} from "antd";
import SearchInput from "app/shared/SearchInput";
import Typography from "app/shared/Typography";
import "../../Settings.scss";
import PageHeader from "app/shared/PageHeader";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import TableViewSelector from "app/shared/TableViewSelector";
import WorkspacesOutlinedIcon from "@mui/icons-material/WorkspacesOutlined";
import {
  API_ENDPOINT_SETTING_MEMBER_REMOVE_FROM_WORKSPACE,
  API_ENDPOINT_SETTING_MEMBERS_LIST,
  API_ENDPOINT_SETTING_MEMBERS_LIST_ALL,
  API_ENDPOINT_SETTING_WORKSPACE_LIST,
} from "../../settings.constants";
import { colorPicker } from "app/utils/color.helper";
import TextField from "app/shared/TextField";
import InviteMember from "../InviteMember";
import UpdateMember from "../UpdateMember";
import InviteMemberV2 from "../InviteMemberV2";
import { useNavigate } from "react-router-dom";
const MemberSetting = forwardRef((props: any, ref: any) => {
  const [tableData, setTableData] = useState([]);
  const [columnDefs, setColumnDefs] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showModalFor, setShowModalFor] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [removingUser, setRemovingUser] = useState("");
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const navigate = useNavigate();
  const gridRef = useRef<any>(null);
  const copyDataToBoard = (text: string) => {
    navigator?.clipboard?.writeText(text);
    messageApi.open({
      type: "success",
      content: "Copied",
    });
  };

  const handleMoveToEditPage = async (workspaceInfo: any) => {
    let newWorksapceData = { ...userDetails, currentWorkspace: workspaceInfo };
    setUserDetails(JSON.parse(JSON.stringify(newWorksapceData)));
    localStorage.setItem("currentWorkspace", workspaceInfo.id);
    navigate("/settings/workspace/meta");
  };

  const initialColDefs = [
    {
      field: "name",
      headerName: "Name",
      enableRowGroup: true,
      minWidth: 200,
      cellRenderer: (params: any) => (
        <div
          onClick={() => {
            setShowModalFor("UPDATE");
            setShowModal(true);
            setSelectedUser(params.data);
          }}
        >
          {params.data?.name}
        </div>
      ),
    },

    {
      field: "email",
      headerName: "Email",
      enableRowGroup: true,
      minWidth: 200,
    },

    {
      field: "account_type",
      headerName: "Access Level",
      enableRowGroup: true,
      minWidth: 200,
      cellRenderer: (params: any) => {
        return (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Tag
              color={params.data?.account_type === "PARTNER" ? "green" : "blue"}
              style={{ display: "flex", alignItems: "center" }}
            >
              {(params.data?.account_type === "PARTNER"
                ? "PARTNER LEVEL"
                : "CLIENT LEVEL") || "CLIENT LEVEL"}
            </Tag>
          </div>
        );
      },
    },
    {
      field: "role",
      headerName: "Workspace Role",
      enableRowGroup: true,
      minWidth: 200,
      cellRenderer: (params: any) => {
        return (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Tag color="blue" style={{ display: "flex", alignItems: "center" }}>
              {(params.data?.workspacerole === "GENERAL" ? "USER" : "ADMIN") ||
                "USER"}
            </Tag>
          </div>
        );
      },
    },
  ];

  const removeUserFormWorkspace = async (userId: string) => {
    setRemovingUser(userId);
    const response = await apiPut(
      API_ENDPOINT_SETTING_MEMBER_REMOVE_FROM_WORKSPACE + userId,
      {
        type: props.noHeader ? "SINGLE" : "ALL",
      }
    );

    if (response.status) {
      message.success("User removed from workspace");
      const result = tableData.filter((item: any) => item.id !== userId);
      setTableData(result); // Update state with filtered data
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setRemovingUser("");
  };

  useEffect(() => {
    fetchUsersList();
  }, [userDetails.currentWorkspace]);

  useImperativeHandle(ref, () => ({
    fetchUsersList() {
      fetchUsersList();
    },
  }));

  const fetchUsersList = async () => {
    setLoading(true);
    const response = await apiGet(
      props.useWorkspace
        ? API_ENDPOINT_SETTING_MEMBERS_LIST
        : API_ENDPOINT_SETTING_MEMBERS_LIST_ALL
    );

    if (response.status) {
      setTableData(response.data);
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setLoading(false);
  };

  const handleMemberShipSuccess = () => {
    setShowModal(false);
    fetchUsersList();
  };

  const handleSearchWorkspace = (query: string) => {
    if (gridRef.current && gridRef.current.exportDataToExcel) {
      // Call the child component's function
      gridRef.current.onTableSearch({ target: { value: query } });
    }
  };

  console.log("userDetails:", userDetails);
  return (
    <div className="ScreenContainer">
      {props.noHeader ? null : (
        <PageHeader
          title="Members"
          rightActions={
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: 12 }}>
                <SearchInput
                  placeholder="Search members"
                  onSearch={handleSearchWorkspace}
                />
              </div>
              {userDetails.currentWorkspace.role === "ADMIN" ||
              userDetails.account_type === "PARTNER" ? (
                <Button
                  type="primary"
                  size="small"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setShowModalFor("CREATE");
                    setShowModal(true);
                  }}
                >
                  Add Members
                </Button>
              ) : null}
            </div>
          }
        />
      )}
      <div className="TableContainer">
        {isLoading ? (
          <Loader />
        ) : tableData.length === 0 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <Empty />
          </div>
        ) : userDetails.account_type === "CLIENT" &&
          userDetails.currentWorkspace.role === "GENERAL" ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <Empty description="You don't have permission to view member of this workspace" />
          </div>
        ) : (
          <AgTableClient
            // @ts-ignore
            rowData={tableData}
            columnDefs={[
              ...initialColDefs,
              ...(userDetails?.email?.includes("kgrp.in") ||
              userDetails?.email?.includes("finkraft.ai")
                ? [
                    {
                      field: "password",
                      headerName: "Temporary Password",
                      enableRowGroup: true,
                      minWidth: 200,
                      cellRenderer: (params: any) => {
                        return params?.data?.password ? (
                          <div
                            style={{
                              height: "100%",
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Tag
                              style={{ display: "flex", alignItems: "center" }}
                              onClick={() =>
                                copyDataToBoard(params?.data?.password)
                              }
                            >
                              <CopyOutlined />
                              <Typography
                                variant="xs"
                                style={{
                                  color: colorPicker("neutral.700"),
                                  marginLeft: 4,
                                }}
                              >
                                Copy Password
                              </Typography>
                            </Tag>
                          </div>
                        ) : null;
                      },
                    },
                  ]
                : []),
              ...(userDetails.currentWorkspace?.role !== ""
                ? [
                    {
                      field: "action",
                      headerName: "Action",
                      cellRenderer: (params: any) => {
                        return (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <Popconfirm
                              title="Remove user from workspace"
                              description="Are you sure want to remove?"
                              onConfirm={() =>
                                removeUserFormWorkspace(params.data?.id)
                              }
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button
                                size="small"
                                danger
                                loading={removingUser === params.data?.id}
                              >
                                Remove
                              </Button>
                            </Popconfirm>
                          </div>
                        );
                      },
                    },
                  ]
                : []),
            ]}
            groupPanel
            // onRowSaved={handleRowEdit}
            ref={gridRef}
            footer={false}
            autoResize
            showStatusBar
          />
        )}
      </div>

      <Modal
        title={showModalFor === "CREATE" ? "Add Members" : "Edit Member Detail"}
        open={showModal}
        footer={false}
        onCancel={() => setShowModal(false)}
        width={800}
      >
        {showModalFor === "CREATE" ? (
          <InviteMemberV2
            onClose={() => setShowModal(false)}
            onSuccess={handleMemberShipSuccess}
          />
        ) : (
          <UpdateMember
            data={selectedUser}
            success={() => {
              fetchUsersList();
              setShowModalFor("");
              setShowModal(false);
            }}
            onClose={() => setShowModal(false)}
          />
        )}
      </Modal>
      {contextHolder}
    </div>
  );
});

export default MemberSetting;
