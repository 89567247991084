import TextField from "app/shared/TextField";
import React, { useEffect, useRef, useState } from "react";
import "./InviteMember.scss";
import {
  Avatar,
  Button,
  Checkbox,
  Input,
  Popover,
  Select,
  Tag,
  message,
} from "antd";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import { cursorTo } from "readline";
import {
  EditOutlined,
  CloseOutlined,
  DeleteTwoTone,
  ClusterOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import MemberSearch from "app/shared/MemberSearch";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { apiPost } from "app/services/apiServices";
import { API_ENDPOINT_SETTING_INVITE_MEMBER } from "../../settings.constants";
import { AgTableClient } from "app/shared/AgTable";
import SearchInput from "app/shared/SearchInput";
const OPTIONS = [
  "Apples",
  "Nails",
  "Bananas",
  "Helsicopters",
  "Appales",
  "Naisls",
  "Bansanas",
  "Heliacopters",
];
// const filteredOptions = OPTIONS.filter((o) => !selectedItems.includes(o));

export default function InviteMemberV2(props: any) {
  const [members, setMembers] = useState<any[]>([]);
  const [userEmail, setUserEmail] = useState("");
  const [accountType, setAccountType] = useState("CLIENT");
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [showEdit, setShowEdit] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [allowedDomains, setAllowedDomains] = useState<any[]>([]);
  const [designation, setDesignation] = useState("");
  const [filteredWorkspaces, setFilteredWorkspaces] = useState<any[]>([]);
  const [userName, setUserName] = useState("");
  let isUserNotPartnerAdmin =
    userDetails.account_type !== "PARTNER" &&
    userDetails.currentWorkspace.role !== "ADMIN";
  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const gridRef: any = useRef();

  const handleInviteMember = async () => {
    if (userEmail.includes(",")) {
      message.error("Cannot invite multiple user at once");
      return;
    }
    let payload: any = {
      email: userEmail,
      name: userName,
      account_type: isUserNotPartnerAdmin ? "CLIENT" : accountType,
      workspaces: selectedWorkspaces,
    };
    setLoading(true);

    if (!userEmail || selectedWorkspaces.length === 0) {
      messageApi.error({
        type: "error",
        content: "Please provide information for invite",
      });
      setLoading(false);
      return;
    }
    const response = await apiPost(API_ENDPOINT_SETTING_INVITE_MEMBER, payload);
    if (response.status) {
      messageApi.success({
        type: "success",
        content: response.message,
      });
      setAccountType("");
      setUserEmail("");
      setUserName("");
      setSelectedWorkspaces([]);
      props.onSuccess && props.onSuccess();
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (accountType === "PARTNER") {
      setFilteredWorkspaces(
        (userDetails.workspaces || []).filter(
          (item: any) => item.entity_type === "INTERMEDIARY"
        )
      );
    } else {
      let newFilteredWorkspace = (userDetails.workspaces || []).filter(
        (item: any) => item.entity_type !== "INTERMEDIARY"
      );
      setFilteredWorkspaces(
        (newFilteredWorkspace || []).map((item: any) => ({
          ...item, // Spread the existing properties of the object
          role: "ADMIN", // Add the new key-value pair
        }))
      );
    }
  }, [accountType]);

  const handleOptionSelect = (value: any, fullObj: any) => {
    let emailDomain = fullObj.email.split("@")[1];
    // if (allowedDomains.includes(emailDomain) || allowedDomains.length === 0) {
    let memberAccess = {
      ...fullObj,
      workspaces: selectedWorkspaces,
      role: "GENERAL",
    };
    setMembers([...members, memberAccess]);
    // } else {
    //   messageApi.error({
    //     type: "error",
    //     content: `Domain not allowed for ${emailDomain}`,
    //   });
    // }
  };

  const handleRemoveMember = (id: string) => {
    const updatedData = members.filter((item) => item.id !== id);
    setMembers(updatedData);
  };

  const { Option } = Select;

  const handleSelectWorkspace = (spaceInfo: any, fullObj: any) => {
    console.log("workspace info", fullObj);

    setAllowedDomains(fullObj.length > 0 ? fullObj?.[0]?.domain : []);
    setSelectedWorkspaces(spaceInfo);
  };

  const getFilteredWorkspaces = () => {
    let filtered: any = [];
    (userDetails.workspaces || []).forEach((workspaceInfo: any) => {
      if (workspaceInfo.role === "ADMIN" || userDetails.user_type_id === 1) {
        filtered.push(workspaceInfo);
      }
    });
    return filtered;
  };

  const handleUpdateInviteRole = (emailId: string, roleId: string) => {
    setMembers((prevItems) =>
      prevItems.map((item) =>
        item.email === emailId ? { ...item, role: roleId } : item
      )
    );
  };

  const getWorkspaceFiltered = () => {
    if (accountType === "PARTNER") {
      return (userDetails.workspaces || []).filter(
        (item: any) => item.entity_type === "INTERMEDIARY"
      );
    } else {
      return userDetails.workspaces;
    }
  };
  const handleAddRemoveWorkspace = (workspaceInfo: any) => {
    setSelectedWorkspaces((prevWorkspaces: any) => {
      const isExist = prevWorkspaces.findIndex(
        (item: any) => item.id === workspaceInfo.id
      );

      if (isExist === -1) {
        // The workspace doesn't exist in the array
        return [...prevWorkspaces, workspaceInfo]; // Add the new workspace
      } else {
        // The workspace exists in the array
        return prevWorkspaces.filter(
          (item: any) => item.id !== workspaceInfo.id
        );
      }

      // Return the array unchanged if no operation was performed
    });
  };

  const handleUpdateRoleForWorkspace = (workspaceInfo: any, role: string) => {
    setSelectedWorkspaces((prevWorkspaces: any) => {
      const isExist = prevWorkspaces.findIndex(
        (item: any) => item.id === workspaceInfo.id
      );

      if (isExist === -1) {
        // The workspace doesn't exist in the array, add it with the new role
        return [...prevWorkspaces, { ...workspaceInfo, role }];
      } else {
        // The workspace exists, update the role
        return prevWorkspaces.map((item: any) =>
          item.id === workspaceInfo.id ? { ...item, role } : item
        );
      }
    });
  };

  const handleSearchWorkspace = (searchQuery: any) => {
    if (searchQuery.length === 0) {
      if (accountType === "PARTNER") {
        setFilteredWorkspaces(
          (userDetails.workspaces || []).filter(
            (item: any) => item.entity_type === "INTERMEDIARY"
          )
        );
      } else {
        setFilteredWorkspaces(
          (userDetails.workspaces || []).filter(
            (item: any) => item.entity_type !== "INTERMEDIARY"
          )
        );
      }
    } else {
      if (accountType === "PARTNER") {
        setFilteredWorkspaces(
          (userDetails.workspaces || []).filter(
            (item: any) =>
              item.entity_type === "INTERMEDIARY" &&
              item.name?.toLowerCase().includes(searchQuery.toLowerCase())
          )
        );
      } else {
        setFilteredWorkspaces(
          (userDetails.workspaces || []).filter(
            (item: any) =>
              item.entity_type !== "INTERMEDIARY" &&
              item.name?.toLowerCase().includes(searchQuery.toLowerCase())
          )
        );
      }
    }
  };

  console.log("selected workspace:", selectedWorkspaces, props.workspaceId);
  return (
    <div className="InviteMember">
      <div className="AddUserContainer" style={{ marginBottom: 8 }}>
        <Typography
          variant="xs"
          style={{ color: colorPicker("neutral.800"), marginBottom: 3 }}
        >
          Name
        </Typography>
        <Input
          placeholder="Name"
          value={userName}
          onChange={(e: any) => setUserName(e.target.value)}
        />
      </div>
      <div className="AddUserContainer">
        <Typography
          variant="xs"
          style={{ color: colorPicker("neutral.800"), marginBottom: 3 }}
        >
          Email
        </Typography>
        <Input
          type="email"
          placeholder="Email"
          value={userEmail}
          onChange={(e: any) => setUserEmail(e.target.value)}
        />
      </div>
      {userDetails.currentWorkspace?.role === "ADMIN" &&
      userDetails.account_type === "PARTNER" ? (
        <div>
          <Select
            style={{ width: "100%", marginTop: 12 }}
            onChange={(item: any) => setAccountType(item)}
            placeholder="Account Type"
          >
            <Select.Option value="PARTNER">Partner Level</Select.Option>
            <Select.Option value="CLIENT">Client Level</Select.Option>
          </Select>
        </div>
      ) : null}
      <Typography variant="xs" style={{ marginTop: 16 }}>
        Selected{" "}
        <b>
          {selectedWorkspaces.length > 0
            ? `(${selectedWorkspaces.length})`
            : null}
        </b>{" "}
        workspaces from below{" "}
      </Typography>
      <div>
        <SearchInput
          onSearch={handleSearchWorkspace}
          height={32}
          placeholder="Search Workspace"
        />
      </div>
      {userEmail ? (
        <div
          style={{
            height: 300,
            width: "100%",
            marginTop: 2,
            overflowY: "auto",
          }}
        >
          <div>
            {(filteredWorkspaces || []).map((item: any, key: any) => {
              return (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "50%",
                    }}
                  >
                    <Checkbox
                      checked={selectedWorkspaces.find(
                        (workspaceInfo: any) => workspaceInfo.id === item.id
                      )}
                      onChange={() => handleAddRemoveWorkspace(item)}
                    />
                    <Typography wrapped style={{ marginLeft: 4 }} variant="xs">
                      {item.name}
                    </Typography>
                  </div>
                  <div>
                    <Select
                      style={{ width: 160, marginTop: 12 }}
                      onChange={(roleInfo: any) =>
                        handleUpdateRoleForWorkspace(item, roleInfo)
                      }
                      placeholder="Workspace Role"
                      size="small"
                      defaultValue={"ADMIN"}
                    >
                      <Select.Option value="ADMIN">Admin</Select.Option>
                      <Select.Option value="GENERAL">User</Select.Option>
                    </Select>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}

      <div className="BottomAction" style={{ marginTop: 16 }}>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button
          type="primary"
          style={{ marginLeft: 6 }}
          onClick={handleInviteMember}
          loading={isLoading}
        >
          Add
        </Button>
      </div>
      {contextHolder}
    </div>
  );
}
