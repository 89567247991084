import { useCallback, memo } from "react";
import React from "react";

const ToolParamInvoker = (params: any) => {
  const { value, handleClick, label, data, colDef } = params;
  console.log("Invoice Data:", data);
  console.log("Handle Click in ToolParamInvoker:", handleClick);
  

  // Get the field dynamically from colDef
  const fieldKey = colDef?.field;
  //const invoiceLink = data?.[fieldKey];
  const invoiceLink = data?.[colDef?.field] || "";
  console.log("Field Key:", fieldKey);
console.log("Data Object:", data);
console.log("Extracted Invoice Link:", invoiceLink);



  const onLinkClick = useCallback(
    (e: any) => {
      e.stopPropagation();
      console.log(handleClick,"handleClick")
      if (handleClick && invoiceLink) {
        handleClick(params, { pdfData: invoiceLink });
      }
    },
    [handleClick, params, invoiceLink]
  );




  return (
    <div onClick={onLinkClick} title={value}>
      <div
        role="button"
        className="link-primary text-truncate"
        style={{ cursor: invoiceLink ? "pointer" : "default", color: invoiceLink ? "blue" : "gray" }}
      >
        {invoiceLink ? "View Invoice" : "No Invoice"}
      </div>
    </div>
  );
};

export default memo(ToolParamInvoker);
