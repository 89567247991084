export const TABLE_VIEW_JSON_CONFIG = {
  2: {
    startRow: 0,
    endRow: 500,
    rowGroupCols: [
      {
        id: "vendor_data.enablement_gov.tradeName",
        displayName: "Vendor Name",
        field: "vendor_data.enablement_gov.tradeName",
      },
    ],
    valueCols: [
      {
        id: "Amount_data.totalAmount",
        aggFunc: "sum",
        displayName: "Total Amount",
        field: "Amount_data.totalAmount",
      },
      {
        id: "Amount_data.totalTaxVal",
        aggFunc: "sum",
        displayName: "Total Tax Value",
        field: "Amount_data.totalTaxVal",
      },
    ],
    pivotCols: [],
    pivotMode: false,
    groupKeys: [],
    filterModel: {},
    sortModel: [],
    database: "gstservice",
    table: "vendor_tally",
    workspace: "33ab7bdd-609b-4551-90b8-9becc33ccb7d",
    FollowUp: "All Data",
    filters: {},
    selectedMonth: null,
  },
  3: {
    startRow: 0,
    endRow: 500,
    rowGroupCols: [
      {
        id: "vendor_data.enablement_gov.tradeName",
        displayName: "Vendor Name",
        field: "vendor_data.enablement_gov.tradeName",
      },
    ],
    valueCols: [
      {
        id: "Amount_data.totalAmount",
        aggFunc: "sum",
        displayName: "Total Amount",
        field: "Amount_data.totalAmount",
      },
      {
        id: "Amount_data.totalTaxVal",
        aggFunc: "sum",
        displayName: "Total Tax Value",
        field: "Amount_data.totalTaxVal",
      },
    ],
    pivotCols: [],
    pivotMode: false,
    groupKeys: [],
    filterModel: {},
    sortModel: [],
    database: "gstservice",
    table: "vendor_tally",
    workspace: "33ab7bdd-609b-4551-90b8-9becc33ccb7d",
    FollowUp: "All Data",
    filters: {},
    selectedMonth: null,
  },
};

export const API_ENDPOINT_BHILOSA_VIEWS = `${process.env.REACT_APP_API_URL}/misc/bhilosa/dynamic/views`;
// export const API_ENDPOINT_BHILOSA_VIEWS = `https://stage-apiplatform.finkraftai.com/api/misc/bhilosa/dynamic/views`;
