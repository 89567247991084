import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  message,
  Select,
  Tag,
} from "antd";
import { apiGet, apiPost } from "app/services/apiServices";
import TextField from "app/shared/TextField";
import Typography from "app/shared/Typography";
import { useState } from "react";
import AppLogo from "static/images/Logo.svg";
import {
  ArrowRightOutlined,
  FileOutlined,
  SendOutlined,
  ArrowLeftOutlined
} from "@ant-design/icons";
import { colorPicker } from "app/utils/color.helper";
import "../../../AirlineCredentialFlow/AirlineCredentialFlow.scss";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { API_ENDPOINT_AIRLINE_CREDENTIAL_INVITE_USER } from "../../gstcredflow.constants";
import { getSubdomain } from "app/utils/generic.helper";

type FieldType = {
  email?: string;
  password?: string;
};

export default function GSTInviteTax(props: any) {
  const [email, setEmail] = useState("");
  const [userRole, setRole] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [userDetails, _] = useRecoilState<any>(userInfo);
  // const handleSendInvite = async () => {
  //   if (!name || !email || !userRole) {
  //     messageApi.error({
  //       type: "error",
  //       content: "Please add user inforamation",
  //     });
      
  //     return;
  //   }
  //   // Get subdomain
  //   const domain = getSubdomain();
  //   console.log("Subdomain is:", domain); 
    
  // if (domain === "mmt") {
  //   navigate("/settings/members");
  // } else if (domain === "bhilosa") {
  //   navigate("/credential/gst/invite/user");
  // }
  //   const payload = {
  //     email: email,
  //     role: userRole,
  //     name: name,
  //     phone: phone,
  //     workspaces: [userDetails?.currentWorkspace?.id],
  //   };
  //   console.log("payload is:", payload);
  //   // const response = await apiPost(API_ENDPOINT_AIRLINE_CREDENTIAL_INVITE_USER, {
  //   //   userList: [payload],
  //   // });
  //   const response = await apiPost(API_ENDPOINT_AIRLINE_CREDENTIAL_INVITE_USER, payload);
  //  console.log("API Response:", response);
  //   if (response.status) {
  //     messageApi.success({
  //       type: "success",
  //       content: "User invited succesfully",
  //     });
  //     setName("");
  //     setPhone("");
  //     setRole("");
  //     setEmail("");
  //     //console.log("Navigating to /flights...");
      
  //   } else {
  //     messageApi.error({
  //       type: "error",
  //       content: "Please add your inforamation",
  //     });
  //   }
  // };

 
  const handleSendInvite = async () => {
    if (!name || !email || !userRole) {
      messageApi.error({
        type: "error",
        content: "Please add user information",
      });
      return;
    }
  
    // Get subdomain
    const domain = getSubdomain();
    console.log("Domain is:", domain);
  
    // const payload = {
    //   email,
    //   role: userRole,
    //   name,
    //   phone,
    //   workspaces: [userDetails?.currentWorkspace?.id],
    // };
    const payload = {
      email,
      name,
      phone,
      workspaces: userDetails?.workspaces?.map((ws: any) => ({
        id: ws?.id,
        role: "ADMIN",  // Always "ADMIN"
      })) ?? [], // Ensures an empty array if workspaces is undefined/null
    };
    
    
  
    console.log("Payload is:", payload);
  
    try {
      const response = await apiPost(API_ENDPOINT_AIRLINE_CREDENTIAL_INVITE_USER, payload);
      console.log("API Response:", response);
  
      // Ensure response status is valid
      if (response?.status === true || response?.status === 200) {
        messageApi.success({
          type: "success",
          content: "User invited successfully",
        });
  
        // Reset fields
        setName("");
        setPhone("");
        setRole("");
        setEmail("");
  
        console.log("Domain before navigating:", domain);
  
        // Delay navigation slightly to ensure message is displayed
        setTimeout(() => {
          if (domain === "mmt") {
            navigate("/settings/members");
          } else if (domain === "bhilosa" || domain === "followup"|| domain === "followup-stage") {
            navigate("/vendorfollowup/gstintegration");
          }
        }, 500); // Small delay to ensure message shows
  
        return; // Exit function after navigation setup
      }
  
      // Handle API failure
      messageApi.error({
        type: "error",
        content: "Failed to invite user. Please try again.",
      });
  
    } catch (error) {
      console.error("API Error:", error);
      messageApi.error({
        type: "error",
        content: "Something went wrong. Please try again.",
      });
    }
  };
  
  

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="AirlineStart">
      <span style={{ display: 'inline-flex', alignItems: 'center', marginBottom: 16, cursor: 'pointer' }} onClick={handleBack}>
        <ArrowLeftOutlined style={{ fontSize: '14px', marginRight: 2, color: '#0A8394', fontWeight: '400' }} />
        <Typography style={{ marginLeft: '3px', color: '#0A8394', fontFamily: 'Noto Sans', }} >
          Back
        </Typography>
      </span>
      <br />
      <Tag color="cyan">GST Integration</Tag>
      <Typography variant="h5" style={{ marginTop: 12 }}>
        Invite user
      </Typography>

      <div className="FormContainer">
        <div className="fieldGroup">
          <Typography
            variant="xs"
            style={{ marginBottom: 2, color: colorPicker("neutral.700") }}
          >
            Name
          </Typography>
          <Input
            placeholder="Name"
            onChange={(e: any) => setName(e.target.value)}
            value={name}
          />
        </div>
        <div className="fieldGroup">
          <Typography
            variant="xs"
            style={{ marginBottom: 2, color: colorPicker("neutral.700") }}
          >
            Email
          </Typography>
          <Input
            placeholder="Email"
            onChange={(e: any) => setEmail(e.target.value)}
            value={email}
          />
        </div>
        <div className="fieldGroup">
          <Typography
            variant="xs"
            style={{ marginBottom: 2, color: colorPicker("neutral.700") }}
          >
            Contact Number
          </Typography>
          <Input
            placeholder="Contact Number"
            onChange={(e: any) => setPhone(e.target.value)}
            value={phone}
          />
        </div>
        <div className="fieldGroup">
          <Typography
            variant="xs"
            style={{ marginBottom: 2, color: colorPicker("neutral.700") }}
          >
            Role
          </Typography>
          <Input
            placeholder="User Role"
            onChange={(e: any) => setRole(e.target.value)}
            value={userRole}
          />
        </div>

        <Button
          style={{ width: "100%", marginTop: 24 }}
          icon={<SendOutlined />}
          size="large"
          type="primary"
          onClick={handleSendInvite}
          //onClick={() =>  navigate("/credential/gst/start")}
        >
          Send Invite
        </Button>
      </div>
      {contextHolder}
    </div>
  );
}
