import React from "react";
import { CloseOutlined } from "@ant-design/icons"

interface ConfirmationPopupProps {
    onClose: () => void;
    onConfirm: () => void;
    totalCount: number; //
}

const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({ onClose, onConfirm,totalCount }) => {
    return (
        <div className="popup-overlay" onClick={onClose}>
            <div className="popup-container" onClick={(e) => e.stopPropagation()}>
                {/* Close Button */}
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <CloseOutlined
                        style={{
                            color: "#0a8394",
                            backgroundColor: "white",
                            cursor: "pointer" // Make it clickable
                        }}
                        onClick={onClose}
                    />
                </div>
                <div className="popup-content">
                    <p>You are about to send a follow-up request to your vendor(s).</p>
                <p>Total Count: {totalCount}</p> 
                <p>Are you sure you want to proceed?</p>
                    <div style={{ display: 'flex', gap:"2px" ,justifyContent: 'center', alignItems: 'center'}}>
                        <button style={{ width: '60px' }} onClick={onConfirm}>yes</button>
                        <button style={{ width: '60px' }} onClick={onClose}>no</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationPopup;