// import TextField from "app/shared/TextField";
// import React, { useState } from "react";
// import "./CreateWorkspace.scss";
// import { Avatar, Button, Popover, Select, Tag, message } from "antd";
// import Typography from "app/shared/Typography";
// import { colorPicker } from "app/utils/color.helper";
// import { cursorTo } from "readline";
// import {
//   EditOutlined,
//   CloseOutlined,
//   ArrowRightOutlined,
// } from "@ant-design/icons";
// import MemberSearch from "app/shared/MemberSearch";
// import { useRecoilState } from "recoil";
// import { userInfo } from "app/config/States/users";
// import { apiGet, apiPost } from "app/services/apiServices";
// import { API_ENDPOINT_SETTING_INVITE_MEMBER } from "../../settings.constants";
// import { API_ENDPOINT_ONBOARDING_CREATE_WORKSPACE } from "app/scenes/Onboarding/onboarding.constants";
// import AddBusiness from "app/scenes/Onboarding/components/AddBusiness";
// import { useNavigate } from "react-router-dom";
// import { API_ENDPOINT_GET_USER_INFO } from "app/scenes/Auth/auth.constants";
// import DynamicChipInput from "app/shared/DynamicChipInput";
// import { MarginTwoTone } from "@mui/icons-material";

// export default function CreateWorkspace(props: any) {
//   const [workspaceName, setWorkspaceName] = useState("");
//   const [workspaceCreated, setWorkspaceCreated] = useState(false);
//   const [messageApi, contextHolder] = message.useMessage();
//   const [isLoading, setLoading] = useState(false);
//   const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
//   const [allowedDomain, setAllowedDomain] = useState([]);
//   const navigate = useNavigate();
//   const validateEmail = (email: string) => {
//     const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return re.test(email);
//   };

//   const fetchUserInfo = async () => {
//     const response = await apiGet(API_ENDPOINT_GET_USER_INFO);
//     if (response.status) {
//       setUserDetails({
//         ...userDetails,
//         workspaces: response.data.workspaces,
//       });
//     }
//   };

//   const handleCreateWorkspace = async () => {
//     setLoading(true);
//     const response: any = await apiPost(
//       API_ENDPOINT_ONBOARDING_CREATE_WORKSPACE,
//       {
//         name: workspaceName,
//         is_active: "true",
//         domains: allowedDomain,
//       }
//     );
//     if (response.status) {
//       messageApi.success({
//         type: "success",
//         content: response.message,
//       });
//       setWorkspaceName("");
//       setAllowedDomain([]);
//       // setWorkspaceCreated(true);
//       fetchUserInfo();
//       props.onSuccess && props.onSuccess();
//     } else {
//       messageApi.error({
//         type: "error",
//         content: response.message || "Something went wrong",
//       });
//     }
//     setLoading(false);
//   };

//   return (
//     <div className="CreateWorkspace">
//       {workspaceCreated ? (
//         <div
//           style={{
//             display: "flex",
//             flexDirection: "column",
//             justifyContent: "center",
//             alignItems: "center",
//             padding: 28,
//           }}
//         >
//           <Typography variant="h5">
//             Hurry 🎉, you created a new workspace{" "}
//           </Typography>
//           <Typography
//             variant="caption"
//             style={{
//               marginTop: 12,
//               color: colorPicker("primary.800"),
//               cursor: "pointer",
//             }}
//             onClick={() => navigate("/settings/members")}
//           >
//             Start adding members <ArrowRightOutlined />
//           </Typography>
//         </div>
//       ) : (
//         <div className="workspaceBasicInfo">
//           <div style={{ width: "100%" }}>
//             <TextField
//               style={{ height: 40 }}
//               placeholder="Workspace Name (eg Acme Corporation)"
//               onChange={(e: any) => setWorkspaceName(e.target.value)}
//               value={workspaceName}
//               defaultValue={workspaceName}
//             />
//           </div>
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "flex-start",
//               flexDirection: "column",
//               width: "100%",
//               marginTop: 12,
//             }}
//           >
//             <Typography
//               variant="xs"
//               style={{ color: colorPicker("neutral.800"), marginBottom: 6 }}
//             >
//               Allowed Domain
//             </Typography>
//             <DynamicChipInput
//               onChange={(data: any) => setAllowedDomain(data)}
//               emailData={allowedDomain}
//             />
//           </div>

//           <Button
//             size="large"
//             style={{ marginTop: 24 }}
//             type="primary"
//             loading={isLoading}
//             onClick={handleCreateWorkspace}
//           >
//             Create
//           </Button>
//         </div>
//       )}

//       {contextHolder}
//     </div>
//   );
// }




import TextField from "app/shared/TextField";
import React, { useState } from "react";
import "./CreateWorkspace.scss";
import { Avatar, Button, Popover, Select, Tag, message } from "antd";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import { cursorTo } from "readline";
import { Upload } from "antd";
import type { UploadProps } from "antd";
import {
  EditOutlined,
  CloseOutlined,
  ArrowRightOutlined,
  UploadOutlined,
  DownloadOutlined
} from "@ant-design/icons";
import MemberSearch from "app/shared/MemberSearch";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { apiGet, apiPost } from "app/services/apiServices";
import { API_ENDPOINT_SETTING_INVITE_MEMBER } from "../../settings.constants";
import { API_ENDPOINT_ONBOARDING_CREATE_WORKSPACE } from "app/scenes/Onboarding/onboarding.constants";
import AddBusiness from "app/scenes/Onboarding/components/AddBusiness";
import { useNavigate } from "react-router-dom";
import { API_ENDPOINT_GET_USER_INFO } from "app/scenes/Auth/auth.constants";
import DynamicChipInput from "app/shared/DynamicChipInput";
import { MarginTwoTone } from "@mui/icons-material";
import csvtojson from 'csvtojson';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-quartz.css";
import { Modal, theme } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Mixpanel } from "app/config/Mixpanel";
import { API_ENDPOINT_SETTING_WORKSPACE_ADD_WORKSPACE_BULK_UPLOAD } from '../../settings.constants'



export default function CreateWorkspace(props: any) {
  const [workspaceName, setWorkspaceName] = useState("");
  const [workspaceCreated, setWorkspaceCreated] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [allowedDomain, setAllowedDomain] = useState([]);
  const [csvData, setCsvData] = useState<any>(null); // To store the parsed JSON data
  const { token } = theme.useToken();//new
  const [file, setFile] = useState<File | null>(null);//new
  const [fileUploaded, setFileUploaded] = useState(false);//new
  const navigate = useNavigate();
  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const columnDefs = csvData && csvData.length > 0
    ? Object.keys(csvData[0]).map(key => ({
      headerName: key,
      field: key,
      sortable: true,
      filter: true,
      editable: true,
      width: 440
    }))
    : [];

  const onCellValueChanged = (event: any) => {
    const updatedData = event.data;
    console.log("Updated Row Data:", updatedData);

    const updatedDataIndex = event.rowIndex;
    const updatedCsvData = csvData.map((row: any, index: number) =>
      index === updatedDataIndex ? updatedData : row
    );
    setCsvData(updatedCsvData);
    console.log("Updated JSON Data:", updatedCsvData);
  };

  const beforeUploadHandler = async (file: File) => {
    console.log("File received:", file);

    if (file.type !== "text/csv") {
      messageApi.error("Please upload a CSV file.");
      return false;
    }

    // Read the file and parse CSV to JSON
    const reader = new FileReader();
    reader.onload = async () => {
      try {
        const csv = reader.result as string;
        const jsonData = await csvtojson().fromString(csv);
        console.log("Parsed CSV data:", jsonData);
        setCsvData(jsonData); // Set the parsed data to state
        setFileUploaded(true);//new
        //addBulkUpload(workspaceNames);
      } catch (error) {
        console.error("Error parsing CSV:", error);
        messageApi.error("Error parsing the CSV file.");
      }
    };
    reader.readAsText(file); // Read the file content

    return false; // Prevent automatic file upload
  };

  const handleFileChange = (info: any) => {
    setFile(info.file.originFileObj);
  };

  const fetchUserInfo = async () => {
    const response = await apiGet(API_ENDPOINT_GET_USER_INFO);
    if (response.status) {
      setUserDetails({
        ...userDetails,
        workspaces: response.data.workspaces,
      });
    }
  };
  ////new----
  interface WorkspacePayload {
    names: string[]; // Array of workspace names
  }


  const addBulkUpload = async (workspaceNames: string[]) => {
    // const payload: WorkspacePayload = {
    //   names: workspaceNames, // Array of workspace names
    // };


    try {
      const response: any = await apiPost(
        API_ENDPOINT_SETTING_WORKSPACE_ADD_WORKSPACE_BULK_UPLOAD,
        {
          names: workspaceNames,
        }
      );
      console.log(response.data);

      if (response.status) {
        messageApi.success("File uploaded successfully.");
      } else {
        messageApi.error("Something went wrong during the upload.");
      }
    } catch (error: unknown) {
      messageApi.error("Error uploading file");
    }
  };


  const handleCreateWorkspace = async () => {
    setLoading(true);
    const response: any = await apiPost(
      API_ENDPOINT_ONBOARDING_CREATE_WORKSPACE,
      {
        name: workspaceName,
        is_active: "true",
        domains: allowedDomain,
      }
    );
    if (response.status) {
      messageApi.success({
        type: "success",
        content: response.message,
      });
      // const workspaceNames = csvData.map((row: any) => row['Workspace Name']);
      const workspaceNames = csvData ? csvData.map((row: any) => row['Workspace Name']) : [];

      addBulkUpload(workspaceNames);
      setWorkspaceName("");
      setAllowedDomain([]);
      // setWorkspaceCreated(true);
      fetchUserInfo();
      props.onSuccess && props.onSuccess();
    } else {
      messageApi.error({
        type: "error",
        content: response.message || "Something went wrong",
      });
    }
    setLoading(false);
  };

  const tagPlusStyle: React.CSSProperties = {
    height: 22,
    background: token.colorBgContainer,
    borderStyle: "dashed",
  };

  const downloadSampleCredentialCSV = async () => {
    Mixpanel.track("Download Sample CSV", {
      Screen: "Workspace",
    });

    // Define only "ABC" in CSV
    let csvString = `Workspace
Test Workspace1
Test Workspace2
Test Workspace3
Test Workspace4`;

    try {
      // Convert the CSV string to a Blob
      const csvBlob = new Blob([csvString], { type: "text/csv" });

      // Convert the Blob object to a data URL
      const csvDataUrl = URL.createObjectURL(csvBlob);

      // Create a temporary anchor element
      const link = document.createElement("a");
      link.href = csvDataUrl;
      link.setAttribute("download", "workspace_sample.csv");

      // Trigger the download by programmatically clicking the anchor element
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading CSV file:", error);
    }
  };



  return (
    <div className="CreateWorkspace">
      {workspaceCreated ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 28,
          }}
        >
          <Typography variant="h5">
            Hurry 🎉, you created a new workspace{" "}
          </Typography>
          <Typography
            variant="caption"
            style={{
              marginTop: 12,
              color: colorPicker("primary.800"),
              cursor: "pointer",
            }}
            onClick={() => navigate("/settings/members")}
          >
            Start adding members <ArrowRightOutlined />
          </Typography>
        </div>
      ) : (
        <div className="workspaceBasicInfo">
          <div style={{ width: "100%" }}>
            <div onClick={downloadSampleCredentialCSV} 
              style={{
                fontSize: '12px',
                border: '1px solid #0a8394',
                borderRadius: '5px',
                width: '160px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                marginBottom: '8px',
                backgroundColor: 'white',
                color: '#0a8394',
                marginLeft: "auto",
              }}
            >
              <DownloadOutlined style={{ marginRight: '4px', color: '#0a8394' }} />
              Download Sample CSV
            </div>
            <TextField
              style={{ height: 40 }}
              placeholder="Workspace Name (eg Acme Corporation)"
              onChange={(e: any) => setWorkspaceName(e.target.value)}
              value={workspaceName}
              defaultValue={workspaceName}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              width: "100%",
              marginTop: 12,
            }}
          >
            <Typography
              variant="xs"
              style={{ color: colorPicker("neutral.800"), marginBottom: 6 }}
            >
              Allowed Domain
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <DynamicChipInput
                onChange={(data: any) => setAllowedDomain(data)}
                emailData={allowedDomain}
              />

              <Upload
                beforeUpload={beforeUploadHandler}
                showUploadList={false}
              //onChange={handleFileChange} 
              // Handle file selection
              >
                <Tag style={{ ...tagPlusStyle, cursor: 'pointer' }}
                  icon={<UploadOutlined />}
                >
                  Bulk upload
                </Tag>
              </Upload>
            </div>


          </div>

          {fileUploaded && csvData && csvData.length > 0 && (
            <div
              className="ag-theme-alpine"
              style={{ height: "300px", width: "100%", marginTop: 20 }}
            >
              <AgGridReact
                columnDefs={columnDefs}
                rowData={csvData}
                onCellValueChanged={onCellValueChanged}
              />
            </div>
          )}


          <Button
            size="large"
            style={{ marginTop: 24 }}
            type="primary"
            loading={isLoading}
            onClick={handleCreateWorkspace}
          >
            Create
          </Button>
        </div>
      )}


      {contextHolder}
    </div>
  );
}
