import { Button, Form, Input, message } from "antd";
import Typography from "app/shared/Typography";
import EmailUpdateForm from "./EmailUpdate";
import { useEffect, useState } from "react";
import { apiPost } from "app/services/apiServices";
import PhoneUpdateForm from "./PhoneUpdate";
// import { API_ENDPOINT_UPDATE_HOTEL_DETAIL } from "../../hotel.booking.constants";

// export default function HotelDataUpdate(props) {
//   const [hotelName, setHotelName] = useState("");
//   const [hotelCode, setHotelCode] = useState("");
//   const [hotelGstin, setHotelGstin] = useState("");

//   const [hotelEmails, setHotelEmails] = useState([]);
//   const [hotelBookingId, sethotelBookingId] = useState([]);
//   const [isLoading, setLoading] = useState(false);
//   const [hotelNumbers, setHotelNumbers] = useState([]);

//   const [messageApi, contextHolder] = message.useMessage();
  
//   console.log("booking data:'", props.bookingData);
//   useEffect(() => {
//     if (props.bookingData) {
//     //   setHotelName(props.bookingData?.hotel_details?.hotel_name);
//     //   setHotelCode(
//     //     props.bookingData?.hotel_details?.hotel_code ||
//     //       props.bookingData.hotel_code
//     //   );
//     //   setHotelGstin(props.bookingData?.hotel_details?.hotel_gstin);
//     //   setHotelNumbers(props.bookingData?.hotel_details?.hotel_phone);

//     //   if (props.type === "CC") {
//     //     setHotelEmails(props.bookingData?.cc_emails || []);
//     //   } else {
//     //     setHotelEmails(props.bookingData?.hotel_details?.hotel_emails);
//     //   }
    
//     setHotelEmails(props?.bookingData?.email_hotel_contact_email)
//     sethotelBookingId(props?.bookingData?.email_bookingId)
//     }
//   }, [props.bookingData]);

//   console.log("props.booking", hotelCode);

//   const handleSubmitHotelInfo = async () => {
//     setLoading(true);
//     const payload = {
  
//       hotel_emails: hotelEmails || [],
//       hotelBookingId:hotelBookingId || []
    
//     };
//     console.log("submit payload", payload);
//     const response = await apiPost( "http://localhost:7000/api/hotelFollowUp/emailUpdate", payload);
//     if (response.status) {
//       messageApi.success({
//         type: "success",
//         content: "Hotel details updated",
//       });
//       setHotelEmails([]);
//       setHotelGstin("");
//       setHotelNumbers([]);
//       props.onSuccess();
//     } else {
//       messageApi.error({
//         type: "error",
//         content: "Could not updated hotel detail",
//       });
//     }
//     setLoading(false);
//   };
//   console.log(hotelEmails,"hotel emails check")
  

//   return (
//     <div className="HotelUpdateForm" style={{ marginTop: 32 }}>
     
//     <Form name="basic" autoComplete="off">
//       <div style={{ marginTop: 24, marginBottom: 24 }}>
//         <Typography variant="xs" style={{ marginBottom: 6 }}>
//           Emails
//         </Typography>
//         <EmailUpdateForm
//           type="email"
//           emailData={hotelEmails}
//           onChange={(data) => setHotelEmails(data)}
//         />
//       </div>
//       <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
//         <Button type="primary" onClick={handleSubmitHotelInfo} loading={isLoading}>
//           Submit
//         </Button>
//       </Form.Item>
//     </Form>
//     {contextHolder}
//   </div>
//   );
// }
export default function HotelDataUpdate({ type, contactDetails, onSuccess }) {
    const [hotelEmails, setHotelEmails] = useState([]);
    const [hotelNumbers, setHotelNumbers] = useState([]);
    const [hotelBookingId, setHotelBookingId] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
  
    useEffect(() => {
      if (contactDetails) {
        setHotelBookingId(contactDetails?.email_bookingId || "");
        
        if (type === "email") {
          setHotelEmails(contactDetails?.email_hotel_contact_email || []);
        } else if (type === "phone") {
          setHotelNumbers(contactDetails?.email_hotel_contact_phone || []);
        }
      }
    }, [contactDetails, type]);
  
    const handleSubmitHotelInfo = async () => {
      setLoading(true);
      const payload = {
        hotelBookingId: hotelBookingId || "",
        ...(type === "email" && { hotel_emails: hotelEmails || [] }),
        ...(type === "phone" && { hotel_phone_numbers: hotelNumbers || [] }),
      };
  
      console.log("submit payload", payload);
  
      const response = await apiPost(
        "https://stage-apiplatform.finkraftai.com/api/hotelFollowUp/detailsUpdate",
        payload
      );
  
      if (response.status) {
        messageApi.success({
          type: "success",
          content: "Hotel details updated",
        });
        if (type === "email") setHotelEmails([]);
        if (type === "phone") setHotelNumbers([]);
        onSuccess();
      } else {
        messageApi.error({
          type: "error",
          content: "Could not update hotel detail",
        });
      }
      setLoading(false);
    };
  
    return (
      <div className="HotelUpdateForm" >
        <Form name="basic" autoComplete="off">
          {/* Conditionally render Email or Phone input based on `type` */}
          {type === "email" && (
            <div style={{  marginBottom: 24 }}>
              <Typography variant="xs" style={{ marginBottom: 6 }}>
                Emails
              </Typography>
              <EmailUpdateForm
                type="email"
                emailData={hotelEmails}
                onChange={(data) => setHotelEmails(data)}
              />
            </div>
          )}
  
          {type === "phone" && (
            <div style={{ marginTop: 24, marginBottom: 24 }}>
              <Typography variant="xs" style={{ marginBottom: 6 }}>
                Contact Numbers
              </Typography>
              <PhoneUpdateForm
                type="phone"
                phoneData={hotelNumbers}
                onChange={(data) => setHotelNumbers(data)}
              />
            </div>
          )}
  
          <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="primary" onClick={handleSubmitHotelInfo} loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
        {contextHolder}
      </div>
    );
  }
  