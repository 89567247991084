import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import './vendorcard.css'
import { API_ENDPOINT_GET_GST_SCORE } from 'app/scenes/VendorFollowUp/vendorfollowup.constants';

const Piechart = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(API_ENDPOINT_GET_GST_SCORE); 
                console.log("API response:", response.data);  // Debugging line
                if (Array.isArray(response.data)) {
                    setData(response.data);
                } else {
                    console.error("Data is not an array:", response.data);
                    setData([]);  // Fallback to empty array if data isn't what you expect
                }
            } catch (err) {
                console.error('Error fetching data:', err);
            }
        };

        fetchData();
    }, []);

    const COLORS = ['#F06B4B','#F5C956','#48C497'];

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, index }) => {
      // Reduce the radius to move the labels further inside
      const radius = innerRadius + (outerRadius - innerRadius) * 0.50; // Adjust for positioning inside the chart
      const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
      const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));
  
      return (
          <g>
              {/* Count text */}
              <text
                  x={x}
                  y={y - 5} // Slightly move up for space between count and range
                  fill="white"
                  textAnchor="middle"
                  dominantBaseline="central"
                  style={{ fontSize: '15px', fontWeight: 'bold' }}
              >
                  {data[index].count}
              </text>
  
              {/* Range text below the count */}
              <text
                  x={x}
                  y={y + 10} // Move down for range below the count
                  fill="white"
                  textAnchor="middle"
                  dominantBaseline="central"
                  style={{ fontSize: '15px' }}
              >
                  {`[${data[index].range}]`}
              </text>
          </g>
      );
    };

    return (
        <div>
            <p className='piedes'>Survey Results of Vendor 1lakh GST with real data</p>
            <div className="header-line2"></div>
            {data.length > 0 ? (
              <div className='piediv'>
                <PieChart width={280} height={280} margin={{ left: 20 }}>
                    <Pie
                        data={data}
                        dataKey="count"
                        nameKey="range"
                        cx="50%"
                        cy="50%"
                        outerRadius={130}
                        fill="#8884d8"
                        label={renderCustomizedLabel} // Use custom label
                        labelLine={false} // Hide default label line
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                </PieChart>
                <ul className='pieul'>
                  <li className='pieli'>Compliant</li>
                  <li className='pieli'>Delays - Suggest hold GST to vendor</li>
                  <li className='pieli'>Several delays hold payments</li>
                </ul>
                </div>
            ) : (
                <p>No data available</p>
            )}
        </div>
    );
};

export default Piechart;
