
import "./Vendor.scss";
import { useState } from "react";
import { Button, Modal, Tag } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
// import MainContentVendor from "./VendorScore/pages/demo/MainContent/MainContent";
import MainContentVendor from "../VendorScore/pages/demo/MainContent/MainContent";
import { useLocation } from "react-router-dom";
import {
 
  workspaceInfo,
} from "app/config/States/users";
import { useRecoilState } from "recoil";


const Vendor = () => {
  const { state } = useLocation();


  const [workspace, setWorkspace] = useRecoilState(workspaceInfo);
  const { GstinStatus, workspace: selectedWorkspaceFromLocation } = state || {};
  const [selectedWorkspace, setSelectedWorkspace] = useState(
    workspace || selectedWorkspaceFromLocation || null
  );
    const [gstin, setGstin] = useState("");
    const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

    const handleInputChange = (e) => {
        setGstin(e.target.value);
    };

    const handleSearchgstin = () => {
        if (gstin.trim()) {
          setIsSideMenuOpen(true);
        }
      };

      const closeSideMenu = () => {
        setIsSideMenuOpen(false);
      };

    console.log(workspace,"workspace in vendor compilance")
    return (
        <div className="vendorContainer">
          <div className="card">
            {/* <button className="vendor-score">Vendor score</button> */}
            <h2>Check your vendor compliance</h2>
            <label className="vendorRequired" >Vendor GSTIN<span className="required">*</span></label>
            <input className="input" type="text" style={{width:'95%'}} placeholder="Enter GSTIN" value={gstin} onChange={handleInputChange} />
            <Button className="check-btn" style={{width:'40%'}} onClick={handleSearchgstin}>Check now <ArrowRightOutlined  style={{marginLeft:'2px'}}/></Button>
          </div>
          {isSideMenuOpen && (
          <div className="side-menu">
            <button className="close-btn" onClick={closeSideMenu}><svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
              <path d="M15.5 5L5.5 15M5.5 5L15.5 15" stroke="#0A8394" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg></button>
            <MainContentVendor gstin={gstin} workspace={workspace} />
          </div>
        )}
        </div>
    )
  };
  
  export default Vendor;
