import { AgGridReact } from "ag-grid-react";
import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";
import { AgTableClient, AgTableServer } from "app/shared/AgTable";
import { apiGet } from "app/services/apiServices";
import { handleCellRenderer } from "app/shared/AgTable/AgServerUtility";
import Loader from "app/shared/Loader";
import { Button } from "antd";
import { currentPDFData, userInfo } from "app/config/States/users";
import { useRecoilState, useSetRecoilState } from "recoil";
import { API_ENDPOINT_RECON_ZA_AIRLINE_DATA } from "app/scenes/AppTour/onboarding.constants";
import ToolParamInvoker from "app/shared/AgTable/HelperTools/ToolParamInvoker";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  RightCircleOutlined,
} from "@ant-design/icons";
import { tableSchema } from "app/config/States/agtable";
import { getProcessedDefinition, getSubdomain } from "app/utils/generic.helper";
import FinkAI from "app/shared/FinkAI";
import FlightReconDemo from "../FlightReconDemo";
import { set } from "lodash";
import {
  agTableAirlineType,
  agTableAirlineData,
  tableLoadingState,
} from "app/config/States/agtable";
import { Server } from "http";
const ChildComponent = forwardRef((props: any, ref) => {
  const [pdfViewerData, setPdfViewerData] = useState("");
  const [tableSchemaInfo, setTableSchema] = useRecoilState<any>(tableSchema);
  const [columnDefs, setColumnDefs] = useState<any[]>([]);

  useEffect(() => {
    getProcessedDefinitions();
  }, [tableSchemaInfo]);

  useImperativeHandle(ref, () => ({
    createNewView(viewName: string) {
      gridRef.current?.saveGridView(viewName, props.moduleId);
    },
    loadTableView(viewId: string) {
      gridRef.current?.loadGridView(viewId, props.moduleId);
    },
    updateTableView(viewId: string) {
      gridRef.current?.updateGridView(viewId, props.moduleId);
    },
    exportDataToExcel() {
      gridRef.current?.exportDataToExcel();
    },
    getAllColumns() {
      return gridRef.current?.getAllColumns();
    },
    getGridState() {
      return gridRef.current?.getServerSidePayload();
    },
  }));
  const [rowData, setRowData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  //const [istableLoading, setistableLoading] = useState(false);
  const gridRef: any = useRef();
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [finkDataset, setFinkDataset] = useState<any[]>([]);
  const setRecoilPdfData = useSetRecoilState<string>(currentPDFData);
  const [airlineType, setAirlineType] = useRecoilState(agTableAirlineType); //my_code
  const [airlineData, setAirlineData] = useRecoilState(agTableAirlineData);
  const [tableLoading, setTableLoading] = useRecoilState(tableLoadingState);

  // const getProcessedDefinitions = () => {
  //   const columnObject = tableSchemaInfo.find(
  //     (item: any) => item.moduleId === props.moduleId
  //   );
  //   let columnArray = columnObject?.state?.columnDefs || [];
  //   let finalDefs = getProcessedDefinition(
  //     columnArray,
  //     "SERVER",
  //     setRecoilPdfData,
  //     userDetails || {}
  //   );

  //   // setColumnDefs(finalDefs);
  //   console.log("final Defs:", finalDefs);
  //   return finalDefs;
  // };\

  const getProcessedDefinitions = () => {
    const columnObject = tableSchemaInfo.find(
      (item: any) => item.moduleId === props.moduleId
    );
    let columnArray = columnObject?.state?.columnDefs || [];

    let finalDefs = getProcessedDefinition(
      columnArray,
      "SERVER",
      setRecoilPdfData,
      userDetails || {}
    );

    return finalDefs;
  };

  const domain = getSubdomain();

  const defaultFinkAction: any = [
    {
      name: "Total GST Airline Vise Not Received",
      model: {
        startRow: 0,
        endRow: 1000,
        rowGroupCols: [
          { id: "Vendor", displayName: "Vendor", field: "Vendor" },
        ],
        valueCols: [
          {
            id: "InvoiceTotalGST",
            aggFunc: "sum",
            displayName: "Invoice Total_GST",
            field: "InvoiceTotalGST",
          },
        ],
        pivotCols: [
          {
            id: "InvoiceStatus",
            displayName: "Invoice Status",
            field: "InvoiceStatus",
          },
        ],
        pivotMode: true,
        groupKeys: [],
        filterModel: {},
        sortModel: [],
        useWorkspace: false,
      },
    },
  ];
  console.log("re rendering", airlineData, airlineType);
  return (
    <div
      className="UnReconContainer"
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        className="ag-theme-alpine"
        style={{ flex: 1, width: "100%", height: "100%" }}
      >
        {isLoading || tableLoading ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader />
          </div>
        ) : airlineType === "CLIENT" ? (
          <AgTableClient
            // @ts-ignore
            rowData={airlineData}
            columnDefs={getProcessedDefinitions()}
            groupPanel
            enablePDFViewer
            pdfV2
            pdfDataKey="Invoice_link"
            ref={gridRef}
          />
        ) : (
          <AgTableServer
            //@ts-ignore
            //agTableAirlineType={airlineType}
            columnDefs={getProcessedDefinitions()}
            endpoint={API_ENDPOINT_RECON_ZA_AIRLINE_DATA}
            // endpoint={"http://192.168.1.59:7001/api/recon/hotel"}
            pdfDataKey="Invoice_link"
            enablePDFViewer
            grandTotalRow
            pdfV2
            ref={gridRef}
            showStatusBar
            useWorkspace={
              userDetails?.currentWorkspace?.name === "MMT Admin"
                ? false
                : userDetails?.currentWorkspace?.meta?.full_access
                ? false
                : true
            }
            setData={(data: any) => setFinkDataset(data)}
          />
        )}
      </div>
      <FinkAI
        onResponse={(config: any) =>
          gridRef.current && gridRef.current.applyTestView(config)
        }
        defaultActions={defaultFinkAction}
        columnDefs={getProcessedDefinitions()}
        dataset={finkDataset}
      />
    </div>
  );
});

export default ChildComponent;
