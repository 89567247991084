import { useEffect, useState } from "react";
import "../../AgTable.scss";
import Loader from "app/shared/Loader";
export default function PDFViewerTool(props: any) {
  const { api } = props;
  const [invoiceLinks, setInvoiceLinks] = useState<any[]>([]);

  const [isLoading, setLoading] = useState(true);

  const handleIframeLoad = () => {
    setLoading(false);
  };

  const onRowClick = (params: any) => {
    setLoading(true);
    let Irn = params.data?.[props?.dataKey];

    setInvoiceLinks([Irn]);
  };

  useEffect(() => {
    api.addEventListener("rowClicked", onRowClick);
    return () => {
      api.removeEventListener("rowClicked", onRowClick);
    };
  }, [api]);

  console.log(invoiceLinks,"invoiceLinks")

  return (
    <div className="PDFViewerToolSidebar">
      <div className="Container">
        {invoiceLinks.map((invoice: any, key: any) => {
          return (
            <div
              className="PDFViewer"
              style={{ width: invoiceLinks.length > 1 ? "50%" : "100%" }}
              key={key}
            >
              {isLoading && <Loader />}

              <embed
                width="100%"
                height="100%"
                src={invoice}
                type="application/pdf"
                id="pdfEmbed"
                onLoad={handleIframeLoad}
                style={{
                  // display: isLoading ? "none" : "block",
                  height: "100%",
                  paddingBottom: 20,
                }}
              ></embed>
            </div>
          );
        })}
      </div>
    </div>
  );
}
