import React from "react";
import "./ExpenseTypeFile.scss";
 //import dollarImage from "../../cost_5293598 1.png";
import dollarImage from "../../Frame 2085666012.png";
import { Button,DatePicker } from "antd";
import fileIcon from "../../fileIcon.png";
import riskIcon from "../../riskIcon.png";
import Loader from "app/shared/Loader";
import { useState } from "react";

const ExpenseTypeFile = ({ expenseData, onMonthChange }) => {
    const [showCalendar, setShowCalendar] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState(null);
  const [open, setOpen] = useState(false);
  console.log(expenseData, "Expense");
  const formatNumber = (num, decimals = 0) => {
    if (!num) return "0";
    if (num < 1000) return num.toFixed(decimals);
    else if (num < 100000) return (num / 1000).toFixed(decimals) + " K";
    else if (num < 10000000) return (num / 100000).toFixed(decimals) + " L";
    else return (num / 10000000).toFixed(decimals) + " Cr";
  };

  const { total_invoice_value, total_amount_risk, total_gst_amount } = expenseData || {};
  return !expenseData ? (
    <div
      className="expense-card-container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loader />
    </div>
  ) : (
     <div className="expense-card-container">
      {/* Top Section (e.g., title or date) */}
      <div
        className="expense-card-header"
        style={{ display: "flex", gap: "20px" }}
      >
        <img src={dollarImage}></img>
        <div style={{ gap: "20px" }}>
          <span className="expenseTag">Expense Type</span>
          <p className="expenseTagYear">2024-2025</p>
        </div>
             <DatePicker
          picker="month"
          placeholder="Apply Filter"
          value={selectedMonth}
          open={open}
          inputReadOnly
        //   onClick={() => setOpen(true)}
        onClick={() => setOpen(true)}
      
          onOpenChange={(status) => setOpen(status)}
        //   onChange={(date) => {
        //     setSelectedMonth(date);
        //     setOpen(false);
        //   }}
        onChange={(date) => {
            setSelectedMonth(date);
            console.log("Always-open DatePicker selected month:", date ? date.format("MMYYYY") : "none");
            if (onMonthChange) {
              onMonthChange(date);
            }
          }}
          suffixIcon={
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.16797 2.66666H13.8346V4.11466C13.8346 4.46825 13.694 4.80733 13.444 5.05732L10.5013 7.99999V12.6667L6.5013 14V8.33332L3.51464 5.04799C3.2916 4.80261 3.168 4.48292 3.16797 4.15132V2.66666Z"
                stroke="#0A8394"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          }
          className="apply-filter-datepicker"
          dropdownClassName="apply-filter-datepicker-dropdown"
          style={{ marginLeft: "auto" , display:"flex"}}
        />
      </div>
      {showCalendar && (
        <div style={{ margin: "10px 0" }}>
          <DatePicker picker="month" placeholder="Select month" open={true}/>
        </div>
      )}

      {/* Card Body */}
      <div className="expense-card-body">
        {/* Card 1 */}
        <div className="expense-card-box1">
          <img
            src={fileIcon}
            style={{ width: "32px", height: "32px", marginTop: "8px" }}
          />
          <div>
            <p className="expense-card-box-label">Total invoice Value</p>
            <p className="expense-card-box-value">INR {formatNumber(total_invoice_value)}</p>
          </div>
        </div>


         {/* Card 3 //---correct flow */}
        <div className="expense-card-box3">
          <img
            src={fileIcon}
            style={{ width: "32px", height: "32px", marginTop: "8px" }}
          />
          <div>
            <p className="expense-card-box-label">GST Amount</p>
            <p className="expense-card-box-value">INR {formatNumber(total_gst_amount)}</p>
          </div>
        </div>

        {/* Card 2 */}
        <div className="expense-card-box2">
          <img
            src={riskIcon}
            style={{ width: "32px", height: "32px", marginTop: "8px" }}
          />
          <div>
            <p className="expense-card-box-label">Risk of GST loss</p>
            <p className="expense-card-box-value">INR {formatNumber(total_amount_risk,2)}</p>
          </div>
        </div>

        
      </div>
    </div>
  );
};

export default ExpenseTypeFile;
