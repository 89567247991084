import React from "react";
import { AgCharts } from "ag-charts-react";
import { Button } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import "./Line.scss";
import { DatePicker } from "antd";
import Loader from "app/shared/Loader";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const FollowUpStatus = ({ followupStatus ,onMonthChange}) => {
  // Map API data into chart data format
  const chartData = followupStatus?.data?.map((item) => ({
    // Format the dt field to a readable date string
    dt: new Date(item.dt).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    }),
    // Use Gst_amt_at_risk from the first object in client_breakdown
    Gst_amt_at_risk: item.client_breakdown[0]?.Gst_amt_at_risk,
  }));

  const navigate = useNavigate();
  const [selectedMonth, setSelectedMonth] = useState(null);///new-------
  const [open, setOpen] = useState(false);

  // Define chart options with one series for "Gst_amt_at_risk"
  const options = {
    theme: "ag-default",
    data: chartData,
    series: [
      {
        type: "line",
        xKey: "dt",
        yKey: "Gst_amt_at_risk",
        stroke: "#0A8394", // Blue line color
        strokeWidth: 2,
        marker: {
          enabled: true,
          shape: "circle",
          size: 8,
          fill: "#0A8394",
          stroke: "#fff",
        },
        interpolation: { type: "smooth" },
        tooltip: { enabled: true },
      },
    ],
    axes: [
      {
        type: "category",
        position: "bottom",
        label: {
          fontSize: 12,
          fontWeight: 400,
          color: "var(--UniversalPalette-text-regular, rgba(0, 0, 0, 0.70))",
        },
        gridStyle: [{ stroke: "#E0E0E0", lineDash: [4, 4] }],
      },
      {
        type: "number",
        position: "left",
        label: {
          fontSize: 12,
          fontWeight: 400,
          color: "var(--UniversalPalette-text-regular, rgba(0, 0, 0, 0.70))",
          formatter: ({ value }) => `${value}`,
        },
        gridStyle: [{ stroke: "#E0E0E0", lineDash: [4, 4] }],
      },
    ],
    // legend: {
    //   position: "bottom",
    //   item: {
    //     marker: {
    //       shape: "circle",
    //     },
    //     toggleSeries: false,
    //   },
    // },
    legend: {
      position: "bottom",
      item: {
        marker: {
          shape: "circle",
        },
      },
      listeners: {
        legendItemClick: (event) => {
          event.preventDefault(); // Prevents toggling
        },
      },
    },
    
  };

  return !followupStatus ? (
    <div
      className="lineConatiner"
      style={{
        display: "flex",
        alignItems: "center",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <Loader />
    </div>
  ) : (
    <div className="lineConatiner">
      <div className="lineHeader">
        <div className="followupHeader">GST at Risk</div>
        {/* <div style={{ display: "flex", gap: "8px" }}>
          <Button className="filterBtn">
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.16797 2.66666H13.8346V4.11466C13.8346 4.46825 13.694 4.80733 13.444 5.05732L10.5013 7.99999V12.6667L6.5013 14V8.33332L3.51464 5.04799C3.2916 4.80261 3.168 4.48292 3.16797 4.15132V2.66666Z"
                stroke="#0A8394"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Apply filter
          </Button>
        </div> */}
        <DatePicker
          picker="month"
          placeholder=""
          value={selectedMonth}
          open={open}
          inputReadOnly
          onClick={() => setOpen(true)}
          onOpenChange={(status) => setOpen(status)}
          onChange={(date) => {
            setSelectedMonth(date);
            console.log(
              "Line component selected month:",
              date ? date.format("MMYYYY") : "none"
            );
            if (onMonthChange) {
              onMonthChange(date);
            }
          }}
          suffixIcon={
            // <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <div className="custom-suffix"> 
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.16797 2.66666H13.8346V4.11466C13.8346 4.46825 13.694 4.80733 13.444 5.05732L10.5013 7.99999V12.6667L6.5013 14V8.33332L3.51464 5.04799C3.2916 4.80261 3.168 4.48292 3.16797 4.15132V2.66666Z"
                  stroke="#0A8394"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {!selectedMonth && (
        <span style={{ color: "#0A8394", fontSize: "12px", fontWeight: "normal" }}>
          Apply filter
        </span>
      )}

            </div>
          }
          className="apply-filter-datepicker1"
          dropdownClassName="apply-filter-datepicker-dropdown"
          style={{
            marginLeft: "auto",
            display: "flex",
            alignItems: "center",
          }}
        />
      </div>
      <div style={{ marginTop: "20px" }}>
        <AgCharts options={options} />
      </div>
    </div>
  );
};

export default FollowUpStatus;