import { Button, Checkbox, Divider, Form, Input, message } from "antd";
import { apiGet, apiPost } from "app/services/apiServices";
import TextField from "app/shared/TextField";
import Typography from "app/shared/Typography";
import { useEffect, useState } from "react";
import AppLogo from "static/images/Logo.svg";
import {
  API_ENDPOINT_AUTH_SIGNIN,
  API_ENDPOINT_AUTH_SIGNIN_OTP,
  API_ENDPOINT_AUTH_SIGNIN_VERIFY_OTP,
  API_ENDPOINT_GET_GST_STATUS,
  API_ENDPOINT_GET_USER_INFO,
  API_ENDPOINT_GET_WORKSPACE_REPORTS,
} from "../../auth.constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userInfo, workspaceReports } from "app/config/States/users";
import { colorPicker } from "app/utils/color.helper";
import { cursorTo } from "readline";
import { getSubdomain } from "app/utils/generic.helper";
import { Mixpanel } from "app/config/Mixpanel";

type FieldType = {
  email?: string;
  otp?: string;
};

export default function LoginOTP(props: any) {
  const [otp, setOTP] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [userDetails, setUserDetails] = useRecoilState(userInfo);
  const [reports, setWorkspaceReports] = useRecoilState(workspaceReports);

  const navigate = useNavigate();
  const location = useLocation();

  const email = location?.state?.email || props.loginInfo.email;
  const fetchWorkspaceGstinStatus = async () => {
    try {
      const response = await apiGet(API_ENDPOINT_GET_GST_STATUS);
      if (response.status) {
        return response.data; // Only return the data property
      } else {
        console.error("Failed to fetch GSTIN status:", response.message);
        return []; // Return an empty array as a fallback
      }
    } catch (error) {
      console.error("Error while fetching GSTIN status:", error);
      return []; // Return an empty array in case of error
    }
  };

  const fetchUserInfo = async () => {
    const domain = getSubdomain();
    const response = await apiGet(API_ENDPOINT_GET_USER_INFO, false);
    if (response.status) {
      if (response.data?.workspaces.length === 0) {
        localStorage.clear();
        message.error("You do not have any acccess to this portal");
        navigate("/auth/signin");
        return;
      }
      let mmtWorkspace = (response.data.workspaces || []).find(
        (item: any) => item?.meta?.full_access
      );
      setUserDetails({
        ...response.data,
        currentWorkspace: mmtWorkspace
          ? mmtWorkspace
          : response.data.workspaces[0],
        clusterId: response.data.cluster_id,
      });
      localStorage.setItem(
        "currentWorkspace",
        mmtWorkspace ? mmtWorkspace.id : response.data.workspaces[0]?.id
      );   
      localStorage.setItem("email", email);

      Mixpanel.identify(response.data.id);
      Mixpanel.track("Successful login", {
        domain: response?.data?.email?.split("@")[1],
      });
      //@ts-ignore
      localStorage.setItem("session_start_time", new Date().getTime());
     

      Mixpanel.people.set({
        $name: response.data.name,
        $email: response.data.email,
        email: response.data.email,
        name: response.data.name,
      });

      const gstinStatus = await fetchWorkspaceGstinStatus();
      if (domain === "bhilosa" || domain === "followup"||domain === "followup-stage") {
        if (gstinStatus?.length > 0) {
          navigate(`/vendorfollowup/gstintegration`, {
            state: {
              GstinStatus: true,
              workspace: response.data.workspaces[0]?.name,
            },
          });
        } else {
          navigate(`/vendorfollowup/gstintegration`, {
            state: {
              GstinStatus: false,
              workspace: response.data.workspaces[0]?.name,
            },
          });
        }
      } else if (domain === "integration") {
        navigate("/credential/gst/landing");
      } else if (domain === "app") {
        navigate("/integrations");
      } else {
        navigate("/dashboard");
      }
      // if (gstinStatus?.length > 0) {
      //   navigate(`/vendorfollowup/gstintegration`, {
      //     state: {
      //       GstinStatus: true,
      //       workspace: response.data.workspaces[0]?.name,
      //     },
      //   });
      // } else {
      //   navigate(`/vendorfollowup/gstintegration`, {
      //     state: {
      //       GstinStatus: false,
      //       workspace: response.data.workspaces[0]?.name,
      //     },
      //   });
      // }

      messageApi.success({
        type: "success",
        content: response.message,
      });

      // if (props.noRouting) {
      //   props.onSuccess && props.onSuccess();
      // } else {
      //   if (response.data.hasOnboared) {
      //     navigate("/dashboard");
      //   } else {
      //     navigate("/dashboard");
      //   }
      // }
    }
  };

  const fetchReportsAndDashboards = async () => {
    const response = await apiGet(API_ENDPOINT_GET_WORKSPACE_REPORTS);
    if (response.status) {
      setWorkspaceReports(response.data);
    }
  };

  useEffect(() => {
    if (!location.state || !email) navigate("/auth/signin");
  }, []);

  const verifyOTP = async () => {
    setLoading(true);
    let payload = {
      email: email,
      otp: otp,
    };

    const response = await apiPost(
      API_ENDPOINT_AUTH_SIGNIN_VERIFY_OTP,
      payload
    );
    if (response.status) {
      localStorage.setItem("token", response.data.token);
      fetchUserInfo();
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const resendLoginOTP = async () => {
    let payload = {
      email: email,
    };
    const response = await apiPost(API_ENDPOINT_AUTH_SIGNIN_OTP, payload);
    if (response.status) {
      navigate("/auth/signin_otp", { state: { email: email } });
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
  };

  return (
    <div className="AuthForm">
      <div className="FormHeader">
        <Typography style={{ color: colorPicker("neutral.700") }}>
          Welcome
        </Typography>
        <Typography
          style={{
            fontSize: 40,
            color: colorPicker("neutral.950"),
            marginTop: -6,
          }}
        >
          Verify OTP
        </Typography>

        <Typography
          style={{ color: colorPicker("neutral.700"), marginTop: 8 }}
          variant="caption"
        >
          We have sent you a one time password on your provided email{" "}
          <strong>{email}</strong>
        </Typography>
      </div>
      <div className="FieldContainer">
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={verifyOTP}
          autoComplete="off"
        >
          <div className="FieldGroup">
            <Form.Item<FieldType>
              name="otp"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.OTP
                onChange={(otpValue) => setOTP(otpValue)}
                size="large"
              />
            </Form.Item>
          </div>

          <div className="FieldGroup" style={{ marginTop: 38 }}>
            <Button
              type="primary"
              style={{ width: "100%" }}
              size="large"
              htmlType="submit"
              // disabled={!email || !password}
              loading={isLoading}
            >
              Verify
            </Button>
          </div>

          <Typography
            style={{
              marginTop: 8,
              textDecoration: "underline",
              cursor: "pointer",
              color: "var(--base-color-blue)",
            }}
            weight={500}
            variant="caption"
            onClick={resendLoginOTP}
          >
            Resend OTP
          </Typography>
        </Form>
      </div>
      {contextHolder}
    </div>
  );
}
