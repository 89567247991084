import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import './gstrb_Tables.css';
import { Empty, Button, Switch } from 'antd';

const GSTR3BFilingStatusSummary = ({ Avgdelaydata, gstr3bFilingDates = [] }) => {
  
  const [showGstr3bDates, setShowGstr3bDates] = useState(false);

  // Prepare data for GSTR3B and GSTR3B Filing Dates
  const gstr3bData = Avgdelaydata["GSTR3B_avg_delay"] || {};

  const gstr3bRowData = Object.entries(gstr3bData).map(([year, delay]) => ({
    year: year,
    delay: delay,
  }));

  const gstr3bDatesRowData = gstr3bFilingDates.map(({ month, dateOfFiling }) => ({
    month: month,
    dateOfFiling: dateOfFiling,
  }));

  const columnDefsGstr3b = [
    { headerName: 'Year of Return Per', field: 'year', resizable: true, sortable: true, flex: 1.1 },
    { headerName: 'Avg Delay in Day(s)', field: 'delay', resizable: true, sortable: true, cellClass: params => params.value < 0 ? 'negative-delay' : '', flex: 1 },
  ];

  const columnDefsGstr3bDates = [
    { headerName: 'Month', field: 'month', resizable: true, sortable: true, flex: 1 },
    { headerName: 'Date of Filing', field: 'dateOfFiling', resizable: true, sortable: true, flex: 1.2 },
  ];

  const rowData = showGstr3bDates ? gstr3bDatesRowData : gstr3bRowData;
  const columnDefs = showGstr3bDates ? columnDefsGstr3bDates : columnDefsGstr3b;

  return (
    <div className="gstr3b-table-container">
      <div className='gstr3b-heading'>
      <h2 style={{ color: 'hsla(187, 87%, 31%, 1)' }}>
        {showGstr3bDates ? 'GSTR3B Filing Dates' : 'GSTR3B Filing Status Summary'}
      </h2>
      <div style={{ marginBottom: '16px' }}>
        <Switch
          checked={showGstr3bDates}
          onChange={() => setShowGstr3bDates(!showGstr3bDates)}
          checkedChildren="Filing Dates"
          unCheckedChildren="Avg Delay Data"
          style={{ margin: '0 10px' }}
        />
      </div>
      </div>
      <div className="header-line"></div>
      {rowData && rowData.length > 0 ? (
        <div className="ag-theme-alpine" style={{ height: 250, width: '100%' }}>
          <AgGridReact
            columnDefs={columnDefs}
            rowData={rowData}
            domLayout="autoHeight"
            headerHeight={50}
            rowHeight={30}
          />
        </div>
      ) : (
        <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '68px' }}>
          <Empty description={showGstr3bDates ? "No GSTR3B Filing Dates Available" : "No GSTR3B Summary Available"} />
        </div>
      )}
    </div>
  );
};

export default GSTR3BFilingStatusSummary;
